import Audience from '@/entities/Audience';
import api from '@/services/Api';

export default {
  getList({ id, type }) {
    const data = {
      ad_account_id: id,
    };
    if (type) {
      data['type'] = type;
    }

    const query = new URLSearchParams(data).toString();

    return api()
      .get('/audiences?' + query)
      .then(resp => resp.map(i => new Audience(i)));
  },

  refresh(id) {
    const data = { ad_account_id: id };

    return api()
      .post('/audiences/refresh', data)
      .then(resp => resp.map(i => new Audience(i)));
  },
};
