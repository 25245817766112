import AdAccount from '@/entities/AdAccount';
import BaseEntity from '@/entities/BaseEntity';
import { parseNumber } from '@/utils/number.js';

export default class Campaign extends BaseEntity {
  id = null;
  ad_account = null;
  name = '';
  status = null;
  objective = null;
  special_ad_categories = [];
  special_ad_category_country = [];
  _budget = null;
  budget_period = null;
  bid_strategy = null;
  reserved = false;
  _spend_cap = null;
  deleted_at = null;

  _entityArrayFields = {
    ad_accounts: AdAccount,
  };

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }

  get budget() {
    return this._budget;
  }

  set budget(value) {
    this._budget = parseNumber(value);
  }

  get spend_cap() {
    return this._spend_cap;
  }

  set spend_cap(value) {
    this._spend_cap = parseNumber(value);
  }

  get hasSpecialAdCategories() {
    return this.special_ad_categories && this.special_ad_categories.length !== 0;
  }

  get isObjectiveEngagement() {
    return this.objective === 'OUTCOME_ENGAGEMENT';
  }

  toObject() {
    const result = super.toObject();
    result.budget = this.budget;

    return result;
  }
}
