export default {
  data:() => ({
    insideValue: '',
  }),
  watch: {
    insideValue(val, oldVal) {
      if (!oldVal) {
        return;
      }
      this.$emit('input', this.insideValue);
    },
    value: {
      immediate: true,
      handler() {
        this.insideValue = this.value;
      },
    },
  },
};
