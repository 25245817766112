<template>
  <div class="rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-1">
    <name-block
      :entity="entity"
      :description="tr('This will be the name of your currently created automation as well as the name of the campaign. The automation and campaign name will help you identify and easily manage the different automations you\'ve created.')"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <notifiable-users-block :entity="entity" :disabled="$parent.readonlyMode" />
    <activity-management-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <form-block
      class="ai-assist-block"
      :title="tr('AI Assist') + '<div class=\'beta-flag\'>Beta</div>'"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            v-model="entity.settings.dont_scale_expired_ads"
            type="checkbox"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Do not scaling up ads with expired content') }}

          <info-popup>
            <div v-html="tr('Check the ad copy expiration date by Infinite∞Ai')" />
          </info-popup>
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-account-block
      :entity="entity"
      :disabled="$parent.readonlyMode || !entity.isNew"
      :description="tr('You can choose the ad account in which you want to manage your advertisement. (This is useful if you manage multiple accounts.)')"
    />

    <div class="divider" />

    <operation-area-block
      :entity="entity"
      :disabled="$parent.readonlyMode"
      :disabled-option-message="tr('In the case of an upscaling task, only Start Automations with the same goal (campaign objective) and using the same Special advertising categories can be selected at the same time.')"
    />

    <div class="divider" />

    <!-- <post-type-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" /> -->

    <form-block
      :title="tr('Filters')"
      :description='tr("You can set different filters to determine the conditions under which the ads should stop or scaling up. You can add multiple conditions to an ad by clicking on the \"+\" sign, and you can connect them with the \"and\" or \"or\" options.")'
      class="filters"
    >
      <condition-group
        v-model="entity.conditions"
        :action="entity.action"
        :platforms="entity.selectedSourcePlatforms"
        :ad-account="entity.selectedAdAccount"
        :disabled="!filterIsAvailable || $parent.readonlyMode"
      />
    </form-block>
  </div>
</template>

<script>
import InfoPopup from '@/components/InfoPopup.vue';
import ActivityManagementBlock from '@/components/RuleForm/Blocks/ActivityManagementBlock.vue';
import AdAccountBlock from '@/components/RuleForm/Blocks/AdAccountBlock.vue';
import NameBlock from '@/components/RuleForm/Blocks/NameBlock.vue';
import NotifiableUsersBlock from '@/components/RuleForm/Blocks/NotifiableUsersBlock.vue';
import OperationAreaBlock from '@/components/RuleForm/Blocks/OperationAreaBlock.vue';
import ConditionGroup from '@/components/RuleForm/Condition/ConditionGroup.vue';
import { AD_CREATION_METHOD } from '@/consts.js';
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

export default {
  name: 'ScalingUpRuleFormStep1',
  components: {
    InfoPopup,
    ActivityManagementBlock,
    AdAccountBlock,
    NameBlock,
    NotifiableUsersBlock,
    OperationAreaBlock,
    ConditionGroup,
  },
  mixins: [ RuleFormStepMixin ],
  computed: {
    hasSelectedSourceProfile() {
      return this.entity.source_profiles.filter(i => i.id).length !== 0;
    },
    filterIsAvailable() {
      const hasSelectedInputCampaign = this.entity.input_campaigns.map(i => i.id).filter(id => !!id).length !== 0;
      return this.entity.hasSelectedAdAccount && hasSelectedInputCampaign;
    },
  },
  watch: {
    'entity.input_campaigns'(val) {
      if (val.length === 1) {
        this.entity.output_campaign.objective = val[0].objective;

        if (val[0].objective === 'OUTCOME_ENGAGEMENT') {
          this.entity.settings.ad_creation_method = AD_CREATION_METHOD.POST_BOOST;
        }
      }
    },
  },
  methods:{
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      if (!this.entity.isValidConditions) {
        this.error(this.tr('All Filter fields must be filled in!'));
        return false;
      }

      return true;
    },
  },
};
</script>
