<template>
  <div class="stripe-card-input-component">
    <component
      :is="clientSecret ? 'stripe-element-payment' : 'stripe-element-card'"
      v-if="stripeLoaded"
      ref="stripeForm"
      icon-style="solid"
      :pk="publishableKey"
      :elements-options="elementsOptions"
      :create-options="createOptions"
      :confirm-params="{}"
      :locale="$root.language"
      :hide-postal-code="true"
      :disabled="isLoading"
      @element-ready="() => $emit('ready')"
      @error="(val) => stripeError = val"
      @element-change="(val) => onElementChange(val)"
    />
    <div v-if="isLoading || !stripeLoaded" class="loading-blocker-layer">
      <i v-if="!clientSecret" class="icon loader primary" />
    </div>
  </div>
</template>

<script>
import { StripeElementCard, StripeElementPayment } from '@vue-stripe/vue-stripe';

import CompanyPaymentService from '@/services/CompanyPayment';

export default {
  name: 'StripeCardInput',
  components: {
    StripeElementCard,
    StripeElementPayment,
  },
  props: {
    clientSecret: {
      type: String,
      required: false,
      default: () => null,
    },
    forceLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      isCompleted: false,
      stripeElementIsLoading: false,
      stripeError: null,
      createOptions: {
        fields: {
          billingDetails: {
            address: {
              country: 'never',
              postalCode: 'never',
            },
          },
        },
      },
    };
  },
  computed: {
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    stripeLoaded() {
      return this.$store.getters['app/stripeLoaded'];
    },
    isLoading() {
      return this.stripeElementIsLoading || this.forceLoading;
    },
    elementsOptions() {
      return this.clientSecret ? { clientSecret: this.clientSecret } : null;
    },
  },
  watch: {
    stripeElementIsLoading(val) {
      if (val && this.$refs.stripeForm) {
        this.$refs.stripeForm.element.blur();
      }
    },
  },
  created() {
    if (!this.stripeLoaded) {
      this.$store.dispatch('app/loadStripe');
    }
  },
  methods: {
    async save() {
      if (this.isLoading) {
        return false;
      }

      this.stripeError = null;

      if (this.clientSecret) {
        return this.confirmPayment();
      }

      return this.confirmCard();
    },
    onUpdateIsLoading(val) {
      if (val) {
        this.stripeElementIsLoading = true;
      }
      else {
        this.$nextTick(() => {
          if (this.stripeError !== null) {
            this.$emit('error', this.stripeError);
          }
          else {
            this.$emit('success');
          }

          this.stripeElementIsLoading = false;
        });
      }
    },
    async confirmPayment() {
      this.onUpdateIsLoading(true);

      let success = false;
      try {
        const { error } = await this.$refs.stripeForm.stripe.confirmPayment({
          elements: this.$refs.stripeForm.elements,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: this.$root.currentCompany.billing_country,
                  postal_code: this.$root.currentCompany.billing_zip,
                },
              },
            },
          },
        });

        if (error) {
          throw error;
        }

        success = true;
      }
      catch (err) {
        this.stripeError = err;
      }

      this.onUpdateIsLoading(false);

      return success;
    },
    async confirmCard() {
      this.onUpdateIsLoading(true);

      let success = false;
      try {
        const resp = await CompanyPaymentService.createCardConfirmSetupIntent(this.$root.currentCompany.id);

        const { setupIntent, error } = await this.$refs.stripeForm.stripe.confirmCardSetup(resp.client_secret, {
          payment_method: {
            card: this.$refs.stripeForm.element,
          },
        });

        if (error) {
          throw error;
        }

        await CompanyPaymentService.setPaymentMethodAsDefault(this.$root.currentCompany.id, setupIntent.payment_method);

        success = true;
      }
      catch (err) {
        this.stripeError = err;
      }

      this.onUpdateIsLoading(false);

      return success;
    },
    onElementChange(val) {
      this.isCompleted = val.complete;

      if (val.error) {
        this.stripeError = val.error.message;
      }
      else {
        this.stripeError = null;
      }
    },
  },
};
</script>
