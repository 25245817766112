<template>
  <form-block
    class="rule-form-block conversion-odax-block-component"
    :title="title !== null ? title : tr('Conversion')"
  >
    <info-text v-if="entity.disconnectedAdAccount" type="error">
      {{ tr('The selected ad account has been disconnected.') }}
    </info-text>
    <template v-else>
      <template v-if="!minimal">
        <div v-if="destiantionTypeSelectVisible">
          <label class="input-label">{{ tr('Conversion location') }}</label>
          <v-select
            v-model="entity.settings.adset_conversion_location"
            :options="destinationTypes"
            :selectable="item => !item.disabled"
            :reduce="(item) => item.machine_name"
            :searchable="false"
            :clearable="false"
            :disabled="entity.isRoasGoalCampaignStrategy || !entity.isNew"
            label="label"
          />
        </div>

        <div>
          <label class="input-label">{{ tr('Performance goal') }}</label>
          <v-select
            v-model="entity.settings.adset_optimization_goal"
            :options="optimizationGoals"
            :reduce="(obj) => obj.machine_name"
            :searchable="false"
            :clearable="false"
            :disabled="!entity.isNew"
            label="label"
          />
        </div>
      </template>

      <template v-if="fbOdaxConversionAppIsVisible">
        <div>
          <label class="input-label">{{ tr('Application') }}</label>
          <v-select
            v-model="entity.settings.adset_conversion_app_id"
            :options="entity.appList"
            :reduce="(obj) => obj.id"
            :placeholder="tr('Select an app')"
            :disabled="disabled"
            label="name"
          />
        </div>
        <div>
          <label class="input-label">{{ tr('Application type') }}</label>
          <v-select
            v-model="entity.settings.adset_conversion_app_store_url"
            :options="appTypeList"
            :reduce="(obj) => obj.value"
            :placeholder="tr('Select app type')"
            :disabled="!entity.settings.adset_conversion_app_id || disabled"
          />
        </div>
      </template>

      <div v-if="fbOdaxConversionPixelIsVisible">
        <label class="input-label">{{ tr('Pixel') }}</label>
        <v-select
          v-model="entity.settings.adset_conversion_pixel_id"
          :options="entity.pixelListForSelect"
          :reduce="option => option.value"
          :placeholder="tr('Select a pixel')"
          :disabled="disabled"
        >
          <template #option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>

          <template #selected-option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>
        </v-select>
      </div>

      <template v-if="fbOdaxConversionEventIsVisible">
        <div>
          <label class="input-label">
            {{ tr('Conversion event') }}

            <refresh-button
              v-if="!entity.disconnectedAdAccount"
              :label="tr('Update events')"
              :success-message="tr('Conversion events updated successfully.')"
              :promise="() => refreshSelectedAdAccount()"
            />
          </label>
          <v-select
            v-model="entity.settings.adset_conversion_event"
            :options="conversionEvents"
            :reduce="(obj) => obj.machine_name"
            :placeholder="tr('Select a conversion')"
            :disabled="entity.isRoasGoalCampaignStrategy || disabled"
          >
            <template #option="option">
              <div>
                <template v-if="'status' in option">
                  <i v-if="option.status" class="icon point green" />
                  <i v-else class="icon point red" />
                </template>

                {{ option.label }}
              </div>
            </template>

            <template #selected-option="option">
              <div>
                <template v-if="'status' in option">
                  <i v-if="option.status" class="icon point green" />
                  <i v-else class="icon point red" />
                </template>

                {{ option.label }}
              </div>
            </template>
          </v-select>
        </div>

        <a
          v-if="!minimal"
          href="https://business.facebook.com/settings/custom-conversions"
          class="facebook-link"
          target="_blank"
        >
          {{tr('Create new custom conversion')}}
        </a>
      </template>

      <template v-if="!minimal">
        <div v-if="entity.isRoasGoalAvailable">
          <label class="input-label">
            {{ tr('ROAS goal') }}
            <template v-if="!entity.isRoasGoalCampaignStrategy">
              ({{ tr('optional') }})
            </template>
          </label>
          <div class="description">{{ tr('Set a goal if you’re aiming for a certain return on ad spend. To set your ROAS goal, use a number between 0.001 and 1,000.') }}</div>
          <number-input
            v-model="entity.settings.adset_roas_goal"
            :step="0.001"
            :max="1000"
          />
        </div>
        <div v-else-if="entity.bidAmountIsAvailable">
          <label class="input-label">
            {{ bidAmountLabel }}
            <template v-if="!entity.bidAmountIsRequired">
              ({{ tr('optional') }})
            </template>
          </label>
          <div v-if="!entity.isBidCapStrategy" class="description">{{ tr('A type of advertising metric that is used to measure the effectiveness and efficiency of an advertising campaign. It represents the average cost that you pay for a specific result, such as a click, an impression, a lead, or a sale.') }}</div>
          <money-input
            v-model="entity.settings.adset_bid_amount"
            :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
            :disabled="disabled"
          />
          <info-text v-if="!entity.bidAmountIsRequired">
            {{ tr('Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.') }}
          </info-text>
        </div>
      </template>
    </template>
  </form-block>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import MoneyInput from '@/components/MoneyInput.vue';
import NumberInput from '@/components/NumberInput.vue';
import RefreshButton from '@/components/RefreshButton.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import ConversionBlockMixin from '@/mixins/RuleForm/Blocks/ConversionBlock.js';
import MetaOdaxMixin from '@/mixins/RuleForm/Functions/MetaOdax.js';

export default {
  name: 'ConversionOdaxBlock',
  components: {
    InfoText,
    MoneyInput,
    NumberInput,
    RefreshButton,
  },
  mixins: [ RuleFormBlockMixin, ConversionBlockMixin, MetaOdaxMixin ],
  props: {
    title: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    minimal: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    selectedObjective() {
      const { objective } = this.entity.output_campaign;
      return this.fbOdaxMap.find(item => item.machine_name === objective);
    },
    destinationTypes() {
      const list = JSON.parse(JSON.stringify(this.selectedObjective.destination_types));
      list.forEach(item => {
        if (item.machine_name === 'instagram_profile') {
          item.disabled = this.entity.hasSelectedFacebookPlatform || !this.entity.hasSelectedInstagramPlatform;
        }
      });

      return list;
    },
    selectedDestinationType() {
      if (this.entity.settings.adset_conversion_location === null) {
        return null;
      }

      return this.destinationTypes.find(item => item.machine_name === this.entity.settings.adset_conversion_location);
    },
    optimizationGoals() {
      if (!this.selectedDestinationType) {
        return [];
      }

      return this.conditionalFilteredList(this.selectedDestinationType.optimization_goals);
    },
    destiantionTypeSelectVisible() {
      return this.destinationTypes.length > 1;
    },
    appTypeList() {
      if (!this.entity.selectedApp) {
        return [];
      }

      const list = [];
      if (this.entity.selectedApp.hasGooglePlayApp) {
        list.push({ value: this.entity.selectedApp.google_play_url, label: 'Google Play' });
      }
      if (this.entity.selectedApp.getAppStoreApp) {
        list.push({ value: this.entity.selectedApp.app_store_url, label: 'Apple App Store' });
      }

      return list;
    },
    bidAmountLabel() {
      return this.entity.isBidCapStrategy ? this.tr('Bid cap') : this.tr('Cost per result goal');
    },
  },
  watch: {
    'entity.ad_account.id'(val) {
      if (!val) {
        return;
      }

      this.updateConversionEvents();
    },
    'entity.isAppPromo'(val) {
      if (val) {
        this.entity.settings.adset_conversion_location = 'app';
      }
      else {
        this.entity.settings.adset_conversion_location = 'website';
      }

      this.$nextTick(() => this.updateConversionEvents());
    },
    'entity.settings.adset_conversion_location'(val) {
      if (this.optimizationGoals.length === 0) {
        this.entity.settings.adset_optimization_goal = null;
      }
      else {
        this.entity.settings.adset_optimization_goal = this.optimizationGoals[0].machine_name;
      }

      if (val === 'instagram_profile') {
        this.entity.settings.cta_button = 'NO_BUTTON';
      }
    },
    fbOdaxConversionAppIsVisible(val) {
      if (!val) {
        this.entity.settings.adset_conversion_app_id = null;
        this.entity.settings.adset_conversion_app_store_url = null;
      }
    },
    fbOdaxConversionPixelIsVisible(val) {
      if (!val) {
        this.entity.settings.adset_conversion_pixel_id = null;
      }
    },
    fbOdaxConversionEventIsVisible(val) {
      if (!val) {
        this.entity.settings.adset_conversion_event = null;
      }
    },
    appTypeList(val) {
      if (val.length !== 0) {
        this.entity.settings.adset_conversion_app_store_url = val[0].value;
      }
    },
    'entity.pixelListForSelect'(val) {
      if (val.length !== 0) {
        this.entity.settings.adset_conversion_pixel_id = val[0].value;
      }
    },
  },
  created() {
    if (
      !this.entity.settings.adset_conversion_location
      || !this.destinationTypes.map(i => i.machine_name).includes(this.entity.settings.adset_conversion_location)
      || this.destinationTypes.find(i => i.machine_name === this.entity.settings.adset_conversion_location).disabled
    ) {
      this.entity.settings.adset_conversion_location = this.destinationTypes.filter(i => !i.disabled)[0].machine_name;
    }
    else if (!this.optimizationGoals.map(i => i.machine_name).includes(this.entity.settings.adset_optimization_goal)) {
      this.entity.settings.adset_optimization_goal = this.optimizationGoals[0].machine_name;
    }

    if (
      (!this.entity.settings.adset_conversion_pixel_id && !this.entity.settings.adset_conversion_app_id)
      || (
        this.entity.settings.adset_conversion_pixel_id
        && !this.entity.pixelListForSelect.map(i => i.value).includes(this.entity.settings.adset_conversion_pixel_id)
      )
      || (
        this.entity.settings.adset_conversion_app_id
        && !this.entity.appList.map(i => i.id).includes(this.entity.settings.adset_conversion_app_id)
      )
    ) {
      this.updateConversionEvents();
    }
  },
  methods: {
    updateConversionEvents() {
      if (this.entity.isAppPromo) {
        if (this.entity.appList.length === 0) {
          this.entity.settings.adset_conversion_app_id = null;
        }
        else if (!this.entity.appList.map(i => i.id).includes(this.entity.settings.adset_conversion_app_id)) {
          this.entity.settings.adset_conversion_app_id = this.entity.appList[0].id;
        }

        if (this.appTypeList.length === 0) {
          this.entity.settings.adset_conversion_app_store_url = null;
        }
        else if (!this.appTypeList.map(i => i.id).includes(this.entity.settings.adset_conversion_app_store_url)) {
          this.entity.settings.adset_conversion_app_store_url = this.appTypeList[0].id;
        }

        this.entity.settings.adset_conversion_pixel_id = null;
        this.entity.settings.adset_conversion_event = null;
      }
      else {
        if (this.entity.pixelListForSelect.length === 0) {
          this.entity.settings.adset_conversion_pixel_id = null;
        }
        else if (!this.entity.pixelListForSelect.map(i => i.value).includes(this.entity.settings.adset_conversion_pixel_id)) {
          this.entity.settings.adset_conversion_pixel_id = this.entity.pixelListForSelect[0].value;
        }

        if (this.conversionEvents.length === 0) {
          this.entity.settings.adset_conversion_event = null;
        }
        else if (this.entity.isSalesCampaign) {
          this.entity.settings.adset_conversion_event = 'PURCHASE';
        }
        else if (!this.conversionEvents.map(i => i.machine_name).includes(this.entity.settings.adset_conversion_event)) {
          this.entity.settings.adset_conversion_event = this.conversionEvents[0].machine_name;
        }

        this.entity.settings.adset_conversion_app_id = null;
        this.entity.settings.adset_conversion_app_store_url = null;
      }
    },
    validate() {
      if (this.entity.bidAmountIsRequired && this.entity.settings.adset_bid_amount <= 0) {
        if (this.entity.isBidCapStrategy) {
          this.error(this.tr(':field value is required if you use "Bid Cap" bid strategy!', { ':field': this.bidAmountLabel }));
          return false;
        }
        if (this.entity.isCostPerResultGoalStrategy) {
          this.error(this.tr(':field value is required if you use "Cost per result goal" bid strategy!', { ':field': this.bidAmountLabel }));
          return false;
        }
      }

      if (this.entity.isRoasGoalCampaignStrategy && this.entity.settings.adset_roas_goal <= 0) {
        this.error(this.tr(':field value is required if you use "ROAS goal" campaign bid strategy!', { ':field': this.tr('ROAS goal') }));
        return false;
      }

      if (
        this.entity.settings.adset_roas_goal !== null
        && this.entity.settings.adset_roas_goal.toString().length !== 0
        && this.entity.settings.adset_roas_goal <= 0
      ) {
        this.error(this.tr('validation.min', { '{field}': this.tr('ROAS goal') }));
        return false;
      }
      else if (this.entity.settings.adset_roas_goal > 1000) {
        this.error(this.tr('validation.max', { '{field}': this.tr('ROAS goal') }));
        return false;
      }

      return true;
    },
  },
};
</script>
