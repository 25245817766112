<template>
  <div class="ai-score-component">
    <div v-if="value !== null && label">
      {{ tr(label) }}
    </div>
    <div class="value">
      <i v-if="value === null" class="icon loader white" />
      <template v-else>{{ value.toFixed(1) }}</template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AiScore',
  props: {
    label: {
      type: String,
      required: false,
      default: () => '',
    },
    value: {
      type: [ Number, null ],
      required: false,
      default: () => null,
    },
  },
};
</script>
