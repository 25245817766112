<template>
  <div class="integrations-meta-view">
    <sticky-header :title="tr('Meta Business')">
      <template v-slot:buttons>
        <button
          v-if="connectButtonVisible"
          :class="connectProfileButtonClasses"
          :disabled="isConnecting"
          @click="() => connect(true)"
        >
          <i v-if="isConnecting" class="icon loader white" />
          {{ tr('Connect Profile') }}
        </button>
      </template>

      <form-step-tab :items="tabs" />
    </sticky-header>

    <router-view
      @connect="() => connect(true)"
      @reconnect="(profile) => reconnect(profile)"
    />
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import ConnectFacebookProfileMixin from '@/mixins/ConnectFacebookProfile';

export default {
  name: 'IntegrationsMetaView',
  components: {
    FormStepTab,
    StickyHeader,
  },
  mixins: [ ConnectFacebookProfileMixin ],
  computed: {
    tabs() {
      if (this.$app.isAd) {
        return [
          {
            label: this.tr('Ad Accounts'),
            link: '/integrations/meta/ad-accounts',
          },
          {
            label: this.tr('Facebook Pages'),
            link: '/integrations/meta/facebook-pages',
          },
          {
            label: this.tr('Instagram Accounts'),
            link: '/integrations/meta/instagram-accounts',
          },
          {
            label: this.tr('Profiles'),
            link: '/integrations/meta/profiles',
          },
        ];
      }

      return [
        {
          label: this.tr('Facebook Pages'),
          link: '/integrations/meta/facebook-pages',
        },
        {
          label: this.tr('Instagram Accounts'),
          link: '/integrations/meta/instagram-accounts',
        },
        {
          label: this.tr('Profiles'),
          link: '/integrations/meta/profiles',
        },
      ];
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
  },
};
</script>
