<template>
  <div class="company-selector-component">
    <dropdown-menu v-model="isOpen" :interactive="true" transition="slide-top-to-bottom">
      <div class="main">
        <label>
          {{ currentCompanyLabel }}
        </label>
        <i class="icon arrow-right dark-gray" />
      </div>
      <div slot="dropdown">
        <div class="search-wrapper">
          <input
            v-model="term"
            type="text"
            autocomplete="off"
            :placeholder="tr('Search') + '...'"
          />

          <div class="divider" />
        </div>

        <div
          v-for="company of filteredCompanyies"
          :key="'company-selector-option-' + company.id"
          :class="{ 'active': currentCompanyId === company.id }"
          @click="() => select(company.id)"
        >
          <i v-if="loadingCompanyId === company.id" class="icon loader" />
          <i v-else-if="company.id === $root.loggedInUser.company.id" :class="'icon star ' + (currentCompanyId === company.id ? 'white' : 'light-black')" />
          <span>{{ company.name }}</span>
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';

export default {
  name: 'LanguageSelector',
  components: {
    DropdownMenu,
  },
  data: () => ({
    isOpen: false,
    term: '',
    loadingCompanyId: null,
  }),
  computed: {
    currentCompanyId() {
      return this.$root.currentCompany.id;
    },
    currentCompanyLabel() {
      const currentCompany = this.availableCompanies.find(i => i.id === this.currentCompanyId);
      return currentCompany ? currentCompany.name : 'Unknown company';
    },
    availableCompanies() {
      const originalCompany = this.$root.loggedInUser.company;
      const companies = originalCompany.managed_companies.map(company => ({
        id: company.id,
        name: company.name,
      }));

      companies.sort((a, b) => a.name.localeCompare(b.name));
      companies.unshift(originalCompany);

      return companies;
    },
    filteredCompanyies() {
      let companies = this.availableCompanies;

      if (this.term.length !== 0) {
        const term = this.term.toLowerCase().replace(/[^a-zA-Z0-9]/ , '');
        companies = companies.filter(company => company.name.toLowerCase().replace(/[^a-zA-Z0-9]/ , '').includes(term));
      }

      return companies;
    },
  },
  watch: {
    isOpen(val) {
      if (!val) {
        setTimeout(() => this.term = '', 100);
      }
    },
  },
  methods: {
    select(companyId) {
      if (
        this.loadingCompanyId !== null
        || companyId === this.currentCompanyId
      ) {
        return;
      }

      this.loadingCompanyId = companyId;

      this.$store.commit('auth/selectCompany', companyId);

      setTimeout(() => window.location = '/dashboard', 250);
    },
  },
};
</script>
