<template>
  <div class="formsteptab-component">
    <div class="steps-container">
      <component
        :is="component"
        v-for="(item, i) in items"
        :key="'tab' + i"
        :to="toAttribute(item)"
        :class="tabClasses(i)"
        @click="() => onClick(i)"
      >
        {{ label(item) }}
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStepTab',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: () => 0,
    },
    allowedIndex: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    component() {
      return typeof this.items[0] === 'string' ? 'div' : 'access-handled-router-link';
    },
  },
  methods: {
    onClick(i) {
      if (this.isInactive(i)) {
        return;
      }

      this.$emit('input', i);
    },
    tabClasses(i) {
      return {
        'tab': true,
        'active': i === this.value && typeof this.items[i] === 'string',
        'inactive': this.isInactive(i),
      };
    },
    isInactive(i) {
      return this.allowedIndex !== null && i > this.allowedIndex;
    },
    label(item) {
      if (typeof item === 'string') {
        return item;
      }

      return item.label ?? '';
    },
    toAttribute(item) {
      if (typeof item === 'string' || !item.link) {
        return null;
      }

      return item.link;
    },
  },
};
</script>
