var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-view" },
    [
      _c(
        "sticky-header",
        {
          attrs: { title: _vm.rule ? _vm.rule.name : _vm.tr("Loading...") },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "button bordered",
                      attrs: { to: "/automation" },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Back")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("form-step-tab", { attrs: { items: _vm.tabs } })],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }