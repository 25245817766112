import BaseEntity from '@/entities/BaseEntity';

export default class PostMetric extends BaseEntity {
  reach = null;
  reach_paid = null;
  reach_organic = null;
  reach_viral = null;
  reach_by_followers = null;
  impressions = null;
  impressions_paid = null;
  impressions_organic = null;
  impressions_viral = null;
  impressions_by_followers = null;
  clicks = null;
  clicks_unique = null;
  shares = null;
  reactions = null;
  reactions_like = null;
  reactions_love = null;
  reactions_wow = null;
  reactions_haha = null;
  reactions_sad = null;
  reactions_angry = null;
  consumers = null;
  video_views = null;
  video_views_organic = null;
  video_views_paid = null;
  total_video_views = null;
  total_video_views_organic = null;
  total_video_views_paid = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get ctr() {
    if (!this.clicks || !this.impressions) {
      return 0;
    }

    return this.clicks / this.impressions * 100;
  }
}
