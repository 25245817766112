<template>
  <div class="copy-evaluation-component">
    <div
      v-for="(row, key) in details"
      :key="'ai-meta-' + key"
      class="meta"
    >
      <div class="title">{{ tr(row.title) }}: {{ row.value }} / 10</div>
      <div v-if="row.details" class="details">
        {{ row.details }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyEvaluation',
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
};
</script>
