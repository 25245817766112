/* eslint-disable filenames/match-regex */

import User from '@/entities/User';
import AuthService from '@/services/Auth';

const state = {
  list: [],
};
const getters = {
  list(state) {
    return state.list;
  },
};
const actions = {
  fetch(store) {
    if (state.list.length !== 0) {
      return Promise.resolve(state.list);
    }

    return AuthService.fetchInvitations().then(resp => {
      store.commit('setList', resp);
      return resp;
    });
  },
  createInvitation(store, data) {
    return AuthService.createInvitation(data)
      .then(resp => {
        store.commit('addToList', resp);

        return resp;
      });
  },
  deleteInvitation(store, entity) {
    return AuthService.deleteInvitation(entity.id).then(store.commit('deleteFromList', entity));
  },
  resendInvitation(store, entity) {
    return AuthService.resendInvitation(entity.id);
  },
};
const mutations = {
  setList(state, list) {
    state.list = [];

    for (const item of list) {
      if (item instanceof User) {
        state.list.push(item);
      }
      else {
        state.list.push(new User(item));
      }
    }
  },
  addToList(state, entity) {
    state.list.push(entity);
  },
  deleteFromList(state, entity) {
    for (let i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
