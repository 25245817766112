import { store } from '@/store';

const shortMonths = [
  'Jan.',
  'Febr.',
  'Mar.',
  'Apr.',
  'May',
  'June',
  'July',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];

const deviceTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
function getUserTimezone() {
  if (
    store.getters['auth/user']
    && store.getters['auth/user'].company
    && store.getters['auth/user'].company.timezone
  ) {
    return store.getters['auth/user'].company.timezone;
  }

  return deviceTimeZone;
}

export function formatTime(date) {
  if (!(date instanceof Date)) {
    return '???';
  }

  const timeZone = getUserTimezone();
  const correctedDate = new Date(date.toLocaleString('en', { timeZone }));

  let hours = correctedDate.getHours();
  const minutes = correctedDate.getMinutes();
  let endTag = '';

  if (store.getters['app/language'] !== 'hu') {
    if (hours > 12) {
      hours = hours - 12;
      endTag = 'PM';
    }
    else if (hours === 12) {
      endTag = 'PM';
    }
    else {
      endTag = 'AM';
    }
  }

  return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + endTag;
}

export function formatToMD(date) {
  return formatToYMD(date, false);
}

export function formatToYMD(date, withYear = true) {
  if (!(date instanceof Date)) {
    return '-';
  }

  const timeZone = getUserTimezone();
  const correctedDate = new Date(date.toLocaleString('en', { timeZone }));

  const month = correctedDate.getMonth() + 1;
  const day = correctedDate.getDate();

  let result = month.toString().padStart(2, '0') + '. ' + day.toString().padStart(2, '0');

  if (withYear) {
    const year = correctedDate.getFullYear();
    result = year + '. ' + result;
  }

  return result;
}

export function formatToStringMD(d, tr) {
  return formatToStringYMD(d, tr, false);
}

export function formatToStringYMD(d, tr, withYear = true) {
  if (!(d instanceof Date)) {
    return '???';
  }

  const timeZone = getUserTimezone();
  const correctedDate = new Date(d.toLocaleString('en', { timeZone }));

  let month = shortMonths[correctedDate.getMonth()];
  const date = correctedDate.getDate();

  if (tr) {
    month = tr(month);
  }

  if (!withYear) {
    return month + ' ' + date + '.';
  }

  return correctedDate.getFullYear() + '. ' + month.toLocaleLowerCase() + ' ' + date + '.';
}

export function timeAgo(d, tr) {
  const now = Date.now();
  const time = d.getTime();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 120) {
    return tr(':num minute ago', { ':num': 1 });
  }
  if (diffInSeconds < 3600) {
    return tr(':num minutes ago', { ':num': Math.floor(diffInSeconds / 60) });
  }
  if (diffInSeconds < 2 * 3600) {
    return tr(':num hour ago', { ':num': 1 });
  }
  if (diffInSeconds < 86400) {
    return tr(':num hours ago', { ':num': Math.floor(diffInSeconds / 3600) });
  }
  if (diffInSeconds < 2 * 86400) {
    return tr(':num day ago', { ':num': 1 });
  }

  return tr(':num days ago', { ':num': Math.floor(diffInSeconds / 86400) });
}

export function modifyDate(date, size) {
  const [ amount, unit ] = size.split(' ');
  const newDate = new Date(date);

  switch (unit) {
    case 'min':
    case 'mins':
    case 'minute':
    case 'minutes':
      newDate.setMinutes(newDate.getMinutes() + parseInt(amount));
      break;

    case 'hour':
    case 'hours':
      newDate.setHours(newDate.getHours() + parseInt(amount));
      break;

    case 'day':
    case 'days':
      newDate.setDate(newDate.getDate() + parseInt(amount));
      break;

    case 'month':
    case 'months':
      newDate.setMonth(newDate.getMonth() + parseInt(amount));
      break;

    case 'year':
    case 'years':
      newDate.setFullYear(newDate.getFullYear() + parseInt(amount));
      break;
  }

  return newDate;
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);
  if (!(date instanceof Date) || isNaN(date)) {
    return '???';
  }

  const timeZone = getUserTimezone();
  const correctedDate = new Date(date.toLocaleString('en', { timeZone }));

  const year = correctedDate.getFullYear();
  const month = (correctedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = correctedDate.getDate().toString().padStart(2, '0');
  const hours = correctedDate.getHours().toString().padStart(2, '0');
  const minutes = correctedDate.getMinutes().toString().padStart(2, '0');
  const seconds = correctedDate.getSeconds().toString().padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}

export function getUserCountry() {
  const { countries, zones } = require('moment-timezone/data/meta/latest.json');
  const timeZoneCityToCountry = {};
  Object.keys(zones).forEach(zoneKey => {
    const zone = zones[zoneKey];
    const city = zoneKey.split('/').pop();
    timeZoneCityToCountry[city] = countries[zone.countries[0]].name;
  });

  const userCity = deviceTimeZone.split('/').pop();
  return timeZoneCityToCountry[userCity];
}
