<template>
  <div class="gender-selector">
    <div class="checkbox-list horizontal">
      <label>
        <input
          v-model="insideValue"
          type="radio"
          name="gender"
          value="all"
        />
        {{ tr('All') }}
      </label>
      <label>
        <input
          v-model="insideValue"
          type="radio"
          name="gender"
          value="men"
        />
        {{ tr('Men') }}
      </label>
      <label>
        <input
          v-model="insideValue"
          type="radio"
          name="gender"
          value="women"
        />
        {{ tr('Women') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenderSelector',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    insideValue: '',
  }),
  watch: {
    insideValue(val, oldVal) {
      if (!oldVal) {
        return;
      }
      this.$emit('input', this.insideValue);
    },
    value: {
      immediate: true,
      handler() {
        this.insideValue = this.value;
      },
    },
  },
  created() {
    this.initDefaultValue();
  },
  methods: {
    initDefaultValue() {
      if (!this.value) {
        this.insideValue = 'all';
      }
    },
  },
};
</script>
