import BaseEntity from '@/entities/BaseEntity';

export default class Customconversion extends BaseEntity {
  id = null;
  name = '';
  rule = '';
  pixel_id = '';
  last_fired_time = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);

    for (const [ key, value ] of Object.entries(rawData)) {
      if (!(key in this)) {
        continue;
      }

      if (
        key === 'last_fired_time'
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }
}
