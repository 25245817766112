<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--start--step-2">
    <campaign-name-block :entity="entity" :disabled="!entity.isNew" />

    <div class="divider" />

    <campaign-budget-block :entity="entity" :disabled="$parent.readonlyMode" switchable />

    <div class="divider" />

    <campaign-special-ad-categories-block :entity="entity" :disabled="!entity.isNew" />

    <div class="divider" />

    <!-- <campaign-spending-limit-block :entity="entity" :disabled="$parent.readonlyMode" /> -->
  </div>
</template>

<script>
import CampaignBudgetBlock from '@/components/RuleForm/Blocks/CampaignBudgetBlock.vue';
import CampaignNameBlock from '@/components/RuleForm/Blocks/CampaignNameBlock.vue';
import CampaignSpecialAdCategoriesBlock from '@/components/RuleForm/Blocks/CampaignSpecialAdCategoriesBlock.vue';
// import CampaignSpendingLimitBlock from '@/components/RuleForm/Blocks/CampaignSpendingLimitBlock.vue';
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

export default {
  name: 'StartRuleFormStep2',
  components: {
    CampaignBudgetBlock,
    CampaignNameBlock,
    CampaignSpecialAdCategoriesBlock,
    // CampaignSpendingLimitBlock,
  },
  mixins: [ RuleFormStepMixin ],
  watch: {
    'entity.output_campaign.bid_strategy'() {
      if (this.entity.isRoasGoalCampaignStrategy) {
        this.entity.settings.adset_conversion_location = 'website';
        this.entity.settings.adset_optimization_goal = 'VALUE';

        if (this.entity.pixelListForSelect.length > 0) {
          this.entity.settings.adset_conversion_pixel_id = this.entity.pixelListForSelect[0].value;
          this.entity.settings.adset_conversion_event = 'PURCHASE';
        }
      }
    },
  },
};
</script>
