<template>
  <div class="image-input-component">
    <div v-if="showButton" class="button-wrapper">
      <label
        :class="buttonClasses"
        @click="($event) => onClickButton($event)"
      >
        <i :class="buttonIconClasses" />
        {{ buttonLabel }}

        <input
          ref="fileInput"
          type="file"
          accept="image/jpeg, image/png, image/webp"
          @change="onFileChange"
        />
      </label>

      <div class="note">
        {{ tr('Maximum upload size :limit (jpg, png or webp)', { ':limit': readableLimit }) }}
      </div>
    </div>

    <div v-if="imagePreview" class="image-wrapper">
      <img :src="imagePreview" />

      <div class="remove-button" @click="() => removeImage()">
        <i class="icon close white" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [ File, null ],
      required: false,
      default: () => null,
    },
    label: {
      type: String,
      required: true,
    },
    activeLabel: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    sizeLimit: {
      type: Number,
      required: false,
      default: 5 * 1024 * 1024, // 5 MB
    },
  },
  data: () => ({
    imagePreview: null,
  }),
  computed: {
    readableLimit() {
      const limit = this.sizeLimit / 1024 / 1024;
      const stringLimit = limit.toFixed(1);
      const splittedLimit = stringLimit.replace('.0', '');
      return splittedLimit + ' MB';
    },
    active() {
      return this.imagePreview !== null;
    },
    showButton() {
      return !this.active || this.activeLabel;
    },
    buttonClasses() {
      return {
        'browse-button': true,
        'active': this.active,
      };
    },
    buttonIconClasses() {
      return {
        'icon': true,
        'upload': !this.active,
        'close': this.active,
      };
    },
    buttonLabel() {
      if (!this.active) {
        return this.label;
      }

      return this.activeLabel ?? this.label;
    },
  },
  watch: {
    value(newFile) {
      if (newFile) {
        this.imagePreview = URL.createObjectURL(newFile);
      }
      else {
        this.imagePreview = null;
      }
    },
  },
  methods: {
    onFileChange(event) {
      if (event.target.files.length === 0) {
        this.$emit('input', null);
        return;
      }

      const file = event.target.files[0];
      if (!file) {
        this.$emit('input', null);
        return;
      }

      if (file.size > this.sizeLimit) {
        this.error(this.tr('Image size is too big. Maximum size is :size.', { ':size': this.readableLimit }));
        return;
      }

      this.$emit('input', file);
    },
    onClickButton($event) {
      if (this.active) {
        $event.preventDefault();
        $event.stopPropagation();
        this.removeImage();
      }
    },
    removeImage() {
      this.$emit('input', null);
    },
  },
};
</script>
