<template>
  <modal
    name="change-password-modal"
    overlayTransition="fade-modal-overlay"
    @closed="modalClosed"
  >
    <div class="modal-title">
      {{ tr('Change password') }}
    </div>
    <div class="modal-content">
      <div>
        <span>
          {{ tr('Current password') }}
        </span>
        <input
          v-model="currentPassword"
          type="password"
          :placeholder="tr('Enter current password here')"
        />
      </div>
      <div>
        <span>
          {{ tr('New password') }}
        </span>
        <input
          v-model="newPassword"
          type="password"
          :placeholder="tr('Enter new password here')"
        />
      </div>
    </div>
    <div class="modal-action-group">
      <button class="bordered" @click="() => close()">
        {{ tr('Cancel') }}
      </button>
      <button
        class="primary"
        :disabled="isLoading"
        @click="() => submit()"
      >
        <i v-if="isLoading" class="icon loader white" />
        <template v-else>
          {{ tr('Change') }}
        </template>
      </button>
    </div>
  </modal>
</template>

<script>
import AuthService from '@/services/Auth';

export default {
  name: 'ChangePasswordModal',
  data: () => ({
    currentPassword: '',
    newPassword: '',
    isLoading: false,
  }),
  methods: {
    open() {
      this.$modal.show('change-password-modal');
    },
    close() {
      this.$modal.hide('change-password-modal');
    },
    modalClosed() {
      this.currentPassword = '';
      this.newPassword = '';
    },
    validate() {
      this.$toast.clear();
      this.errorField = '';

      if (!this.newPassword || !this.currentPassword) {
        this.$toast.error(this.tr('Password cannot be empty!'));
        return false;
      }

      if (this.newPassword.length < 8) {
        this.$toast.error(this.tr('The password must be at least 8 characters long.'));
        this.errorField = 'new-password';
        return false;
      }

      return true;
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      const data = {
        id: this.$root.loggedInUser.id,
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      AuthService.updatePassword(data)
        .then(resp => {
          this.$toast.success(this.tr('Password changed successfully!'));
          this.$modal.hide('password-change-modal');

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => {
          this.close();
          this.isLoading = false;
        });
    },
  },
};
</script>
