<template>
  <component
    :is="parentElement"
    :class="classes"
    :to="link"
  >
    <div class="content">
      <i :class="'icon white ' + computedIcon" />
      <div class="text">
        <slot />
      </div>
    </div>

    <router-link
      v-if="computedLink"
      :to="computedLink"
      class="link"
    >
      {{ tr(buttonLabel) }}
    </router-link>
  </component>
</template>

<script>
export default {
  name: 'InfoText',
  props: {
    type: {
      type: String,
      required: false,
      default: () => 'info',
    },
    icon: {
      type: String,
      required: false,
      default: () => null,
    },
    link: {
      type: String,
      required: false,
      default: () => null,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: () => 'View',
    },
    large: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    computedLink() {
      if (!this.$root.isInOriginalCompany) {
        return null;
      }

      return this.link;
    },
    parentElement() {
      if (this.computedLink) {
        return 'router-link';
      }

      return 'div';
    },
    classes() {
      const classes = { 'info-text-component': true };
      classes[this.type] = true;

      if (this.large) {
        classes['large'] = true;
      }

      return classes;
    },
    computedIcon() {
      if (this.icon) {
        return this.icon;
      }

      switch (this.type) {
        case 'warning':
        case 'error':
          return 'warning';
      }

      return 'info-filled';
    },
  },
};
</script>
