var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rule-overview-view" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("sticky-container", { attrs: { active: _vm.stickyIsActive } }, [
          _c(
            "div",
            { staticClass: "action-buttons" },
            [
              _c(
                "router-link",
                {
                  class: "button primary " + (_vm.isLoading ? "disabled" : ""),
                  attrs: {
                    to: _vm.isLoading
                      ? ""
                      : "/automation/" + _vm.rule.id + "/edit",
                  },
                },
                [
                  _c("i", { staticClass: "icon edit white" }),
                  _vm._v(" " + _vm._s(_vm.tr("Edit")) + " "),
                ]
              ),
              _c(
                "router-link",
                {
                  class: "button bordered " + (_vm.isLoading ? "disabled" : ""),
                  attrs: {
                    to: _vm.isLoading
                      ? ""
                      : "/automation/" + _vm.rule.id + "/duplicate",
                  },
                },
                [
                  _c("i", { staticClass: "icon copy blue" }),
                  _vm._v(" " + _vm._s(_vm.tr("Duplicate")) + " "),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rule-details" },
            [
              _c("card", {
                attrs: {
                  "header-color":
                    _vm.rule && _vm.rule.active ? "primary" : "inactive",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        !_vm.rule
                          ? _c("switches")
                          : _c("switches", {
                              attrs: {
                                disabled: _vm.statusSwitchIsDisabled,
                                "emit-on-mount": false,
                              },
                              on: {
                                input: () => _vm.onChangeRuleStatus(_vm.rule),
                              },
                              model: {
                                value: _vm.rule.active,
                                callback: function ($$v) {
                                  _vm.$set(_vm.rule, "active", $$v)
                                },
                                expression: "rule.active",
                              },
                            }),
                        _vm._v(" " + _vm._s(_vm.tr("Automation")) + ": "),
                        _vm.isLoading
                          ? [_vm._v(" ... ")]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.rule.active
                                      ? _vm.tr("Active")
                                      : _vm.tr("Inactive")
                                  ) +
                                  " "
                              ),
                            ],
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.isLoading
                          ? [
                              _c("div", [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.tr("Automation type")) + ":"
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    class: "flag action " + _vm.actionFlagClass,
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.actionFlagLabel(_vm.rule.action)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.rule.action !== "stop"
                                ? _c("div", [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Budget")) + ":"),
                                    ]),
                                    _vm._v(" " + _vm._s(_vm.budget) + " "),
                                  ])
                                : _vm._e(),
                              _vm.rule.action !== "stop"
                                ? _c("div", [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.tr("Number of active ads")) +
                                          ":"
                                      ),
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(_vm.rule.active_ads) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.tr("Ad Account")) + ":"),
                                ]),
                                _c("span", { staticClass: "flag purple" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.rule.ad_account
                                          ? _vm.rule.ad_account.name
                                          : "---"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _vm.rule.action === "start"
                                ? _c("div", [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Sources")) + ":"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "sources" },
                                      _vm._l(
                                        _vm.sourceProfiles,
                                        function (profile) {
                                          return _c("source-profile-flag", {
                                            key: profile.id,
                                            attrs: {
                                              name: profile.name,
                                              platform: profile.platform,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : _c(
                              "div",
                              { staticClass: "skeletons" },
                              [
                                _c("skeleton", {
                                  attrs: { width: "40%", height: "14px" },
                                }),
                                _c("skeleton", {
                                  attrs: { width: "60%", height: "14px" },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("card", {
                attrs: {
                  "header-color": !_vm.isLoading ? "primary" : "inactive",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_vm._v(" " + _vm._s(_vm.tr("Activity")) + " ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.isLoading
                          ? [
                              _c("div", [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.tr("Created")) + ":"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToStringYMD(
                                        _vm.rule.created_at,
                                        _vm.tr
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.tr("Last modified")) + ":"
                                    ),
                                  ]),
                                  _vm.rule.updated_at
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  _vm.formatToYMD(
                                                    _vm.rule.updated_at
                                                  ) +
                                                  ". " +
                                                  _vm.formatTime(
                                                    _vm.rule.updated_at
                                                  ),
                                                classes: [""],
                                              },
                                              expression:
                                                "{ content: formatToYMD(rule.updated_at) + '. ' + formatTime(rule.updated_at), classes: [ '' ] }",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.timeAgo(
                                                  _vm.rule.updated_at,
                                                  _vm.tr
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : [_vm._v(" - ")],
                                ],
                                2
                              ),
                              _c(
                                "div",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.tr("Last run")) + ":"),
                                  ]),
                                  _vm.rule.last_run
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  _vm.formatToYMD(
                                                    _vm.rule.last_run
                                                  ) +
                                                  ". " +
                                                  _vm.formatTime(
                                                    _vm.rule.last_run
                                                  ),
                                                classes: [""],
                                              },
                                              expression:
                                                "{ content: formatToYMD(rule.last_run) + '. ' + formatTime(rule.last_run), classes: [ '' ] }",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.timeAgo(
                                                  _vm.rule.last_run,
                                                  _vm.tr
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : [_vm._v(" - ")],
                                ],
                                2
                              ),
                              _vm.rule.active
                                ? _c("div", [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.tr("Next run in")) + ":"
                                      ),
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(_vm.nextRunCountdown) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.tr("Last activity")) + ":"
                                    ),
                                  ]),
                                  _vm.rule.last_activity
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  _vm.formatToYMD(
                                                    _vm.rule.last_activity
                                                  ) +
                                                  ". " +
                                                  _vm.formatTime(
                                                    _vm.rule.last_activity
                                                  ),
                                                classes: [""],
                                              },
                                              expression:
                                                "{ content: formatToYMD(rule.last_activity) + '. ' + formatTime(rule.last_activity), classes: [ '' ] }",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.timeAgo(
                                                  _vm.rule.last_activity,
                                                  _vm.tr
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : [_vm._v(" - ")],
                                ],
                                2
                              ),
                              _c("div", [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.tr("Tasks done")) + ":"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.rule.triggered > 0
                                        ? _vm.rule.triggered
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          : _c(
                              "div",
                              { staticClass: "skeletons" },
                              [
                                _c("skeleton", {
                                  attrs: { width: "40%", height: "14px" },
                                }),
                                _c("skeleton", {
                                  attrs: { width: "60%", height: "14px" },
                                }),
                                _c("skeleton", {
                                  attrs: { width: "30%", height: "14px" },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "card",
                {
                  staticClass: "connected-rules",
                  attrs: {
                    "header-color": !_vm.isLoading ? "primary" : "inactive",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.tr("Connected automations")) + " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  !_vm.isLoading
                    ? [
                        _vm.rule.connected_rules.length === 0
                          ? _c("div", { staticClass: "empty-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.tr("No connected automations")) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.rule.connected_rules,
                          function (connectedRule) {
                            return _c(
                              "access-handled-router-link",
                              {
                                key: connectedRule.id,
                                staticClass: "connected-rule",
                                attrs: {
                                  to:
                                    "/automation/" +
                                    connectedRule.id +
                                    "/overview",
                                },
                              },
                              [
                                _c("i", { staticClass: "icon link blue" }),
                                _vm._v(" " + _vm._s(connectedRule.name) + " "),
                              ]
                            )
                          }
                        ),
                      ]
                    : _c(
                        "div",
                        { staticClass: "skeletons" },
                        [
                          _c("skeleton", {
                            attrs: { width: "40%", height: "14px" },
                          }),
                          _c("skeleton", {
                            attrs: { width: "60%", height: "14px" },
                          }),
                          _c("skeleton", {
                            attrs: { width: "30%", height: "14px" },
                          }),
                        ],
                        1
                      ),
                ],
                2
              ),
            ],
            1
          ),
          !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "action-buttons action-buttons--bottom" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button red",
                      on: {
                        click: function ($event) {
                          return _vm.openDeleteConfirmationModal()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon delete white" }),
                      _vm._v(" " + _vm._s(_vm.tr("Delete")) + " "),
                    ]
                  ),
                  _vm.$root.currentCompany.enabled_manual_run &&
                  _vm.$root.loggedInUser.level === "admin"
                    ? _c(
                        "button",
                        {
                          staticClass: "button primary",
                          attrs: { disabled: _vm.isRunning },
                          on: {
                            click: function ($event) {
                              return _vm.runRule()
                            },
                          },
                        },
                        [
                          _c("i", { class: _vm.runIconClasses }),
                          _vm._v(" " + _vm._s(_vm.tr("Run")) + " "),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c(
          "sticky-container",
          { attrs: { active: _vm.stickyIsActive } },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.tr("Completed tasks")) + " "),
            ]),
            _c("activity-log", {
              ref: "activityLog",
              attrs: { "rule-id": _vm.rule ? _vm.rule.id : null },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }