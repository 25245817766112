var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _vm.$slots["header"]
      ? _c("div", { staticClass: "header", class: _vm.headerColor }, [
          _vm.$slots["header"]
            ? _c(
                "div",
                { staticClass: "header-content" },
                [_vm._t("header")],
                2
              )
            : _vm._e(),
          _vm.$slots["header-decorator"]
            ? _c(
                "div",
                { class: "header-decorator " + _vm.headerDecoratorColor },
                [_vm._t("header-decorator")],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2),
    _vm.$slots["subrow"] || _vm.$slots["subrow-hidable"]
      ? _c(
          "div",
          { staticClass: "sub-row", class: _vm.backgroundClass },
          [
            _c(
              "div",
              { staticClass: "main-text" },
              [
                _vm.$slots["subrow"] ? _vm._t("subrow") : _c("div"),
                _vm.$slots["subrow-hidable"]
                  ? _c(
                      "div",
                      {
                        staticClass: "expand-button",
                        on: { click: _vm.toggleExpand },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isExpanded
                                ? _vm.tr("Hide")
                                : _vm.translatedSubrowOpenerLabel
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass: "icon arrow white",
                          class: { expanded: _vm.isExpanded },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm.$slots["subrow-hidable"]
              ? _c("transition-expand", [
                  _vm.isExpanded
                    ? _c(
                        "div",
                        { staticClass: "hidable-text" },
                        [_vm._t("subrow-hidable")],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }