import api from '@/services/Api';

export default {
  getConfig() {
    return api().get('/app/config');
  },

  getDynamicSubfields(field, adAccountId) {
    const data = {
      field,
      ad_account_id: adAccountId,
    };

    const query = new URLSearchParams(data).toString();

    return api().get('/app/dynamic-subfields?' + query);
  },
};
