<template>
  <div class="multiple-audience-select">
    <div
      v-for="(audienceId, key) in internalValue"
      :key="'audience-row-' + key"
      class="row-wrapper"
    >
      <div class="select-search-input-wrapper">
        <v-select
          ref="select"
          v-model="internalValue[key]"
          :options="audiences"
          :reduce="(obj) => obj.id"
          :clearable="false"
          :searchable="false"
          :placeholder="tr('Select target audience')"
          :dropdownShouldOpen="() => false"
          :disabled="disabled"
          :loading="isLoading"
          label="name"
          @open="() => openedDropdownIndex = key"
          @dropdown-should-open="() => openedDropdownIndex === key"
        />

        <input
          v-if="openedDropdownIndex === key"
          ref="searchInput"
          v-model="term"
          class="search-input"
          type="text"
          @keydown.esc="() => openedDropdownIndex = null"
        />
        <i v-if="openedDropdownIndex === key" class="icon arrow light-black icon-on-search-input" />
      </div>

      <i
        v-if="showDeleteButton"
        class="icon delete dark-gray"
        @click="() => removeRow(key)"
      />

      <div
        v-if="!disabled"
        :class="{ 'menu': true, 'hidden': openedDropdownIndex !== key }"
      >
        <div v-if="externalAudiences.length === 0 && internalAudiences.length === 0" class="empty-text">
          {{ tr('There are no target audiences available.') }}
        </div>

        <dropdown-menu
          v-if="externalAudiences.length !== 0"
          v-model="isExternalOpen"
          :interactive="false"
          :close-on-click-outside="false"
        >
          <div class="group-label">
            <i :class="{ 'icon arrow dark-gray': true, 'opened': isExternalOpen }" />
            {{ tr(externalLabel) }}
          </div>

          <template #dropdown>
            <div
              v-for="item in externalAudiences.filter(item => item.name.toLowerCase().includes(term.toLocaleLowerCase()))"
              :key="item.id"
              class="option"
              :class="{ 'selected': internalValue.includes(item.id) }"
              @click="() => selectValue(key, item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </dropdown-menu>

        <dropdown-menu
          v-if="internalAudiences.length !== 0"
          v-model="isInternalOpen"
          :interactive="false"
          :close-on-click-outside="false"
        >
          <div class="group-label">
            <i :class="{ 'icon arrow dark-gray': true, 'opened': isInternalOpen }" />
            {{ tr('Saved on Infinite∞Ad') }}
          </div>
          <template #dropdown>
            <div
              v-for="item in internalAudiences.filter(item => item.name.toLowerCase().includes(term.toLocaleLowerCase()))"
              :key="item.id"
              class="option"
              :class="{selected: internalValue.includes(item.id)}"
              @click="() => selectValue(key, item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </dropdown-menu>
      </div>
    </div>

    <button
      v-if="showAddButton"
      class="button primary"
      :disabled="disabled || internalValue.includes('')"
      @click="() => addNewRow()"
    >
      + {{ tr('Add audience') }}
    </button>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';

import debounce from '@/utils/debounce.js';

export default {
  name: 'MultipleAudienceSelect',
  components: {
    DropdownMenu,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    externalAudiences: {
      type: Array,
      required: false,
      default: () => [],
    },
    internalAudiences: {
      type: Array,
      required: false,
      default: () => [],
    },
    externalLabel: {
      type: String,
      required: false,
      default: () => 'Saved on Meta',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      debouncedInitSelectedValues: debounce(() => this.initSelectedValues(), 50),
      openedDropdownIndex: null,
      isExternalOpen: true,
      isInternalOpen: false,
      internalValue: [],
      term: '',
    };
  },
  computed: {
    audiences() {
      return [].concat(this.externalAudiences).concat(this.internalAudiences);
    },
    showDeleteButton() {
      return !this.disabled;
    },
    showAddButton() {
      return this.audiences.length === 0 || (!this.disabled && this.internalValue.length < this.audiences.length);
    },
  },
  watch: {
    openedDropdownIndex(val, oldVal) {
      if (val !== null) {
        this.$nextTick(() => {
          if (oldVal === null) {
            document.addEventListener('click', this.documentClickListener);
          }

          setTimeout(() => {
            this.$refs.searchInput[0].focus();
          }, 1);
        });
      }
      else {
        document.removeEventListener('click', this.documentClickListener);
      }

      this.term = '';
    },
    internalAudiences: {
      immediate: true,
      handler() {
        this.debouncedInitSelectedValues();
      },
    },
    externalAudiences: {
      immediate: true,
      handler() {
        this.debouncedInitSelectedValues();
      },
    },
    isLoading(val) {
      if (!val) {
        this.debouncedInitSelectedValues();
      }
    },
  },
  methods: {
    initSelectedValues() {
      if (this.value.length !== 0 && !this.isLoading) {
        const newInternalValue = [];

        const optionValueList = this.audiences.map(i => i.id);
        let value = [ ...JSON.parse(JSON.stringify(this.value)) ];
        value = value.filter(item => {
          if (typeof item === 'object' && 'id' in item) {
            return optionValueList.map(i => i.id).includes(item.id);
          }

          return optionValueList.includes(item);
        });
        newInternalValue.push( ...value );

        this.internalValue = newInternalValue;
        this.emit();
      }
    },
    documentClickListener(e) {
      if (this.$el !== e.target && !this.$el.contains(e.target)) {
        this.closeSelect();
      }
    },
    closeSelect() {
      this.openedDropdownIndex = null;
    },
    addNewRow() {
      this.internalValue.push('');
      this.emit();
    },
    removeRow(rowIndex) {
      this.internalValue.splice(rowIndex, 1);
      this.emit();
    },
    selectValue(key, value) {
      const indexOfSelectedValue = this.internalValue.indexOf(value);
      if (indexOfSelectedValue !== -1 && indexOfSelectedValue !== key) {
        this.$toast.error(this.tr('Cannot set duplicate target audiences!'));
      }
      else {
        this.internalValue[key] = value;
        this.emit();
      }

      this.closeSelect();
    },
    emit() {
      this.$emit('input', this.internalValue.filter(item => !!item));
    },
  },
};
</script>
