<template>
  <form-block
    class="rule-form-block demographic-target-block-component"
    :title="title !== null ? title : tr('Demographic targeting')"
  >
    <div v-if="targetAudienceBlockIsLoading" class="inline-loader">
      <i class="icon loader" />
      {{ tr('Loading...') }}
    </div>

    <info-text v-else-if="entity.output_campaign.hasSpecialAdCategories">
      {{ tr('Ads in special advertising categories cannot target demographic data.') }}
    </info-text>

    <template v-else>
      <div v-if="!hasGenderInSelectedAudiences">
        <label class="input-label">{{ tr('Gender') }}</label>
        <gender-selector v-model="entity.settings.adset_targeting.genders" :disabled="disabled" />
      </div>
      <info-text v-else>{{ tr('You have chosen a target audience where gender targeting is specified.') }}</info-text>

      <div v-if="!hasAgeInSelectedAudiences">
        <label class="input-label">{{ tr('Age') }}</label>
        <div class="age-block">
          <age-selector
            v-model="entity.settings.adset_targeting.age_min"
            :disabled="disabled"
          />
          -
          <age-selector
            v-model="entity.settings.adset_targeting.age_max"
            :disabled="disabled"
          />
        </div>
      </div>
      <info-text v-else>{{ tr('You have chosen a target audience where age targeting is specified.') }}</info-text>
    </template>
  </form-block>
</template>

<script>
import AgeSelector from '@/components/AgeSelector.vue';
import GenderSelector from '@/components/GenderSelector.vue';
import InfoText from '@/components/InfoText.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'DemographicTargetBlock',
  components: {
    AgeSelector,
    GenderSelector,
    InfoText,
  },
  mixins: [ RuleFormBlockMixin ],
  props: {
    title: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    targetAudienceBlock: {
      type: [ Object, null ],
      required: false,
      default: () => null,
    },
    targetAudienceBlockIsLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    hasGenderInSelectedAudiences() {
      if (this.targetAudienceBlockIsLoading) {
        return true;
      }
      if (!this.targetAudienceBlock) {
        return false;
      }

      return this.targetAudienceBlock.hasGenderTargeting;
    },
    hasAgeInSelectedAudiences() {
      if (this.targetAudienceBlockIsLoading) {
        return true;
      }
      if (!this.targetAudienceBlock) {
        return false;
      }

      return this.targetAudienceBlock.hasAgeTargeting;
    },
  },
  methods: {
    validate() {
      return true;
    },
  },
};
</script>
