import BaseEntity from '@/entities/BaseEntity';

export default class PaymentHistoryItem extends BaseEntity {
  id = null;
  title = '';
  net = 0;
  gross = 0;
  vat_rate = 0;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
