<template>
  <div
    v-tooltip="{ content: tooltipContent, classes: [ '' ] }"
    class="rule-next-run-tooltip-component"
    @mouseover="isActive = true"
    @mouseleave="isActive = false"
  >
    <slot />
  </div>
</template>

<script>
import RuleEntity from '@/entities/Rule.js';

export default {
  name: 'RuleNextRunTooltip',
  props: {
    entity: {
      type: [ RuleEntity, null ],
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    isActive: false,
    intervalTimer: null,
    updaterSwitchState: false,
  }),
  computed: {
    timerString() {
      const { nextRunInSeconds } = this.entity;

      if (!(nextRunInSeconds > 0)) {
        return '-';
      }

      // Forcing recalculate result value in every seconds.
      if (this.updaterSwitchState && !this.updaterSwitchState) {
        return false;
      }

      return this.secTotoHHMMSS(nextRunInSeconds);
    },
    tooltipContent() {
      if (!this.isActive) {
        return '';
      }

      return '<div class="tooltip-small-title">' + this.tr('Next run in') + '</div>' + this.timerString;
    },
  },
  watch: {
    isActive(value) {
      if (value) {
        this.initTimer();
      }
      else {
        this.destroyTimer();
      }
    },
  },
  beforeDestroy() {
    this.destroyTimer();
  },
  methods: {
    initTimer() {
      this.updaterSwitchState = !this.updaterSwitchState;

      this.destroyTimer();
      this.intervalTimer = setInterval(() => this.updaterSwitchState = !this.updaterSwitchState, 1000);
    },
    destroyTimer() {
      if (this.intervalTimer !== null) {
        clearInterval(this.intervalTimer);
        this.intervalTimer = null;
      }
    },
    secTotoHHMMSS(secNum) {
      let hours   = Math.floor(secNum / 3600);
      let minutes = Math.floor((secNum - (hours * 3600)) / 60);
      let seconds = Math.floor(secNum - (hours * 3600) - (minutes * 60));

      if (hours   < 10) {
        hours   = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      let result = minutes+ ':' +seconds;
      if (hours !== '00') {
        result = hours + ':' + result;
      }

      return result;
    },
  },
};
</script>
