<template>
  <div class="magic-tool-item-details-component">
    <div class="original">
      <div class="title">
        {{ tr('Original text') }}
      </div>

      <facebook-ad-preview
        :text="entity.text"
        :cta-title="entity.title"
        :cta-description="entity.description"
        :cta-button="previewCtaButton"
        :img-url="entity.image ? entity.image.url : null"
      />

      <div class="ai-values">
        <div v-if="entity.content_actuality_check_status !== null" class="ai-inline-value">
          <template v-if="entity.contentActualityCheckInProgress || entity.expiry_date">
            <b>{{ tr('Content expired at') }}:</b>

            <i v-if="entity.contentActualityCheckInProgress" class="icon loader" />
            <span v-else>
              {{ formatToYMD(entity.expiry_date) + ' / ' + formatTime(entity.expiry_date)}}
            </span>
          </template>
          <b v-else>
            {{ tr('The content has no defined expiration date.') }}
          </b>
        </div>

        <div class="ai-inline-value">
          <b>{{ tr('Meta policy check') }}:</b>

          <i v-if="entity.policyCheckInProgress" class="icon loader" />
          <post-content-policy-check-status v-else :value="entity.policy_check_is_prohibited" />
        </div>

        <div v-if="entity.policy_check_is_prohibited && entity.policy_check_reason" class="ai-non-inline-value">
          <b>{{ tr('Reason for denial') + ':' }}</b>
          {{ entity.policy_check_reason }}
        </div>

        <ai-score :value="entity.value" :label="tr('AI Score') + ':'" />
      </div>

      <copy-evaluation :details="entity.details" />

      <button
        class="button red-inverted"
        @click="() => openDeleteConfirmationModal()"
      >
        {{ tr('Delete evaluation')}}
      </button>
    </div>

    <div class="vertical-divider" />

    <div class="improved">
      <div class="title">
        {{ tr('Improved text') }}
      </div>

      <div v-if="!entity.improved_copy || entity.improved_copy.inProgress" class="request-improved">
        {{ tr('Click on \'Get improved copy\' to get your ad\'s improved version.') }}

        <button
          class="primary"
          :disabled="entity.improved_copy"
          @click="() => improveCopy()"
        >
          <i v-if="entity.improved_copy" class="icon loader white" />
          {{ tr('Get improved copy') }}
        </button>

        <div v-if="entity.improved_copy && entity.improved_copy.inProgress">
          {{ tr('We are already working on it! The evaluation will be completed within 1-2 minutes.') }}
        </div>
      </div>

      <template v-if="entity.improved_copy && entity.improved_copy.isDone">
        <facebook-ad-preview
          :text="entity.improved_copy.improved_text"
          :cta-title="entity.improved_copy.improved_title"
          :cta-description="entity.improved_copy.improved_description"
          :cta-button="previewCtaButton"
        />

        <ai-score :value="entity.improved_copy.value" :label="tr('AI Score') + ':'" />

        <copy-evaluation :details="entity.improved_copy.details" />
      </template>

      <template v-if="entity.improved_copy && !entity.improved_copy.isDone && !entity.improved_copy.inProgress">
        {{ tr('An error occurred while creating the corrected version.') }}
      </template>
    </div>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import CopyEvaluation from '@/components/CopyEvaluation.vue';
import FacebookAdPreview from '@/components/FacebookAdPreview.vue';
import PostContentPolicyCheckStatus from '@/components/PostContentPolicyCheckStatus.vue';
import AdCopyMagicItem from '@/entities/AdCopyMagicItem.js';
import ImprovedCopy from '@/entities/ImprovedCopy.js';
import MagicToolService from '@/services/MagicTool.js';
import { formatTime, formatToYMD } from '@/utils/date';

export default {
  name: 'MagicToolItemDetails',
  components: {
    AiScore,
    CopyEvaluation,
    FacebookAdPreview,
    PostContentPolicyCheckStatus,
  },
  props: {
    entity: {
      type: AdCopyMagicItem,
      required: true,
    },
  },
  computed: {
    previewCtaButton() {
      switch (this.entity.goal) {
        case 'conversion':
          return this.tr('Shop Now');

        case 'traffic':
          return this.tr('Learn more');

        case 'app_promotion':
          return this.tr('Use App');
      }

      return null;
    },
  },
  methods: {
    formatTime,
    formatToYMD,
    improveCopy() {
      if (this.entity.improved_copy) {
        return;
      }

      this.entity.improved_copy = new ImprovedCopy({ status: 1 });

      MagicToolService.improve({ adCopyMagicItemId: this.entity.id })
        .then(resp => {
          this.entity.improved_copy = resp;

          this.$toast.clear();
          this.$toast.success(this.tr('We are already working on it! The improved copy will be ready within 1-2 minutes.'));

          this.$emit('entity-improve-begin', this.entity);

          return resp;
        })
        .catch(err => {
          this.entity.improved_copy = null;
          this.error(err);
        });
    },
    openDeleteConfirmationModal() {
      this.$confirmation.open({
        className: 'delete-confirm',
        image: require('@/assets/img/delete-confirm.png'),
        imageClass: 'delete-confirm-image',
        title: this.tr('Are you sure you want to delete this item?'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirmClass: 'red',
        confirm: () => MagicToolService.delete(this.entity.id)
          .then(resp => {
            this.$emit('entity-deleted', this.entity);

            this.$toast.clear();
            this.$toast.success(this.tr('Item was successfully deleted.'));

            return resp;
          }),
      });
    },
  },
};
</script>
