var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      !_vm.minimal
        ? _c("system-level-notifications", { attrs: { "space-top": "" } })
        : _vm._e(),
      _vm.list.length > 10 && !_vm.minimal
        ? _c("div", { staticClass: "actions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "text",
              attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.list.length === 0
        ? [
            !_vm.hasConnectedProfile
              ? _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/onboarding/step-profile.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "If you want to use an ad account, first you should connect you Meta Profile here"
                          )
                        ) +
                        " "
                    ),
                    _vm.connectButtonVisible
                      ? _c(
                          "button",
                          {
                            staticClass: "button primary",
                            on: { click: () => _vm.$emit("connect") },
                          },
                          [
                            _vm._v(
                              " + " +
                                _vm._s(_vm.tr("Connect Your Profile")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _c("notification-card", { attrs: { "empty-screen": "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "There are no Ad accounts connected to this user's company."
                        )
                      ) +
                      " "
                  ),
                ]),
          ]
        : _vm.filteredList.length === 0
        ? _c(
            "notification-card",
            { staticClass: "no-result", attrs: { "empty-screen": "" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "There are no results matching your search criteria."
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _c("sorted-table", {
            staticClass: "row-hover-shadow",
            attrs: {
              data: _vm.filteredList,
              "initial-sort": { key: "name", direction: "asc" },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "name",
                          "min-width": "200px",
                          sortable: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Name")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "status-cell",
                        attrs: { col: "is_active", width: "180px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Status")))]
                    ),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          {
                            staticClass: "external-id-cell",
                            attrs: {
                              col: "external_id",
                              width: "160px",
                              sortable: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.tr("Account ID")) + " ")]
                        )
                      : _vm._e(),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          { attrs: { col: "connected_by", width: "180px" } },
                          [_vm._v(_vm._s(_vm.tr("Connected by")))]
                        )
                      : _vm._e(),
                    _c("table-head", {
                      attrs: { col: "actions", "max-width": "100px" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      { key: row.external_id },
                      [
                        _c("table-data", { attrs: { col: "name" } }, [
                          _c("label", [
                            _vm.checkboxIsDisabled(row)
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.tr("Not allowed"),
                                      expression: "tr('Not allowed')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  attrs: { type: "checkbox", disabled: "" },
                                })
                              : _vm.loadingId === row.external_id
                              ? _c("i", { staticClass: "icon loader" })
                              : _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    disabled:
                                      !!_vm.loadingId || !_vm.isModifiable(row),
                                  },
                                  domProps: {
                                    checked: _vm.selected.includes(row.id),
                                  },
                                  on: {
                                    change: ($event) =>
                                      _vm.checkboxChanged($event, row),
                                  },
                                }),
                            _c(
                              "div",
                              {
                                staticClass: "name-wrapper",
                                class: {
                                  disabled:
                                    row.account_status !== 1 &&
                                    !_vm.isUnsettled(row),
                                },
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(" " + _vm._s(row.name) + " "),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "table-data",
                          {
                            staticClass: "status-cell",
                            attrs: { col: "is_active" },
                          },
                          [
                            _c("span", { class: _vm.statusClasses(row) }, [
                              _vm._v(" " + _vm._s(_vm.statusLabel(row)) + " "),
                            ]),
                          ]
                        ),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              {
                                staticClass: "external-id-cell",
                                attrs: { col: "external_id" },
                              },
                              [_vm._v(" " + _vm._s(row.external_id) + " ")]
                            )
                          : _vm._e(),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              { attrs: { col: "connected_by" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selected.includes(row.id) && row.owner
                                        ? row.owner.fullname
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.minimal && _vm.$app.isAd && _vm.$root.isSupportMode
                          ? _c("table-data", { attrs: { col: "actions" } }, [
                              _vm.isConnected(row)
                                ? _c("div", { staticClass: "action-group" }, [
                                    _vm.$root.supportHasL2Access
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "bordered small",
                                            on: {
                                              click: () =>
                                                _vm.openCommunicatorDebug(row),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.tr("Debug")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
      _c("connect-unsettled-ad-account-modal", {
        ref: "connectUnsettledAdAccountModal",
        attrs: { "ad-account": _vm.accountInModal },
        on: {
          closed: () => (_vm.accountInModal = null),
          settled: (adAccount) => {
            _vm.accountInModal.status = adAccount.status
            _vm.checkboxChanged(null, adAccount)
          },
        },
      }),
      _c("connect-ad-account-without-payment-method-modal", {
        ref: "connectAdAccountWithoutPaymentMethodModal",
        attrs: { "ad-account": _vm.accountInModal },
        on: {
          closed: () => (_vm.accountInModal = null),
          setted: (adAccount) => {
            _vm.accountInModal.has_payment_method = adAccount.has_payment_method
            _vm.checkboxChanged(null, adAccount)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }