import api from '@/services/Api';

export default {
  getStripeCurrentCard(companyId) {
    return api().get('/company/' + companyId + '/payment/stripe/current-card');
  },

  /**
   * @deprecated
   */
  updateStripeCurrentCard(companyId, token) {
    return api().put('/company/' + companyId + '/payment/stripe/current-card', { token });
  },

  deleteStripeCurrentCard(companyId) {
    return api().delete('/company/' + companyId + '/payment/stripe/current-card');
  },

  createCardConfirmSetupIntent(companyId) {
    return api().post('/company/' + companyId + '/payment/stripe/card-confirm');
  },

  setPaymentMethodAsDefault(companyId, paymentMethod) {
    const data = { payment_method: paymentMethod };
    return api().post('/company/' + companyId + '/payment/stripe/set-as-default', data);
  },
};
