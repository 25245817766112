import AuthService from '@/services/Auth';

/* global FB */

export default {
  computed: {
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    connectProfileButtonClasses() {
      return {
        button: true,
        primary: true,
        'connect-new-profile': true,
        loading: this.isConnecting,
      };
    },
  },
  created() {
    this.$store.dispatch('app/loadFbSdk');
  },
  methods: {
    onFacebookProfileConnected() {
      // Template method.
    },
    async reconnect(profile) {
      // TODO: alert modal.

      try {
        await AuthService.revokeProfilePermissions(profile.id);
      }
      catch (error) {
        this.error(error);
        throw error;
      }

      return this.connect(false, profile)
        .catch(err => {
          this.$store.dispatch('auth/removeProfile', profile.id);
          this.error(err); // TODO: improved error message: leálltak a szabályaid.
        });
    },
    connect(navigateToProfilesPage, profile, skipSuccessMessage) {
      this.$store.commit('app/isConnecting', true);

      return this._login(profile, skipSuccessMessage)
        .then(resp => {
          if (navigateToProfilesPage && this.$route.path !== '/integrations/meta/profiles') {
            this.$router.push('/integrations/meta/profiles');
          }

          return resp;
        })
        .finally(() => this.$store.commit('app/isConnecting', false));
    },
    _getCurrentUserToken() {
      return new Promise(resolve => {
        FB.login(response => {
          if (response.authResponse && response.authResponse.accessToken) {
            resolve(response.authResponse.accessToken);
          }

          resolve(null);
        });
      });
    },
    _revokePermissions(token) {
      return new Promise(resolve => {
        FB.api('me/permissions?access_token=' + token, 'delete', () => {
          resolve();
        });
      });
    },
    _login(profile, skipSuccessMessage) {
      let scopes = '';
      if (
        typeof this.$store.getters['app/facebook'] !== 'undefined'
        && typeof this.$store.getters['app/facebook'].scopes !== 'undefined'
        && Array.isArray(this.$store.getters['app/facebook'].scopes)
      ) {
        scopes = this.$store.getters['app/facebook'].scopes.join(',');
      }

      return new Promise((resolve, reject) => {
        FB.login(
          response => {
            if (!response.authResponse) {
              reject(new Error(this.tr('User cancelled login or did not provide full authorization for the app.')));
              return;
            }

            if (profile && profile.external_id !== response.authResponse.userID) {
              reject(new Error(this.tr('You are not currently logged in to Facebook with this user, so reconnecting is not possible.')));
              return;
            }

            const { accessToken } = response.authResponse;
            this.$store
              .dispatch('auth/connectProfile', {
                platform: 'facebook',
                accessToken,
              })
              .then(resp => {
                this.onFacebookProfileConnected();

                resolve();

                if (!skipSuccessMessage) {
                  if (profile) {
                    this.$toast.success(this.tr('Profile reconnected successfully.'));
                  }
                  else {
                    this.$toast.success(this.tr('Profile connected successfully.'));
                  }
                }

                if (window.fbq) {
                  window.fbq('trackCustom', 'ProfileConnected');
                }
                if (window.ttq) {
                  window.ttq.track('ProfileConnected');
                }
                if (window.gtag) {
                  if (this.$app.isBuzz) {
                    window.gtag('event', 'conversion', { 'send_to': 'AW-11432616777/zPXvCNCC4vwYEMnGv8sq' });
                  }
                }

                return resp;
              })
              .catch(err => reject(err));
          },
          {
            scope: scopes,
          },
        );
      });
    },
  },
};
