import BaseEntity from '@/entities/BaseEntity';
import Rule from '@/entities/Rule';
import SourceProfile from '@/entities/SourceProfile';

export default class ErrorLog extends BaseEntity {
  id = null;
  type = '';
  text = '';
  details = null;
  rule = null;
  source_profile = null;
  created_at = new Date();

  _entityFields = {
    rule: Rule,
    source_profile: SourceProfile,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
