import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';
import api from '@/services/Api';

export default {
  getList({ ruleId, status, handledBy, page, limit }) {
    const data = {};
    if (typeof status !== 'undefined') {
      data['status'] = status;
    }
    if (handledBy) {
      data['handled_by'] = handledBy;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + ruleId + '/activity-management/list?' + query)
      .then(resp => resp.map(i => new RuleActivityManagementQueueItem(i)));
  },

  instruct(ruleId, id, instruction) {
    return api().put('/rule/' + ruleId + '/activity-management/' + id + '/' + instruction)
      .then(resp => new RuleActivityManagementQueueItem(resp));
  },
};
