<template>
  <div class="source-profile-moderation-settings-component">
    <form-block
      :title="tr('Moderation settings of :name :platformType', { ':name': entity.name, ':platformType': platformType })"
      :description="tr('The comments that reach the set risk rating after the AI evaluation are automatically subject to moderation.')"
    >
      <div>
        <label class="input-label">{{ tr('Sensitivity') }}</label>

        <div class="sensitivity-select">
          <div class="sensitivity-value" :style="sensitivityValueStyle">
            {{ model.comment_moderation_sensitivity }}
          </div>
          <input
            v-model="model.comment_moderation_sensitivity"
            type="range"
            min="1"
            max="5"
            step="1"
            :disabled="!canModifySenstivity"
          />
        </div>
      </div>

      <info-text v-if="!canModifySenstivity">
        {{ tr('To set the sensitivity of moderation, switch to a subscription plan that includes this feature.') }}
      </info-text>

      <div>
        <label class="input-label">{{ tr('Sensitivity levels') }}</label>

        <div class="levels">
          <div :class="'level ' + (model.comment_moderation_sensitivity == 1 ? 'active' : '')">
            <div class="emoji">
              😠
            </div>
            <div class="number">
              1)
            </div>

            {{ tr('Low risk') }}:
            {{ tr('Expresses dissatisfaction in a polite manner, showing some resentment in opinion.') }}
          </div>
          <div :class="'level ' + (model.comment_moderation_sensitivity == 2 ? 'active' : '')">
            <div class="emoji">
              😤
            </div>
            <div class="number">
              2)
            </div>

            {{ tr('Medium risk') }}:
            {{ tr('Expresses dissatisfaction in a slightly impolite manner, showing some resentment in opinion.') }}
          </div>
          <div :class="'level ' + (model.comment_moderation_sensitivity == 3 ? 'active' : '')">
            <div class="emoji">
              😡
            </div>
            <div class="number">
              3)
            </div>

            {{ tr('High risk') }}:
            {{ tr('Accuses of lying or uses improper or vulgar language, indicating some use of offensive language.') }}
          </div>
          <div :class="'level ' + (model.comment_moderation_sensitivity == 4 ? 'active' : '')">
            <div class="emoji">
              🤬
            </div>
            <div class="number">
              4)
            </div>

            {{ tr('Very high risk') }}:
            {{ tr('Accuses of lying or uses improper or vulgar language, indicating a significant use of offensive language.') }}
          </div>
          <div :class="'level ' + (model.comment_moderation_sensitivity == 5 ? 'active' : '')">
            <div class="emoji">
              ⚠️
            </div>
            <div class="number">
              5)
            </div>

            {{ tr('Dangerous') }}:
            {{ tr('Contains words indicating abuse, or uses vulgar language, involves online fraud, or false information. It can be highly offensive, especially related to race or sexual orientation.') }}
          </div>
        </div>
      </div>
    </form-block>

    <div class="modal-action-group">
      <button class="bordered" :disabled="isLoading" @click="() => $emit('close')">
        {{ tr('Cancel') }}
      </button>
      <button class="primary" :disabled="isLoading" @click="() => save()">
        <i v-if="isLoading" class="icon loader white" />
        {{ tr('Save') }}
      </button>
    </div>
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import InfoText from '@/components/InfoText.vue';
import SourceProfile from '@/entities/SourceProfile';
import SourceProfileService from '@/services/SourceProfile';

export default {
  name: 'SourceProfileModerationSettings',
  components: {
    FormBlock,
    InfoText,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    model: null,
  }),
  computed: {
    platformType() {
      switch (this.entity.platform) {
        case 'facebook':
          return this.tr('Facebook Page');

        case 'instagram':
          return this.tr('Instagram Account');
      }

      return '';
    },
    sensitivityValueStyle() {
      const leftPercentage = ((this.model.comment_moderation_sensitivity - 1) / 4 * 97);
      return {
        'left': 'calc(' + leftPercentage + '% - 3px)',
      };
    },
    canModifySenstivity() {
      const currentActivePackage = this.$root.currentCompany.current_active_package;
      return currentActivePackage && currentActivePackage.hasFeature('adjustable_comment_moderation_sensitivity');
    },
  },
  created() {
    this.model = new SourceProfile(this.entity);
    this.model.owner = null;
  },
  methods: {
    save() {
      this.isLoading = true;

      SourceProfileService.update(this.model)
        .then(resp => {
          this.entity.updateWith(resp);

          this.$toast.success(this.tr('Settings have been successfully saved.'));
          this.$emit('close');

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
