var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    _vm._l(_vm.cards, function (card, index) {
      return _c(
        "create-new-rule-card",
        _vm._b(
          {
            key: "create-new-rule-card--" + index,
            ref: "cards",
            refInFor: true,
          },
          "create-new-rule-card",
          card,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }