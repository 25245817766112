var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-form-view" },
    [
      _c("transition", { attrs: { name: "fade--fast" } }, [
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [
              _c("i", { staticClass: "icon loader black" }),
            ])
          : _vm._e(),
      ]),
      !_vm.isLoading
        ? [
            _c(
              "sticky-header",
              {
                attrs: {
                  title: _vm.stickyHeaderTitle,
                  bordered: _vm.maxStepNumber <= 1,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "router-link",
                            {
                              staticClass: "button bordered",
                              attrs: { to: _vm.cancelButtonLink },
                            },
                            [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "primary",
                              attrs: {
                                loading: _vm.isSaving,
                                disabled:
                                  _vm.$root.isSupportMode && _vm.isLastStep,
                              },
                              on: { click: () => _vm.nextButtonAction() },
                            },
                            [
                              _vm.isSaving
                                ? _c("i", { staticClass: "icon loader white" })
                                : [
                                    _vm._v(
                                      " " + _vm._s(_vm.stepButtonLabel) + " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  125530577
                ),
              },
              [
                _vm.maxStepNumber > 1
                  ? _c("form-step-tab", {
                      attrs: {
                        items: _vm.formStepTabs,
                        "allowed-index": _vm.allowedStep - 1,
                      },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(_vm.renderedView, { ref: "formStep", tag: "component" }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }