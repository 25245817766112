var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rule-switch-modal-content-component" }, [
    _c("div", { staticClass: "modal-title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.tr("Would you like to turn off the campaign too?")) +
          " "
      ),
    ]),
    _c("div", { staticClass: "modal-content" }, [
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.tr(
              "The campaign is currently active, in which the desired automation is being worked to be turned off."
            )
          )
        ),
      ]),
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.tr(
              "You can choose to disable only the automation: in this case, no new advertisements will be created, but the existing ones will continue to run."
            )
          )
        ),
      ]),
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.tr(
              "You can also disable the associated campaign along with the automation: in this case, the existing ads will also be paused."
            )
          )
        ),
      ]),
    ]),
    _c("div", { staticClass: "modal-action-group" }, [
      _c(
        "button",
        {
          staticClass: "bordered",
          attrs: { disabled: _vm.isLoading },
          on: { click: () => _vm.switchOnlyRule() },
        },
        [
          _vm.loadingRule
            ? _c("i", { staticClass: "icon loader" })
            : [
                _vm._v(
                  " " + _vm._s(_vm.tr("Only switching the Automation")) + " "
                ),
              ],
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "primary",
          attrs: { disabled: _vm.isLoading },
          on: { click: () => _vm.switchRuleWithCampaign() },
        },
        [
          _vm.loadingRuleWithCampaign
            ? _c("i", { staticClass: "icon loader white" })
            : [
                _vm._v(
                  " " + _vm._s(_vm.tr("Turn off Automation and Campaign")) + " "
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }