<template>
  <div class="delete-rule-confirm-modal-content-component">
    <div class="top">
      <img
        class="image delete-confirm-image"
        src="@/assets/img/delete-confirm.png"
      />

      <div class="title">
        {{ tr('Are you sure you want to delete this automation?') }}
      </div>
    </div>

    <div v-if="showCampaignDeletionOptions" class="campaign-options">
      <label v-if="!showDeletedCampaignMessage" class="checkbox-wrapper">
        <input
          v-model="deleteCampaign"
          type="checkbox"
          class="delete-campaign-checkbox"
        />
        <span class="checkbox-label">
          {{ tr('Also delete associated campaign') }}
        </span>
      </label>

      <div v-else class="info-text-wrapper">
        <info-text class="info">
          {{ tr('The associated campaign has already been deleted.') }}
        </info-text>
      </div>

      <transition-expand>
        <div v-if="deleteCampaign" class="info-text-wrapper">
          <info-text class="warning">
            {{ tr('Warning: This action will permanently delete the campaign and all associated data. This action cannot be undone.') }}
          </info-text>
        </div>
      </transition-expand>
    </div>

    <div class="buttons">
      <button class="cancel-button clean" :disabled="isLoading" @click="() => close()">
        {{ tr('Cancel') }}
      </button>

      <button class="confirm-button red" :disabled="isLoading" @click="() => actionConfirm()">
        <i v-if="isLoading" class="icon loader white" />
        <template v-else>
          {{ tr('Confirm') }}
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import { CAMPAIGN_STATUS } from '@/consts';
import Rule from '@/entities/Rule.js';
import RuleService from '@/services/Rule.js';

export default {
  components: {
    InfoText,
    TransitionExpand,
  },

  props: {
    rule: {
      type: Rule,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    deleteCampaign: false,
  }),

  computed: {
    showCampaignDeletionOptions() {
      return this.rule.has_output_campaign;
    },
    showDeletedCampaignMessage() {
      return (
        this.rule.has_output_campaign &&
        this.rule.campaign_status === CAMPAIGN_STATUS.DELETED
      );
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    actionConfirm() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const { id } = this.rule;
      RuleService.delete(id, this.deleteCampaign)
        .then(resp => {
          this.$store.commit('rules/ruleRemoved', id);
          this.$toast.clear();
          this.$toast.success(this.tr('The automation was successfully deleted.'));
          this.$router.replace('/automation');

          this.close();

          return resp;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error(this.tr('An error occurred while deleting the automation.'));
        })
        .finally(() => {
          this.progress = false;
          this.$emit('close');
        });
    },
  },
};
</script>
