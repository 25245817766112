<template>
  <form-block
    class="rule-form-block geotarget-block-component"
    :title="title !== null ? title : tr('Geotargeting')"
    :description="description"
  >
    <div v-if="targetAudienceBlockIsLoading" class="inline-loader">
      <i class="icon loader" />
      {{ tr('Loading...') }}
    </div>

    <info-text v-else-if="hasGeotargetingInSelectedAudiences">
      {{ tr('You have chosen a target audience where geographic targeting is specified.') }}
    </info-text>

    <geolocation-target-selector
      v-else
      ref="geolocationTargetSelector"
      v-model="entity.settings.adset_targeting"
      :disabled="disabled"
    />

    <div>
      <label class="input-label">
        {{ tr('Spoken languages') }}
        <small>({{ tr('optional') }})</small>
      </label>
      <v-select
        v-model="entity.settings.adset_targeting.locales"
        :options="languages"
        :reduce="(obj) => obj.id"
        label="name"
        multiple
      />
    </div>
  </form-block>
</template>

<script>
import GeolocationTargetSelector from '@/components/GeolocationTargetSelector.vue';
import InfoText from '@/components/InfoText.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'GeotargetBlock',
  components: {
    GeolocationTargetSelector,
    InfoText,
  },
  mixins: [ RuleFormBlockMixin ],
  props: {
    title: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    targetAudienceBlock: {
      type: [ Object, null ],
      required: false,
      default: () => null,
    },
    targetAudienceBlockIsLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    hasGeotargetingInSelectedAudiences() {
      if (!this.targetAudienceBlock) {
        return false;
      }

      return this.targetAudienceBlock.hasGeotargeting;
    },
    description() {
      if (this.hasGeotargetingInSelectedAudiences) {
        return null;
      }

      return this.tr('You have to specify where the ad should be displayed, or you have to choose a target audience where geographic targeting is set.');
    },
    languages() {
      const list = JSON.parse(JSON.stringify(this.$store.getters['app/facebookLanguages']));
      list.forEach(item => item.name = this.tr(item.name));
      list.sort((a, b) => a.name.localeCompare(b.name));

      return list;
    },
  },
  methods: {
    validate() {
      if (this.hasGeotargetingInSelectedAudiences) {
        return true;
      }

      if (
        this.entity.settings.adset_included_custom_audiences.length === 0
        && this.entity.settings.adset_targeting.geo_locations.all.length === 0
      ) {
        this.error(this.tr('Please add a location for your target audience or use a Custom Audience.'));
        return false;
      }

      if (this.$refs['geolocationTargetSelector'] && !this.$refs['geolocationTargetSelector'].validate()) {
        return false;
      }

      return true;
    },
  },
};
</script>
