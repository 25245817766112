import Rule from '@/entities/Rule.js';
import RuleActivityLog from '@/entities/RuleActivityLog.js';
import RuleChangeLog from '@/entities/RuleChangeLog.js';
import api from '@/services/Api';

export default {
  getList({ page, limit, term, status, action, adAccountId, orderBy, order }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (term) {
      data['term'] = term;
    }
    if (typeof status !== 'undefined') {
      data['status'] = status ? 1 : 0;
    }
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (action) {
      data['action'] = action;
    }
    if (orderBy) {
      data['order_by'] = orderBy;
    }
    if (order) {
      data['order'] = order.toUpperCase();
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rules/v2?' + query)
      .then(resp => resp.map(i => new Rule(i)));
  },

  get(id) {
    return api().get('/rule/' + id)
      .then(resp => new Rule(resp));
  },

  create(entity) {
    return api().post('/rule/v2', entity.toObject())
      .then(resp => new Rule(resp));
  },

  createAiMode(entity, goal, scalingBudget, aiAssist) {
    const data = entity.toObject();
    data['goal'] = goal;
    if (scalingBudget) {
      data['scaling_budget'] = scalingBudget;
    }
    if (aiAssist) {
      data['ai_assist'] = aiAssist;
    }

    return api().post('/rule/v2/ai-mode', data);
  },

  update(entity) {
    return api().put('/rule/v2/' + entity.id, entity.toObject())
      .then(resp => new Rule(resp));
  },

  delete(id, deleteCampaign) {
    return api().delete('/rule/v2/' + id, { data: { delete_output_campaign: deleteCampaign } });
  },

  changeStatus(id, active, changeCampaignStatus) {
    const data = { active };
    if (changeCampaignStatus) {
      data['change_campaign_status'] = true;
    }

    return api().put('/rule/v2/' + id + '/status', data)
      .then(resp => new Rule(resp));
  },

  run(id) {
    return api().post('/rule/' + id + '/run');
  },

  getLogs({ id, page, limit }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + id + '/logs?' + query)
      .then(resp => resp.map(i => new RuleActivityLog(i)));
  },

  getChangeLogs({ id, page, limit }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + id + '/change-logs?' + query)
      .then(resp => resp.map(i => new RuleChangeLog(i)));
  },

  getRuleCampaignPairs() {
    return api().get('/rule-campaign-pairs');
  },
};
