<template>
  <div :class="wrapperClasses">
    <div class="navigation">
      <div v-if="title" class="title">
        <slot name="beforeTitle" />
        {{ title }}

        <info-popup v-if="info">
          <div v-html="info" />
        </info-popup>
      </div>
      <div class="nav-buttons">
        <slot name="buttons" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import InfoPopup from '@/components/InfoPopup.vue';

export default {
  name: 'StickyHeader',
  components: {
    InfoPopup,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    info: {
      type: String,
      required: false,
      default: () => '',
    },
    bordered: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'sticky-header-component': true,
        'bordered': this.bordered,
      };
    },
  },
};
</script>
