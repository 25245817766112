var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-logs-component" },
    [
      _vm.computedIsLoading && _vm.isListEmpty
        ? _c("div", { staticClass: "loader" }, [
            _c("i", { staticClass: "icon loader black" }),
          ])
        : _vm.isListEmpty
        ? _c(
            "notification-card",
            {
              attrs: {
                image: require("@/assets/img/thumbsup.png"),
                title: _vm.tr("Super!"),
                "empty-screen": "",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.tr("No error messages have been generated yet.")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.isListEmpty
        ? _c("sorted-table", {
            attrs: { data: _vm.list },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "table-head",
                        { attrs: { col: "created_at", width: "120px" } },
                        [_vm._v(_vm._s(_vm.tr("Date created")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "type", width: "130px" } },
                        [_vm._v(_vm._s(_vm.tr("Type")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "error_details", minWidth: "300px" } },
                        [_vm._v(_vm._s(_vm.tr("Error details")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "data_source", width: "220px" } },
                        [_vm._v(_vm._s(_vm.tr("Datasource")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "rule", width: "250px" } },
                        [_vm._v(_vm._s(_vm.tr("Rule")))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ sort }) {
                    return _vm._l(sort, function (row) {
                      return _c(
                        "table-row",
                        { key: row.id },
                        [
                          _c("table-data", { attrs: { col: "created_at" } }, [
                            _vm._v(_vm._s(_vm.formatToYMD(row.created_at))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.formatTime(row.created_at))),
                          ]),
                          _c("table-data", { attrs: { col: "type" } }, [
                            _vm._v(_vm._s(_vm.getTypeLabel(row.type))),
                          ]),
                          _c(
                            "table-data",
                            { attrs: { col: "error_details" } },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    row.text.replace(/([^>])\n/g, "$1<br />\n")
                                  ),
                                },
                              }),
                            ]
                          ),
                          _c("table-data", {
                            staticClass: "source-profile-col",
                            attrs: { col: "data_source" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sourceProfileColContent(row.source_profile)
                              ),
                            },
                          }),
                          _c(
                            "table-data",
                            { attrs: { col: "rule" } },
                            [
                              _c(
                                row.rule ? "router-link" : "span",
                                {
                                  tag: "component",
                                  attrs: { to: _vm.ruleUrl(row.rule) },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.rule ? row.rule.name : "-") +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    })
                  },
                },
              ],
              null,
              false,
              2661496990
            ),
          })
        : _vm._e(),
      !_vm.isListEmpty && _vm.hasMore
        ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }