import ConfirmationModal from './components/Modal';
import api from './js/api.js';

const plugin = (Vue, options = {}) => {
  const methods = api(Vue, options);
  Vue.$confirmation = methods;
  Vue.prototype.$confirmation = methods;
};

ConfirmationModal.install = plugin;

export { ConfirmationModal, ConfirmationModal as default };
