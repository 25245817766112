import BaseEntity from '@/entities/BaseEntity';
import Post from '@/entities/Post';
import User from '@/entities/User';

export default class PostAiProhibitedContent extends BaseEntity {
  id = null;
  post = null;
  is_prohibited = null;
  reason = null;
  is_ignored = null;
  is_current = true;
  handled_by = null;
  handled_at = null;
  created_at = new Date();

  _entityFields = {
    post: Post,
    handled_by: User,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);

    if (this.handled_at) {
      this.handled_at = new Date(this.handled_at);
    }
  }

  get isHandled() {
    return this.handled_at !== null;
  }
}
