import api from '@/services/Api';

export default {
  hide(entity) {
    return api().put('/comment/' + entity.id + '/hide');
  },

  unhide(entity) {
    return api().put('/comment/' + entity.id + '/unhide');
  },
};
