<template>
  <form-block :title="tr('Ad creation method')">
    <div v-if="entity.output_campaign.isObjectiveEngagement">
      <info-text type="info">
        {{ tr('For engagement campaigns, only post boosting is available as the ad creation method since the goal is to increase engagement on existing content') }}
      </info-text>
    </div>

    <div class="checkbox-list vertical">
      <label v-for="(method, key) in methods" :key="'ad-creation-method--' + key">
        <input
          v-model="entity.settings.ad_creation_method"
          type="radio"
          name="ad-creation-method"
          :value="method.value"
          :disabled="disabled || entity.output_campaign.isObjectiveEngagement"
        />
        {{ method.label }}
      </label>
    </div>

    <template v-if="entity.settings.ad_creation_method === 'creative'">
      <div>
        <label class="input-label">{{ tr('AI Improvements') }}</label>
        <div class="description">
          {{ tr('You can easily entrust Infinite∞Ai to automatically optimize certain elements of your ads, making your campaigns more effective.') }}
          {{ tr('AI creates and configures the various elements based on the characteristics of your target audience and the context of your advertisement to engage your audience as effectively as possible.') }}
        </div>
        <div class="checkbox-list vertical">
          <label
            v-for="(item, key) in aiImprovementFields"
            :key="'scaling-up-settins--ai--' + key"
            v-tooltip.right="tr(item.description)"
          >
            <input
              v-model="aiImprovements"
              type="checkbox"
              :value="item.machine_name"
              :disabled="disabled"
            />

            {{ item.label }}
          </label>
        </div>
      </div>

      <div>
        <label class="input-label">{{ tr('Ad Language') }}</label>
        <v-select
          v-model="entity.settings.language"
          :options="languages()"
          :disabled="disabled"
          :searchable="true"
          :reduce="(obj) => obj.originalName"
          label="name"
          class="language-selector"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Number of Ad Variations') }}
        </label>
        <div class="description">
          {{ tr('You can specify how many different variations should be created from the ad that will be scaled up.') }}
        </div>
        <number-input
          v-model="entity.settings.ad_variants"
          :step="1"
          :min="1"
          :max="5"
          :disabled="disabled"
          class="ad-variations-number"
        />
      </div>

      <div v-if="entity.settings.ad_variants > 1">
        <label>
          <input
            v-model="entity.settings.ai_ad_with_original_content"
            type="checkbox"
            :disabled="disabled"
          />

          {{ tr('One of the created variations should be made without Infinite∞Ai') }}
        </label>

        <info-text v-if="!adVariantsValueIsValid">
          {{ tr('If you want to create multiple ad variations, you need to specify at least one ad element that the Infinite∞Ai can determine.') }}
        </info-text>
      </div>

      <button class="button primary ap-creative-settings" @click="() => openApCreativeSettings()">
        <i class="icon development white" />
        {{ tr('Meta Advantage+ Creative Settings') }}
      </button>
    </template>
  </form-block>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import NumberInput from '@/components/NumberInput.vue';
import MetaApCreativeFeaturesModal from '@/components/RuleForm/MetaApCreativeFeaturesModal.vue';
import { AD_CREATION_METHOD, ORIGINAL_POST_LANGUAGE, RULE_ACTION, TARGETED_COUNTRY_LANGUAGE } from '@/consts.js';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'AdCreationMethodBlock',
  components: {
    InfoText,
    NumberInput,
  },
  mixins: [ RuleFormBlockMixin ],
  data: () => ({
    aiImprovements: [],
  }),
  computed: {
    methods() {
      switch (this.entity.action) {
        case RULE_ACTION.SCALING_UP:
          return [
            { label: this.tr('Copy original ad'), value: AD_CREATION_METHOD.COPY },
            { label: this.tr('Create new ad creative(s)'), value: AD_CREATION_METHOD.CREATIVE },
          ];

        case RULE_ACTION.START:
          return [
            { label: this.tr('Boosting original posts'), value: AD_CREATION_METHOD.POST_BOOST },
            { label: this.tr('Create new ad creative(s)'), value: AD_CREATION_METHOD.CREATIVE },
          ];
      }

      return [];
    },
    aiImprovementFields() {
      return [
        {
          label: this.tr('Primary text'),
          description: this.tr('Infinite∞Ai writes the main content to ensure your message reaches its target effectively and with impact.'),
          machine_name: 'ai_primary_text',
        },
        {
          label: this.tr('CTA Title and Description'),
          description: this.tr('Hagyd, hogy az Infinite∞Ai javasoljon egy ütős címet és leírást a hirdetésedhez, hogy még jobban megragadhassa a figyelmet.'),
          machine_name: 'ai_cta_content',
        },
        {
          label: this.tr('CTA Button type'),
          description: this.tr('Let AI choose the best button type (such as "Learn More" or "Buy Now") to support the effectiveness of your ad.'),
          machine_name: 'ai_cta_button',
        },
      ];
    },
    ctaList() {
      let list = this.$store.getters['app/facebookCallToActions'];
      if (this.entity.isAppPromo) {
        list = list.filter(i => i.machine_name !== 'NO_BUTTON');
      }

      return list;
    },
    adVariantsValueIsValid() {
      return !(this.entity.settings.ad_variants > 1 && this.aiImprovements.length === 0);
    },
  },
  watch: {
    'entity.output_campaign.isObjectiveEngagement': {
      immediate: true,
      handler(val) {
        if (val) {
          this.entity.settings.ad_creation_method = AD_CREATION_METHOD.POST_BOOST;
        }
      },
    },
    'entity.settings.ad_creation_method'(val) {
      if (val === 'creative') {
        if (!this.entity.settings.cta_button) {
          this.entity.settings.cta_button = this.ctaList[0].machine_name;
        }

        Object.keys(this.entity.settings.ap_creative_features).map(key => this.entity.settings.ap_creative_features[key] = true);
      }
      else if ('custom_cta_settings' in this.entity.settings) {
        this.entity.settings.custom_cta_settings = false;
        this.entity.settings.cta_button = null;
        this.entity.settings.cta_url = null;
        this.entity.settings.smart_url = true;

        Object.keys(this.entity.settings.ap_creative_features).map(key => this.entity.settings.ap_creative_features[key] = false);
      }
    },
    aiImprovements(newVal, oldVal) {
      const difference = newVal
        .filter(x => !oldVal.includes(x))
        .concat(oldVal.filter(x => !newVal.includes(x)));

      difference.forEach(field => this.entity.settings[field] = !this.entity.settings[field]);
    },
  },
  created() {
    this.aiImprovementFields.forEach(item => {
      if (this.entity.settings[item.machine_name]) {
        this.aiImprovements.push(item.machine_name);
      }
    });
  },
  methods: {
    validate() {
      if (!this.adVariantsValueIsValid) {
        this.error(this.tr('If you want to create multiple ad variations, you need to specify at least one ad element that the Infinite∞Ai can determine.'));
        return false;
      }

      return true;
    },
    openApCreativeSettings() {
      this.$modal.show(
        MetaApCreativeFeaturesModal,
        {
          value: this.entity.settings.ap_creative_features,
          disabled: this.disabled,
        },
        { class: '' },
      );
    },
    languages() {
      const list = JSON.parse(JSON.stringify(this.$store.getters['app/facebookLanguages']));

      list.forEach(item => {
        item.originalName = item.name;
        item.name = this.tr(item.name);
      });

      list.sort((a, b) => a.name.localeCompare(b.name));

      list.unshift(
        {
          id: ORIGINAL_POST_LANGUAGE,
          name: this.tr('Original post language'),
          originalName: ORIGINAL_POST_LANGUAGE,
        },
        {
          id: TARGETED_COUNTRY_LANGUAGE,
          name: this.tr('Targeted country language'),
          originalName: TARGETED_COUNTRY_LANGUAGE,
        },
      );

      if (this.entity.settings.language === null) {
        this.entity.settings.language = list[0].originalName;
      }

      return list;
    },
  },
};
</script>
