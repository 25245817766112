export const PLATFORMS = [
  {
    label: 'Facebook',
    machineName: 'facebook',
  },
  {
    label: 'Instagram',
    machineName: 'instagram',
  },
];

export const RULE_ACTION = {
  START: 'start',
  STOP: 'stop',
  SCALING_UP: 'scaling_up',
};

export const AI_STATUS = {
  WAITING: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3,
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
};

export const AD_CREATION_METHOD = {
  POST_BOOST: 'post_boost',
  COPY: 'copy',
  CREATIVE: 'creative',
};

export const ORIGINAL_POST_LANGUAGE = 'original_post_language';
export const TARGETED_COUNTRY_LANGUAGE = 'targeted_country_language';

export const AI_ASSIST_OPTIONS = {
  CREATE_CREATIVES: 'create_creatives',
};
