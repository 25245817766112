/* eslint-disable filenames/match-regex */

import { StripePlugin } from '@vue-stripe/vue-stripe';
import Vue from 'vue';

import AppService from '@/services/App';

const availableLanguages = {
  'en': 'English',
  'hu': 'Magyar',
};

let language = localStorage.getItem('language');
if (!language) {
  language = (navigator.language || navigator.userLanguage).split('-')[0];
}
if (!Object.keys(availableLanguages).includes(language)) {
  language = 'en';
}
window.localStorage.setItem('language', language);

const state = {
  isConnecting: false,
  isLoading: true,
  fbSdkLoaded: false,
  igSdkLoaded: false,
  stripeLoaded: false,
  sidebarClosed: false,
  availableLanguages,
  language,
  fields: [],
  fieldComparisons: [],
  countries: [],
  currencies: [],
  devices: [],
  mobileDevices: [],
  budgetPeriods: [],
  campaignNameMacros: [],
  adsetNameMacros: [],
  urlParamMacros: [],
  facebook: {},
  facebookOdaxMap: [],
  facebookAdAccountStatuses: [],
  facebookAdFormats: [],
  facebookLanguages: [],
  facebookCampaignObjectives: [],
  facebookCampaignBidStrategies: [],
  facebookAdsetBidStrategies: [],
  facebookSpecialAdCategories: [],
  facebookPostTypes: [],
  facebookPostMediaTypes: [],
  facebookAdsetOptimizationGoals: [],
  facebookAdsetBillingEvents: [],
  facebookAttributionWindows: [],
  facebookApCreativeFeatures: [],
  instagramPostTypes: [],
  instagramPostMediaTypes: [],
  facebookCallToActions: [],
  facebookConversionEvents: [],
  timezones: [],
  conversionLocations: [],
  facebookAdPlacements: {},
  postMetrics: [],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isConnecting(state) {
    return state.isConnecting;
  },
  fbSdkLoaded(state) {
    return state.fbSdkLoaded;
  },
  stripeLoaded(state) {
    return state.stripeLoaded;
  },
  sidebarClosed(state) {
    return state.sidebarClosed;
  },
  availableLanguages(state) {
    return state.availableLanguages;
  },
  language(state) {
    return state.language;
  },
  fields(state) {
    return state.fields;
  },
  fieldComparisons(state) {
    return state.fieldComparisons;
  },
  campaignNameMacros(state) {
    return state.campaignNameMacros;
  },
  budgetPeriods(state) {
    return state.budgetPeriods;
  },
  countries(state) {
    return state.countries;
  },
  currencies(state) {
    return state.currencies;
  },
  facebook(state) {
    return state.facebook;
  },
  facebookOdaxMap(state) {
    return state.facebookOdaxMap;
  },
  facebookAdAccountStatuses(state) {
    return state.facebookAdAccountStatuses;
  },
  facebookAdFormats(state) {
    return state.facebookAdFormats;
  },
  facebookLanguages(state) {
    return state.facebookLanguages;
  },
  facebookCampaignObjectives(state) {
    return state.facebookCampaignObjectives;
  },
  facebookCampaignBidStrategies(state) {
    return state.facebookCampaignBidStrategies;
  },
  facebookAdsetBidStrategies(state) {
    return state.facebookAdsetBidStrategies;
  },
  facebookSpecialAdCategories(state) {
    return state.facebookSpecialAdCategories;
  },
  facebookPostTypes(state) {
    return state.facebookPostTypes;
  },
  facebookPostMediaTypes(state) {
    return state.facebookPostMediaTypes;
  },
  instagramPostTypes(state) {
    return state.instagramPostTypes;
  },
  instagramPostMediaTypes(state) {
    return state.instagramPostMediaTypes;
  },
  facebookConversionEvents(state) {
    return state.facebookConversionEvents;
  },
  facebookAdPlacements(state) {
    return state.facebookAdPlacements;
  },
  facebookCallToActions(state) {
    return state.facebookCallToActions;
  },
  facebookApCreativeFeatures(state) {
    return state.facebookApCreativeFeatures;
  },
  adsetNameMacros(state) {
    return state.adsetNameMacros;
  },
  urlParamMacros(state) {
    return state.urlParamMacros;
  },
  facebookAdsetOptimizationGoals(state) {
    return state.facebookAdsetOptimizationGoals;
  },
  facebookAdsetBillingEvents(state) {
    return state.facebookAdsetBillingEvents;
  },
  facebookAttributionWindows(state) {
    return state.facebookAttributionWindows;
  },
  devices(state) {
    return state.devices;
  },
  mobileDevices(state) {
    return state.mobileDevices;
  },
  conversionLocations() {
    return state.conversionLocations;
  },
  timezones(state) {
    return state.timezones;
  },
  postMetrics(state) {
    return state.postMetrics;
  },
};

const actions = {
  fetch(store) {
    return AppService.getConfig().then(result => {
      const propertyMapping = {
        fields: 'fields',
        field_comparisons: 'fieldComparisons',
        campaign_name_macros: 'campaignNameMacros',
        adset_name_macros: 'adsetNameMacros',
        url_param_macros: 'urlParamMacros',
        budget_periods: 'budgetPeriods',
        countries: 'countries',
        currencies: 'currencies',
        facebook: 'facebook',
        facebook_odax_map: 'facebookOdaxMap',
        facebook_ad_account_statuses: 'facebookAdAccountStatuses',
        facebook_ad_formats: 'facebookAdFormats',
        facebook_languages: 'facebookLanguages',
        facebook_campaign_objectives: 'facebookCampaignObjectives',
        facebook_campaign_bid_strategies: 'facebookCampaignBidStrategies',
        facebook_adset_bid_strategies: 'facebookAdsetBidStrategies',
        facebook_special_ad_categories: 'facebookSpecialAdCategories',
        facebook_adset_optimization_goals: 'facebookAdsetOptimizationGoals',
        facebook_adset_billing_events: 'facebookAdsetBillingEvents',
        facebook_attribution_windows: 'facebookAttributionWindows',
        facebook_ad_placements: 'facebookAdPlacements',
        facebook_call_to_actions: 'facebookCallToActions',
        facebook_conversion_events: 'facebookConversionEvents',
        facebook_post_types: 'facebookPostTypes',
        facebook_post_media_types: 'facebookPostMediaTypes',
        facebook_ap_creative_features: 'facebookApCreativeFeatures',
        instagram_post_types: 'instagramPostTypes',
        instagram_post_media_types: 'instagramPostMediaTypes',
        devices: 'devices',
        mobile_devices: 'mobileDevices',
        conversion_locations: 'conversionLocations',
        timezones: 'timezones',
        post_metrics: 'postMetrics',
      };
      Object.entries(result).forEach(([ property, items ]) => {
        if (property in propertyMapping) {
          store.commit('updateState', {
            key: propertyMapping[property],
            items,
          });
        }
      });

      store.commit('isLoading', false);

      return result;
    });
  },
  loadFbSdk(store) {
    return new Promise(resolve => {
      if (store.getters['fbSdkLoaded']) {
        resolve();
        return;
      }

      store.commit('setFbSdkLoaded', true);

      /* global FB */
      window.fbAsyncInit = function() {
        FB.init({
          appId: store.getters['facebook'].app_id,
          version: 'v' + store.getters['facebook'].graph_version,
          xfbml: true,
        });

        resolve();
      };

      const scriptEle = document.createElement('script');
      scriptEle.setAttribute(
        'src',
        'https://connect.facebook.net/en_US/sdk.js',
      );
      scriptEle.setAttribute('type', 'text/javascript');
      scriptEle.setAttribute('async', true);
      scriptEle.setAttribute('defer', true);
      document.body.appendChild(scriptEle);
    });
  },
  loadIgSdk(store) {
    return new Promise(resolve => {
      if (store.getters['igSdkLoaded']) {
        resolve();
        return;
      }

      store.commit('setIgSdkLoaded', true);

      const scriptEle = document.createElement('script');
      scriptEle.setAttribute(
        'src',
        'https://platform.instagram.com/en_US/embeds.js',
      );
      scriptEle.setAttribute('type', 'text/javascript');
      scriptEle.setAttribute('async', true);
      scriptEle.onload = () => {
        resolve();
      };

      document.body.appendChild(scriptEle);
    });
  },
  loadStripe(store) {
    return new Promise(resolve => {
      if (store.getters['stripeLoaded']) {
        resolve();
        return;
      }

      store.commit('setStripeLoaded', true);

      const scriptEl = document.createElement('script');
      scriptEl.setAttribute(
        'src',
        'https://js.stripe.com/v3',
      );
      scriptEl.setAttribute('type', 'text/javascript');
      scriptEl.setAttribute('async', true);
      scriptEl.onload = () => {
        const stripeOptions = {
          testMode: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'beta',
          pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
          locale: store.rootGetters['app/language'],
        };

        Vue.use(StripePlugin, stripeOptions);

        resolve();
      };

      document.body.appendChild(scriptEl);
    });
  },
};

const mutations = {
  isLoading(state, value) {
    state.isLoading = value;
  },
  isConnecting(state, value) {
    state.isConnecting = value;
  },
  setFbSdkLoaded(state, value) {
    state.fbSdkLoaded = value;
  },
  setIgSdkLoaded(state, value) {
    state.igSdkLoaded = value;
  },
  setStripeLoaded(state, value) {
    state.stripeLoaded = value;
  },
  setSidebarClosed(state, value) {
    state.sidebarClosed = value;
  },
  language(state, value) {
    state.language = value;
    window.localStorage.setItem('language', state.language);
  },
  updateState(state, { key, items }) {
    state[key] = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
