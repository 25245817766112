<template>
  <form-block
    v-if="facebookAttributionWindows.length !== 0"
    class="rule-form-block attribution-window-block-component"
    :title="tr('Attribution window')"
  >
    <div class="checkbox-list vertical">
      <label>
        <input
          v-model="isCustom"
          type="radio"
          name="attribution"
          :value="false"
          :disabled="disabled"
        />
        {{ tr('Default') }}
      </label>
      <label>
        <input
          v-model="isCustom"
          type="radio"
          name="attribution"
          :value="true"
          :disabled="disabled"
        />
        {{ tr('Custom') }}
      </label>

      <div v-if="isCustom" class="checkbox-list vertical">
        <div>
          <span>
            {{ tr('The number of days between when a person viewed or clicked your ad and subsequently took an action.') }}
            <a href="https://www.facebook.com/business/help/2198119873776795?id=768381033531365" target="_blank">{{ tr('Learn more') }}</a>
          </span>
        </div>
        <div class="checkbox-list vertical">
          <v-select
            v-model="entity.settings.adset_attribution_window"
            :options="facebookAttributionWindows"
            :reduce="(obj) => obj.machine_name"
            :disabled="disabled"
            label="label"
          />
          <div>
            <span>{{ tr('By default Ads Manager uses ad set attribution window, also known as unified attribution window. You may see discrepancies in conversion data if this setting is disabled.') }}</span>
          </div>
        </div>
      </div>
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'AttributionWindowBlock',
  mixins: [ RuleFormBlockMixin ],
  data: () => ({
    isCustom: null,
  }),
  computed: {
    facebookAttributionWindows() {
      const items = this.$store.getters['app/facebookAttributionWindows'];
      return this.filterSelectValuesByConditions(items);
    },
  },
  watch: {
    isCustomAttributionWindow(val, oldVal) {
      if (oldVal === null || val == oldVal) {
        return;
      }

      if (val) {
        this.entity.settings.adset_attribution_window = this.facebookAttributionWindows[0].machine_name;
      }
      else {
        this.entity.settings.adset_attribution_window = null;
      }
    },
  },
  created() {
    this.isCustom = !!this.entity.settings.adset_attribution_window;
  },
};
</script>
