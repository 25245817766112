var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "onboarding-component onboarding--ad",
      style: _vm.wrapperStyle,
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("i", {
            staticClass: "icon close",
            style: { "background-color": _vm.iconBackgroundColor },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          }),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 0,
                      expression: "step === 0",
                    },
                  ],
                  staticClass: "step step--0 step--welcome",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-welcome.png"),
                      },
                    }),
                  ]),
                  _c("div", {
                    staticClass: "title large",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "Welcome to the <span class='primary'>Infinite∞Ad</span> automatic ad management system!"
                        )
                      ),
                    },
                  }),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "We are glad that you are with us in the world of endless ads."
                          )
                        ) +
                          " " +
                          _vm._s(
                            _vm.tr(
                              "In the following, we will help you use the system in three simple steps."
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.tr(
                              "Don't worry, we will guide you step by step so that you can set up your first automations as easily as possible."
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Let start it!")))]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 1 && !_vm.skipFacebookProfile,
                      expression: "step === 1 && !skipFacebookProfile",
                    },
                  ],
                  staticClass: "step step--1 step--profile",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-profile.png"),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Connect Facebook Profile"))),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "Okay, first, let's connect your Facebook profile. This is how we will be able to access your assets."
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.tr(
                              "Don't worry, data protection is also of utmost importance to us here."
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              'Click the "Connect Profile" button below and follow the instructions.'
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "With this step, we are already on our way to more effective advertising!"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        class: _vm.connectProfileButtonClasses,
                        attrs: { disabled: _vm.isConnecting },
                        on: { click: () => _vm.connect() },
                      },
                      [
                        _vm.isConnecting
                          ? _c("i", { staticClass: "icon loader white" })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.tr("Connect Profile")) + " "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 2,
                      expression: "step === 2",
                    },
                  ],
                  staticClass: "step step--2 step--ad-account",
                },
                [
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "Great, now connect at least one Facebook ad account to manage your ads with <b class='primary'>Infinite∞Ad</b>."
                          )
                        ),
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            'Use the checkboxes to select the advertising accounts you want to manage, then go to the last step by pressing the "Next" button below.'
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scroll-wrapper" },
                    [
                      _c("integrations-meta-ad-accounts-view", {
                        attrs: { minimal: "" },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Back")))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Next")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 3,
                      expression: "step === 3",
                    },
                  ],
                  staticClass: "step step--3 step--source-profile",
                },
                [
                  _c("div", { staticClass: "title small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tr(
                          "Congratulations, you are very close to creating effective ads!"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "In the next step, use the checkboxes to connect the <b>Facebook Page</b> data sources from which you want to use the posts as raw material for ads."
                          )
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scroll-wrapper" },
                    [
                      _c("integrations-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "facebook",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Back")))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Next")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 4,
                      expression: "step === 4",
                    },
                  ],
                  staticClass: "step step--4 step--source-profile",
                },
                [
                  _c("div", { staticClass: "title small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tr(
                          "Congratulations, you are very close to creating effective ads!"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "In the next step, use the checkboxes to connect the <b>Instagram Account</b> data sources from which you want to use the posts as raw material for ads."
                          )
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scroll-wrapper" },
                    [
                      _c("integrations-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "instagram",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Back")))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Finish")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 5,
                      expression: "step === 5",
                    },
                  ],
                  staticClass: "step step--5 step--done",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-done.png"),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Fantastic!"))),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "From now on, with the help of <span class='primary'>Infinite∞Ad</span>, you can easily automate your ads and achieve the best results."
                          )
                        ),
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "Remember, if you get stuck at any time, we are available to help you."
                          )
                        )
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr("Now start your new advertising experience!")
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bottom-button" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button primary",
                          attrs: { to: "/automation/new/ai-mode" },
                          nativeOn: {
                            click: function ($event) {
                              return (() => _vm.$emit("close")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Create my first Automation")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 1 && _vm.step <= 4,
                    expression: "step >= 1 && step <= 4",
                  },
                ],
                staticClass: "dots",
              },
              [
                _c("div", { class: { dot: true, active: _vm.step >= 1 } }),
                _c("div", { class: { dot: true, active: _vm.step >= 2 } }),
                _c("div", { class: { dot: true, active: _vm.step >= 3 } }),
                _c("div", { class: { dot: true, active: _vm.step >= 4 } }),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }