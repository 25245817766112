<template>
  <div class="source-profile-flag" :class="flagColor">
    {{ name }}
    <i class="icon white" :class="platform" />
  </div>
</template>

<script>
export default {
  name: 'SourceProfileFlag',
  props: {
    name: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
    flagColor: {
      type: String,
      default: 'blue',
    },
  },
};

</script>
