<template>
  <div class="age-selector">
    <v-select
      v-model="insideValue"
      :reduce="i => i.value"
      :options="ageOptions"
      :searchable="false"
      :clearable="false"
    />
  </div>
</template>

<script>
import MultiLevelValueModel from '@/mixins/MultiLevelValueModel';

export default {
  name: 'AgeSelector',
  mixins: [ MultiLevelValueModel ],
  props: {
    value: {
      type: [ Number, String, null ],
      required: false,
      default: () => null,
    },
  },
  computed: {
    ageOptions() {
      const ages = [];

      for (let i = 18; i <= 65; i++) {
        const label = i === 65 ? '65+' : i;

        ages.push({
          label,
          value: i,
        });
      }

      return ages;
    },
  },
};
</script>
