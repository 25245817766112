<template>
  <div class="placement-selector">
    <div class="upper">
      <three-state-checkbox
        v-for="(platform, key) in platforms"
        :key="'top-checkbox-' + key"
        v-model="entity.settings.adset_targeting_positions[platform.machineName]"
        :items="adPlacements[platform.machineName].map(fbAdPlacement => fbAdPlacement.machine_name)"
        :disabled="disabled || isDisabledPlatform(platform)"
        @change="() => $nextTick(() => $forceUpdate())"
      >
        {{ platform.label }}
      </three-state-checkbox>
    </div>

    <div class="divider" />

    <div class="under">
      <div
        v-for="(platform, key) in platforms"
        :key="'bottom-checkbox-' + key"
        class="checkbox-list vertical"
      >
        <label
          v-for="(fbAdPlacement, key) in adPlacements[platform.machineName]"
          :key="'fb-ad-placement-' + key"
          class="fb-placement-list"
        >
          <input
            v-model="entity.settings.adset_targeting_positions[platform.machineName]"
            type="checkbox"
            :value="fbAdPlacement.machine_name"
            :disabled="disabled || isDisabledPlatform(platform)"
            @change="$nextTick(() => $forceUpdate())"
          />
          {{ tr(fbAdPlacement.label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ThreeStateCheckbox from '@/components/ThreeStateCheckbox.vue';
import { PLATFORMS } from '@/consts';

export default {
  name: 'PlacementSelector',
  components: { ThreeStateCheckbox },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    disabledFacebook: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    adPlacements() {
      return this.$store.getters['app/facebookAdPlacements'];
    },
    platforms() {
      return PLATFORMS;
    },
  },
  watch: {
    disabledFacebook: {
      immediate: true,
      handler(val) {
        if (val) {
          this.entity.settings.adset_targeting_positions['facebook'] = [];
          this.$forceUpdate();
        }
      },
    },
  },
  methods: {
    isDisabledPlatform(platform) {
      return platform.machineName === 'facebook' && this.disabledFacebook;
    },
  },
};
</script>
