<template>
  <div class="source-select-component">
    <div
      v-for="(sourceProfile, key) in entity.source_profiles"
      :key="'source-list-row-' + key"
      class="select-wrapper"
    >
      <v-select
        v-model="sourceProfile.platform"
        :options="availablePlatformOptions"
        :reduce="(option) => option.machineName"
        :searchable="false"
        :clearable="false"
        :placeholder="tr('Platform')"
        :disabled="disabledPlatformSelector"
        class="social"
        @option:selecting="() => sourceProfile.id = null"
      >
        <template #no-options>
          {{ tr('No elements found') }}
        </template>
      </v-select>

      <v-select
        v-model="sourceProfile.id"
        :options="filteredSources(sourceProfile.platform)"
        :reduce="(option) => option.id"
        :clearable="false"
        :disabled="disabled || !sourceProfile.platform"
        :placeholder="sourcePlaceholder(sourceProfile)"
        label="name"
        class="source"
        @option:selecting="(option) => sourceProfile.updateWith(option)"
      >
        <template #no-options>
          {{ tr('No elements found') }}
        </template>
      </v-select>

      <i
        v-if="showDeleteButton"
        class="icon delete dark-gray"
        @click="() => removeRow(key)"
      />
    </div>

    <button v-if="canAddSource" class="big primary" @click="() => addRow()">
      + {{ tr('Add Source') }}
    </button>
  </div>
</template>

<script>
import SourceProfile from '@/entities/SourceProfile.js';

export default {
  name: 'SourceSelect',
  props: {
    sources: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    disabledFacebook: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    limit: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    currentAdAccountOwner: null,
  }),
  computed: {
    availablePlatformOptions() {
      const platforms = this.sources.map(i => i.platform);
      const uniquePlatforms = [ ...new Set(platforms) ];

      let result = [];
      uniquePlatforms.forEach(item => {
        result.push({ label: this.capitalizeFirstLetter(item), machineName: item });
      });

      if (this.disabledFacebook) {
        result = result.filter(i => i.machineName !== 'facebook');
      }

      return result;
    },
    filteredSourcesByAvalablePlatforms() {
      const availablePlatforms = this.availablePlatformOptions.map(i => i.machineName);
      return this.sources.filter(i => availablePlatforms.includes(i.platform));
    },
    disabledPlatformSelector() {
      return this.availablePlatformOptions.length <= 1;
    },
    showDeleteButton() {
      return !this.disabled && this.entity.source_profiles.length > 1;
    },
    canAddSource() {
      /* return this.entity.source_profiles.length < this.filteredSourcesByAvalablePlatforms.length
        && (!this.limit || this.limit > this.entity.source_profiles.length)
        && !this.disabled; */

      return false;
    },
  },
  watch: {
    availablePlatformOptions: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length === 1) {
          this.entity.source_profiles.forEach(sourceProfile => sourceProfile.platform = val[0].machineName);
        }
      },
    },
    filteredSourcesByAvalablePlatforms: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length === 1) {
          this.entity.source_profiles.forEach(sourceProfile => sourceProfile.id = val[0].id);
        }
      },
    },
    'entity.source_profiles': {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length === 0) {
          this.addRow();
        }
        else if (val.length > 1) {
          this.sourceProfileDuplicationCheck();
        }
      },
    },
  },
  created() {
    if (this.entity.source_profiles.length === 0) {
      this.addRow();
    }
  },
  methods: {
    sourcePlaceholder(sourceProfile) {
      switch (sourceProfile.platform) {
        case 'facebook':
          return this.tr('Page');

        case 'instagram':
          return this.tr('Account');
      }

      return '';
    },
    addRow() {
      const newRow = new SourceProfile();
      if (this.availablePlatformOptions.length === 1) {
        newRow.platform = this.availablePlatformOptions[0].machineName;
      }

      this.entity.source_profiles.push(newRow);
    },
    removeRow(rowIndex) {
      this.entity.source_profiles.splice(rowIndex, 1);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    filteredSources(platform) {
      return this.sortSources(this.filteredSourcesByAvalablePlatforms.filter(i => i.platform === platform));
    },
    sortSources(sources) {
      return sources.sort((a, b) => a.name.localeCompare(b.name));
    },
    sourceProfileDuplicationCheck() {
      const selectedSources = [];

      for (const [ index, item ] of this.entity.source_profiles.entries()) {
        if (!item.id) {
          continue;
        }

        if (selectedSources.includes(item.id)) {
          this.entity.source_profiles[index].id = null;
          this.error(this.tr('Cannot set duplicate datasources!'));
        }
        else {
          selectedSources.push(item.id);
        }
      }
    },
  },
};
</script>
