<template>
  <input
    v-model="insideValue"
    type="text"
    :placeholder="placeholder"
    :disabled="disabled"
    @focus="$event => $emit('focus', $event)"
    @blur="$event => onBlur($event)"
    @keyup="$event => $emit('keyup', $event)"
    @keydown="$event => onKeyDown($event)"
  />
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: [ Number, String, null ],
      required: false,
      default: () => null,
    },
    min: {
      type: [ Number, null ],
      required: false,
      default: () => null,
    },
    max: {
      type: [ Number, null ],
      required: false,
      default: () => null,
    },
    step: {
      type: Number,
      required: false,
      default: () => 1,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    insideValue: '',
  }),
  computed: {
    numberValue() {
      const val = parseFloat(this.value);
      return isNaN(parseFloat(this.value)) ? 0 : val;
    },
  },
  watch: {
    insideValue(val) {
      if (isNaN(parseFloat(val))) {
        val = '';
      }
      else if (typeof val === 'string' && val.indexOf(',') !== -1) {
        val = val.replace(',', '.');
      }

      this.$emit('input', val);
    },
    value: {
      immediate: true,
      handler() {
        this.insideValue = this.value;
      },
    },
  },
  methods: {
    onKeyDown(event) {
      this.$nextTick(() => this.$emit('keydown', event));

      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }

      if (event.key === 'ArrowUp') {
        let newValue = this.numberValue + this.step;
        if (this.max !== null && newValue > this.max) {
          newValue = this.max;
        }
        this.$emit('input', newValue);
        return;
      }
      else if (event.key === 'ArrowDown') {
        let newValue = this.numberValue - this.step;
        if (this.min !== null && newValue < this.min) {
          newValue = this.min;
        }
        this.$emit('input', newValue);
        return;
      }

      if (
        event.key.length === 1
        && event.key !== '.'
        && event.key !== ','
        && isNaN(parseInt(event.key))
      ) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    },
    onBlur($event) {
      if (this.min !== null && this.numberValue < this.min) {
        this.$emit('input', this.min);
      }
      else if (this.max !== null && this.numberValue > this.max) {
        this.$emit('input', this.max);
      }

      this.$emit('blur', $event);
    },
  },
};
</script>
