<template>
  <form-block
    class="rule-form-block post-type-block-component"
    :title="tr('Post type')"
    :description="description"
  >
    <template v-if="showFacebookBlock">
      <post-type-selector
        ref="facebookSelector"
        platform="facebook"
        :entity="entity"
        :disabled="disabled"
      />

      <info-text v-if="entity.facebook_post_media_types.includes('event')">
        {{ tr('Events can\'t be advertised directly, but if you share the event on your Facebook Page, you\'ll be able to boost that post.') }}
      </info-text>
    </template>

    <template v-if="showInstagramBlock">
      <post-type-selector
        ref="instagramSelector"
        platform="instagram"
        :entity="entity"
        :disabled="disabled"
      />
    </template>

    <div v-if="!showFacebookBlock && !showInstagramBlock" class="blocker-text">
      {{ tr('Please, select a source first.') }}
    </div>
  </form-block>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import PostTypeSelector from '@/components/RuleForm/PostTypeSelector.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'PostTypeBlock',
  components: {
    InfoText,
    PostTypeSelector,
  },
  mixins: [ RuleFormBlockMixin ],
  props: {
    description: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
  },
  computed: {
    showFacebookBlock() {
      return this.entity.hasSelectedFacebookPlatform;
    },
    showInstagramBlock() {
      return this.entity.hasSelectedInstagramPlatform;
    },
  },
  watch: {
    showFacebookBlock: {
      immediate: true,
      handler(val) {
        if (val && this.entity.facebook_post_types.length == 0) {
          this.$nextTick(() => this.$refs.facebookSelector.selectAll());
        }
        else if (!this.showFacebookBlock) {
          this.entity.facebook_post_types = [];
          this.entity.facebook_post_media_types = [];
        }
      },
    },
    showInstagramBlock: {
      immediate: true,
      handler(val) {
        if (val && this.entity.instagram_post_types.length === 0) {
          this.$nextTick(() => this.$refs.instagramSelector.selectAll());
        }
        else if (!val) {
          this.entity.instagram_post_types = [];
          this.entity.instagram_post_media_types = [];
        }
      },
    },
  },
  methods: {
    validate() {
      if (this.showFacebookBlock) {
        if (this.entity.facebook_post_types.length === 0) {
          this.error(this.tr('At least one {platform} post type must be selected!', { '{platform}': 'Facebook' }));
          return false;
        }
        if (this.entity.facebook_post_media_types.length === 0) {
          this.error(this.tr('At least one {platform} post media type must be selected!', { '{platform}': 'Facebook' }));
          return false;
        }
      }

      if (this.showInstagramBlock) {
        if (this.entity.instagram_post_types.length === 0) {
          this.error(this.tr('At least one {platform} post type must be selected!', { '{platform}': 'Instagram' }));
          return false;
        }
        if (this.entity.instagram_post_media_types.length === 0) {
          this.error(this.tr('At least one {platform} post media type must be selected!', { '{platform}': 'Instagram' }));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
