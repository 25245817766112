var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integrations-meta-profiles-view" },
    [
      _vm.isProfilesEmpty
        ? _c(
            "notification-card",
            {
              attrs: {
                image: require("@/assets/img/onboarding/step-profile.png"),
                "empty-screen": "",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "First, connect your Meta Profile, then you'll see all of your assets"
                    )
                  ) +
                  " "
              ),
              _vm.connectButtonVisible
                ? _c(
                    "button",
                    {
                      class: _vm.connectProfileButtonClasses,
                      attrs: { disabled: _vm.isConnecting },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("connect")
                        },
                      },
                    },
                    [
                      _vm.isConnecting
                        ? _c("i", { staticClass: "icon loader white" })
                        : [_vm._v("+")],
                      _vm._v(
                        " " + _vm._s(_vm.tr("Connect Your Profile")) + " "
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            { staticClass: "profiles" },
            _vm._l(_vm.profiles, function (profile) {
              return _c("connected-profile-item", {
                key: profile.id,
                attrs: { entity: profile },
                on: {
                  remove: () => _vm.removeProfile(profile),
                  reconnect: function ($event) {
                    return _vm.$emit("reconnect", profile)
                  },
                },
              })
            }),
            1
          ),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }