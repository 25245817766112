<template>
  <form-block
    class="rule-form-block tracking-block"
    :title="tr('Tracking')"
    :description="tr('You can track datasets of events that include conversions your ad may have driven. By default, the system tracks the dataset associated with the conversion selected for your ad account.')"
    :disabled="disabled"
  >
    <div class="checkbox-list" :class="{ 'disabled': !hasAdvertisableApplications }">
      <label>
        <input v-model="appEventsChecked" type="checkbox" :disabled="!hasAdvertisableApplications">
        {{ tr('App events') }}
      </label>
      <v-select
        v-if="appEventsChecked"
        v-model="entity.settings.tracking_advertisable_application_id"
        :options="advertisableApplications"
        :reduce="(obj) => obj.id"
        :placeholder="tr('Select an app')"
        label="name"
        :disabled="advertisableApplications.length === 0"
        :clearable="false"
      />
    </div>

    <div class="checkbox-list" :class="{'disabled': !hasPixels}">
      <label>
        <input v-model="websiteEventsChecked" type="checkbox" :disabled="!hasPixels">
        {{ tr('Website events') }}
      </label>
      <v-select
        v-if="websiteEventsChecked"
        v-model="entity.settings.tracking_pixel_id"
        :options="pixels"
        :reduce="(obj) => obj.id"
        :placeholder="tr('Select a pixel')"
        label="name"
        :disabled="pixels.length === 0"
        :clearable="false"
      />
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'TrackingBlock',
  mixins: [ RuleFormBlockMixin ],
  data() {
    return {
      websiteEventsChecked: !!this.entity.settings.tracking_pixel_id,
      appEventsChecked: !!this.entity.settings.tracking_advertisable_application_id,
    };
  },
  computed: {
    advertisableApplications() {
      return this.entity.selectedAdAccount.advertisable_applications || [];
    },
    pixels() {
      return this.entity.selectedAdAccount.pixels || [];
    },
    hasAdvertisableApplications() {
      return this.advertisableApplications.length > 0;
    },
    hasPixels() {
      return this.pixels.length > 0;
    },
  },
  watch: {
    websiteEventsChecked(val) {
      if (!val) {
        this.entity.settings.tracking_pixel_id = null;
      }
    },
    appEventsChecked(val) {
      if (!val) {
        this.entity.settings.tracking_advertisable_application_id = null;
      }
    },
  },
  methods: {
    validate() {
      if (this.websiteEventsChecked && !this.entity.settings.tracking_pixel_id) {
        this.error('When a website is used as an event tracking location, a Pixel must be added!');
        return false;
      }
      if (this.appEventsChecked && !this.entity.settings.tracking_advertisable_application_id) {
        this.error('When an app is used as an event tracking location, an App must be added!');
        return false;
      }

      return true;
    },
  },
};
</script>
