import BaseEntity from '@/entities/BaseEntity';

export default class AdMetric extends BaseEntity {
  period = null;
  reach = null;
  impressions = null;
  clicks = null;
  clicks_unique = null;
  inline_link_click_ctr = null;
  cost_per_link_click = null;
  purchase_roas = null;
  video_views = null;
  actions = [];
  action_values = [];
  cost_per_action_type = [];
  spend = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
