<template>
  <div class="facebook-ad-preview-component">
    <div class="top">
      <div class="profile">
        <div class="image">
          <i class="icon infinite white" />
        </div>
        <div class="name">
          {{ name }}
        </div>
      </div>

      <div v-if="text" class="main-text" v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
    </div>

    <div v-if="imgUrl" class="image">
      <img :src="imgUrl" alt="Ad Image" />
    </div>

    <div v-if="ctaTitle || ctaDescription" class="cta-section">
      <div class="texts">
        <div v-if="ctaTitle" class="cta-title">{{ ctaTitle }}</div>
        <div v-if="ctaDescription" class="cta-description">{{ ctaDescription }}</div>
      </div>
      <div v-if="ctaButton" class="cta-button">{{ ctaButton }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaTitle: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaDescription: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaButton: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
  },
  computed: {
    name() {
      return process.env.VUE_APP_HTML_TITLE;
    },
  },
};
</script>

