<template>
  <div class="magic-tool-view">
    <sticky-header
      :title="title"
      :info="info"
      bordered
    />

    <div class="transition-position-fix">
      <div class="form-with-preview">
        <magic-tool-form
          ref="form"
          @image-uploaded="(image) => onImageUploaded(image)"
          @entity-created="(entity) => onEntityCreated(entity)"
        />
        <facebook-ad-preview
          v-if="showPreview"
          :text="$refs.form.text"
          :cta-title="$refs.form.title"
          :cta-description="$refs.form.description"
          :cta-button="previewCtaButton"
          :img-url="imageUrl"
        />
      </div>

      <div ref="scrollHereAfterSubmit" class="divider" />

      <h3>{{ tr('Completed evaluations') }}</h3>

      <div v-if="!isLoading && list.length === 0" class="empty-text">
        {{ tr('There are no evaluation requests yet.') }}
      </div>
      <sorted-table v-else :data="list" class="row-hover-shadow">
        <template #header>
          <table-head col="post" min-width="200px">{{ tr('Content') }}</table-head>
          <table-head col="ai-score" width="100px" class="centered-text">{{ tr('AI Score') }}</table-head>
          <table-head col="owner" width="180px">{{ tr('Owner') }}</table-head>
          <table-head col="requested" width="150px">{{ tr('Requested') }}</table-head>
          <table-head col="expiry-date" width="150px">{{ tr('Expiry date') }}</table-head>
          <table-head col="policy-check" width="140px" class="centered-text">{{ tr('Policy check') }}</table-head>
        </template>
        <template #default="{ sort }">
          <table-row
            v-for="item in sort"
            :key="item.id"
            subrow-color='primary'
            subrow-opened-color='white'
            :subrow-opener-label="tr('More information')"
          >
            <table-data col="post" class="post-content">
              <img
                v-if="item.image"
                class="post-content-img"
                :src="item.image.url"
              />
              <div class="post-content-text">{{ item.text }}</div>
            </table-data>
            <table-data col="ai-score" class="col-center">
              <ai-score :value="item.value" />
            </table-data>
            <table-data col="owner">
              <span>{{ item.owner.firstname + ' ' + item.owner.lastname }}</span>
            </table-data>
            <table-data col="requested">
              <span v-tooltip="formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)">
                {{ timeAgo(item.created_at, tr) }}
              </span>
            </table-data>
            <table-data col="expiry-date">
              <skeleton v-if="item.contentActualityCheckInProgress" width="80px" height="16px" />
              <div v-else-if="item.expiry_date">
                <div>{{ formatToYMD(item.expiry_date)}}</div>
                <div>{{ formatTime(item.expiry_date) }}</div>
              </div>
              <template v-else>
                -
              </template>
            </table-data>
            <table-data col="policy-check" class="policy-check-cell">
              <skeleton v-if="item.policyCheckInProgress" width="50px" height="16px" />
              <post-content-policy-check-status v-else :value="item.policy_check_is_prohibited" />
            </table-data>
            <template #subrow>
              <template v-if="item.inProgress">
                {{ tr('The content is being analyzed! Please wait...') }}
              </template>
              <template v-else>
                {{ tr('Explore in-depth content analysis metrics and insights') }}
              </template>
            </template>
            <template v-if="!item.inProgress" #subrow-hidable>
              <magic-tool-item-details
                :entity="item"
                @entity-improve-begin="(entity) => onEntityImproveBegin(entity)"
                @entity-deleted="(entity) => onEntityDeleted(entity)"
              />
            </template>
          </table-row>
          <template v-if="isLoading || hasMore">
            <table-row
              v-for="i in [ 1, 2 ]"
              :key="'magic-tool-loader-skeleton-' + i"
              ref="loadMore"
              class="skeleton-row"
            >
              <table-data col="post" class="post-content">
                <div class="post-content-text">
                  <skeleton width="100%" height="10px" />
                  <skeleton width="95%" height="10px" />
                  <skeleton width="90%" height="10px" />
                  <skeleton width="95%" height="10px" />
                </div>
              </table-data>
              <table-data col="ai-score" class="col-center">
                <skeleton width="32px" height="28px" />
              </table-data>
              <table-data col="owner">
                <span>
                  <skeleton width="100px" height="16px" />
                </span>
              </table-data>
              <table-data col="requested">
                <skeleton width="100px" height="16px" />
              </table-data>
              <table-data col="expiry-date">
                <skeleton width="80px" height="16px" />
              </table-data>
              <table-data col="policy-check" class="policy-check-cell">
                <skeleton width="50px" height="16px" />
              </table-data>
            </table-row>
          </template>
        </template>
      </sorted-table>
    </div>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import FacebookAdPreview from '@/components/FacebookAdPreview.vue';
import MagicToolForm from '@/components/MagicToolForm.vue';
import MagicToolItemDetails from '@/components/MagicToolItemDetails.vue';
import PostContentPolicyCheckStatus from '@/components/PostContentPolicyCheckStatus.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import MagicToolService from '@/services/MagicTool.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';

const ITEM_PER_PAGE = 20;

export default {
  name: 'PostView',
  components: {
    AiScore,
    FacebookAdPreview,
    MagicToolForm,
    MagicToolItemDetails,
    PostContentPolicyCheckStatus,
    Skeleton,
    StickyHeader,
  },
  data: () => ({
    isMounted: false,
    isLoading: false,
    hasMore: true,
    page: 0,
    list: [],
    scrollPagerObserver: null,
    fetchTimers: {},
    imageUrl: null,
  }),
  computed: {
    title() {
      return this.tr('Magic Tool');
    },
    info() {
      return this.tr('The <b class="primary">Infinite∞Ai</b> evaluates your posts based on 5 criteria and provides suggestions for the text to make them as sought after as possible. 🧠🤖');
    },
    showPreview() {
      if (!this.isMounted) {
        return null;
      }

      return this.$refs.form.text || this.$refs.form.title || this.$refs.form.description;
    },
    previewCtaButton() {
      if (!this.isMounted) {
        return null;
      }

      switch (this.$refs.form.goal) {
        case 'conversion':
          return this.tr('Shop now');

        case 'traffic':
          return this.tr('Learn more');

        case 'app_promotion':
          return this.tr('Use App');
      }

      return null;
    },
  },
  created() {
    this.loadNewData();
  },
  mounted() {
    this.isMounted = true;
  },
  beforeDestroy() {
    this.destroyAllFetchTimers();
  },
  methods: {
    formatTime,
    formatToYMD,
    timeAgo,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;

      ++this.page;

      MagicToolService.getList({ page: this.page, limit: ITEM_PER_PAGE })
        .then(resp => {
          resp.forEach(entity => {
            this.list.push(entity);
            if (entity.hasInProgressParts) {
              this.initFetchTimer(entity.id);
            }
          });

          this.hasMore = resp.length === ITEM_PER_PAGE;

          this.reinitScrollPager();

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    reinitScrollPager() {
      this.disconnectScrollPager();

      if (!this.hasMore) {
        return;
      }

      this.scrollPagerObserver = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.disconnectScrollPager();
          this.loadNewData();
        }
      });

      if (this.$refs.loadMore[0]) {
        this.scrollPagerObserver.observe(this.$refs.loadMore[0].$el);
      }
    },
    disconnectScrollPager() {
      if (this.scrollPagerObserver !== null) {
        this.scrollPagerObserver.disconnect();
        this.scrollPagerObserver = null;
      }
    },
    onEntityCreated(entity) {
      if (this.list.length === 10) {
        this.list.pop();
      }
      this.list.unshift(entity);

      this.$nextTick(() => {
        this.$refs.scrollHereAfterSubmit.scrollIntoView({ behavior: 'smooth' });
      });

      this.initFetchTimer(entity.id);
    },
    onEntityImproveBegin(entity) {
      this.initFetchTimer(entity.id);
    },
    onEntityDeleted(entity) {
      this.list = this.list.filter(i => i.id !== entity.id);
    },
    initFetchTimer(id) {
      if (id in this.fetchTimers) {
        return;
      }

      this.fetchTimers[id] = setInterval(() => {
        MagicToolService.get(id)
          .then(entity => {
            const index = this.list.findIndex(i => i.id === id);
            if (index !== -1) {
              this.list.splice(index, 1, entity);
            }

            if (!entity.hasInProgressParts) {
              clearInterval(this.fetchTimers[id]);
              delete this.fetchTimers[id];
            }

            return entity;
          })
          .catch(err => {
            clearInterval(this.fetchTimers[id]);
            delete this.fetchTimers[id];
            this.error(err);
          });
      }, 10000);
    },
    destroyAllFetchTimers() {
      Object.keys(this.fetchTimers).forEach(id => {
        clearInterval(this.fetchTimers[id]);
        delete this.fetchTimers[id];
      });
    },
    onImageUploaded(image) {
      this.imageUrl = URL.createObjectURL(image);
    },
  },
};
</script>
