import Comment from '@/entities/Comment.js';
import Post from '@/entities/Post.js';
import api from '@/services/Api';

export default {
  getList({ term, sourceProfileId, orderBy, order, page, limit, withComments, withModeratedComments }) {
    const data = {};
    if (term) {
      data['term'] = term;
    }
    if (orderBy) {
      data['order_by'] = orderBy;
    }
    if (order) {
      data['order'] = order;
    }
    if (sourceProfileId) {
      data['source_profile_id'] = sourceProfileId;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    if (withModeratedComments) {
      data['with_moderated_comments'] = 1;
    }
    else if (withComments) {
      data['with_comments'] = 1;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/posts?' + query)
      .then(resp => resp.map(i => new Post(i)));
  },

  get(id) {
    return api().get('/post/' + id)
      .then(resp => new Post(resp));
  },

  update(entity) {
    return api().put('/post/' + entity.id, entity.toObject())
      .then(resp => new Post(resp));
  },

  delete(id) {
    return api().delete('/post/' + id);
  },

  refresh(id) {
    return api().post('/post/' + id + '/refresh')
      .then(resp => new Post(resp));
  },

  moderate(id) {
    return api().post('/post/' + id + '/moderate');
  },

  getComments(id, { term, orderBy, order, page, limit }) {
    const data = {};
    if (term) {
      data['term'] = term;
    }
    if (orderBy) {
      data['order_by'] = orderBy;
    }
    if (order) {
      data['order'] = order;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/post/' + id + '/comments?' + query)
      .then(resp => resp.map(i => new Comment(i)));
  },

  getEmbed(id) {
    return api().get('/post/' + id + '/embed');
  },

  changeStatus(id, active) {
    return api().put('/post/' + id + '/status', { active });
  },
};
