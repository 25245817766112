<template>
  <div class="post-type-selector-component">
    <label class="input-label platform-title">{{ label }}</label>
    <template v-if="postTypes.length > 1">
      <label class="input-label">{{ tr('Post type') }}</label>
      <div class="checkbox-list horizontal">
        <label v-for="(postType, key) in postTypes" :key="platform + '-post-type-' + key">
          <input
            v-model="entity[postTypeProperty]"
            type="checkbox"
            :value="postType.machine_name"
            :disabled="disabled && (postType.machine_name === 'reels')"
          />
          {{ tr(postType.label) }}
        </label>
      </div>
    </template>

    <template v-if="postMediaTypes.length > 1">
      <label class="input-label">{{ tr('Media type') }}</label>
      <div class="checkbox-list horizontal">
        <template v-for="(mediaType, key) in postMediaTypes">
          <label v-if="mediaTypeIsAvailabe(mediaType)" :key="platform + '-media-type-' + key">
            <input
              v-model="entity[postMediaTypeProperty]"
              type="checkbox"
              :value="mediaType.machine_name"
              :disabled="disabled"
            />
            {{ tr(mediaType.label) }}
          </label>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PostTypeSelector',
  props: {
    platform: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    postTypes() {
      return this.$store.getters['app/' + this.platform + 'PostTypes'];
    },
    postMediaTypes() {
      return this.$store.getters['app/' + this.platform + 'PostMediaTypes'];
    },
    postTypeProperty() {
      return this.platform + '_post_types';
    },
    postMediaTypeProperty() {
      return this.platform + '_post_media_types';
    },
    albumMediaTypeIsAvailable() {
      return this.entity[this.postTypeProperty].includes('feed');
    },
    nonVideoTypesAreAvailable() {
      return this.platform !== 'facebook' || this.entity[this.postTypeProperty].includes('feed');
    },
    label() {
      return this.platform.charAt(0).toUpperCase() + this.platform.slice(1);
    },
  },
  watch: {
    albumMediaTypeIsAvailable(val) {
      if (!val) {
        this.entity[this.postMediaTypeProperty] = this.entity[this.postMediaTypeProperty].filter(item => item !== 'album');
      }
    },
    nonVideoTypesAreAvailable(val) {
      if (!val) {
        this.entity[this.postMediaTypeProperty] = this.entity[this.postMediaTypeProperty].filter(item => item === 'video');
      }
    },
  },
  methods: {
    selectAll() {
      let newPostTypes = this.postTypes.map(item => item.machine_name);
      if (this.platform === 'facebook' && this.entity.action === 'start') {
        newPostTypes = newPostTypes.filter(item => item !== 'reels');
      }

      this.entity[this.postTypeProperty] = newPostTypes;
      this.entity[this.postMediaTypeProperty] = this.postMediaTypes.map(item => item.machine_name);
    },
    mediaTypeIsAvailabe(mediaType) {
      if (mediaType.machine_name !== 'video') {
        if (!this.nonVideoTypesAreAvailable) {
          return false;
        }
      }

      return mediaType.machine_name !== 'album' || this.albumMediaTypeIsAvailable;
    },
  },
};
</script>
