<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--scaling-up--step-3">
    <adset-name-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <adset-budget-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>

<script>
import AdsetBudgetBlock from '@/components/RuleForm/Blocks/AdsetBudgetBlock.vue';
import AdsetNameBlock from '@/components/RuleForm/Blocks/AdsetNameBlock.vue';
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

export default {
  name: 'ScalingUpRuleFormStep3',
  components: {
    AdsetBudgetBlock,
    AdsetNameBlock,
  },
  mixins: [ RuleFormStepMixin ],
  methods:{
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      return true;
    },
  },
};
</script>
