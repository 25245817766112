export function isValidEmail(email) {
  const emailString = (email || '').toString();

  return emailString
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}
export function isValidURL(url) {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  }
  catch (err) {
    return false;
  }
}

export function isValidURLParameter(params, macros = []) {
  const validParamName = '[a-zA-Z_][a-zA-Z0-9_]*';
  const validParamValue = '[^&]+';

  if (/&&|==/.test(params)) {
    return false;
  }

  const paramRegex = new RegExp(`(${validParamName})(=(?:${validParamValue}))?`, 'g');
  const macroRegex = /\{\{(.*?)\}\}/g;
  const matches = Array.from(params.matchAll(paramRegex));

  for (const match of matches) {
    const [ _fullMatch, key, value ] = match;
    if (!key) {
      return false;
    }
    if (key && value === undefined) {
      return true;
    }

    if (value.startsWith('=')) {
      const actualValue = value.slice(1);
      const foundMacros = actualValue.match(macroRegex) || [];

      for (const foundMacro of foundMacros) {
        if (!macros.includes(foundMacro)) {
          return false;
        }
      }

      if (actualValue.includes('{') || actualValue.includes('}')) {
        const invalidMacroRegex = /(?<!{){[^{}]*}(?!})|(?<!{){{[^{}]*}(?!})|(?<!{){[^{}]*}}(?!})/g;
        if (invalidMacroRegex.test(actualValue)) {
          return false;
        }
      }
    }
  }

  return true;
}
