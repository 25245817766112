export function convertOldCampaignObjectiveToOdax(objective, conversionEvent) {
  switch (objective) {
    case 'BRAND_AWARENESS':
    case 'REACH':
      return 'OUTCOME_AWARENESS';

    case 'LINK_CLICKS':
      return 'OUTCOME_TRAFFIC';

    case 'POST_ENGAGEMENT':
      return 'OUTCOME_ENGAGEMENT';

    case 'VIDEO_VIEWS':
      return 'OUTCOME_ENGAGEMENT';

    case 'CONVERSIONS':
      if (salesFunnelEvents.includes(conversionEvent)) {
        return 'OUTCOME_SALES';
      }

      return 'OUTCOME_LEADS';
  }

  return objective;
}

export const leadFunnelEvents = [
  'COMPLETE_REGISTRATION',
  'CONTACT',
  'FIND_LOCATION',
  'LEAD',
  'SCHEDULE',
  'SEARCH',
  'START_TRIAL',
  'SUBMIT_APPLICATION',
  'SUBSCRIBE',
  'VIEW_CONTENT',
];

export const salesFunnelEvents = [
  'ADD_TO_CART',
  'ADD_TO_WISHLIST',
  'ADD_PAYMENT_INFO',
  'COMPLETE_REGISTRATION',
  'DONATE',
  'INITIATE_CHECKOUT',
  'PURCHASE',
  'SEARCH',
  'START_TRIAL',
  'SUBSCRIBE',
  'VIEW_CONTENT',
];
