<template>
  <router-link :to="path" :class="wrapperClasses">
    <div class="bottom">
      <div class="first-line">
        <i :class="'action-icon icon ' + icon + ' ' + color" />
        <div class="title">{{ title }}</div>
      </div>

      <div class="description">{{ description }}</div>

      <navigation-arrow :link="path" :color="color" />
    </div>
  </router-link>
</template>

<script>
import NavigationArrow from '@/components/NavigationArrow.vue';

export default {
  name: 'CreateNewRuleCard',
  components: {
    NavigationArrow,
  },
  props: {
    color:  {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    wrapperClasses() {
      const result = {};
      result['create-new-rule-card-component'] = true;
      result['color--' + this.color] = true;
      return result;
    },
  },
};
</script>
