var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "invite-member-modal",
      attrs: { name: "invite-member" },
      on: { closed: () => _vm.resetForm() },
    },
    [
      _c("div", { staticClass: "modal-title" }, [
        _vm._v(" " + _vm._s(_vm.tr("Invite a teammember")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(" " + _vm._s(_vm.tr("Access level")) + " "),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.userLevels,
                  reduce: (val) => val.value,
                  searchable: false,
                  clearable: false,
                  appendToBody: false,
                },
                model: {
                  value: _vm.level,
                  callback: function ($$v) {
                    _vm.level = $$v
                  },
                  expression: "level",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(" " + _vm._s(_vm.tr("First name")) + " "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstname,
                  expression: "firstname",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.firstname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.firstname = $event.target.value
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(" " + _vm._s(_vm.tr("Last name")) + " "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastname,
                  expression: "lastname",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.lastname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.lastname = $event.target.value
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(" " + _vm._s(_vm.tr("E-mail")) + " "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              attrs: { type: "email" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _vm.level === "restricted_viewer" || _vm.level === "restricted_editor"
            ? [
                _vm.$app.isAd
                  ? _c(
                      "div",
                      [
                        _c("label", { staticClass: "input-label" }, [
                          _vm._v(
                            " " + _vm._s(_vm.tr("Visible Ad Accounts")) + " "
                          ),
                        ]),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.adAccounts,
                            "append-to-body": false,
                            "value-key": "id",
                            "label-key": "name",
                          },
                          model: {
                            value: _vm.visibleAdAccountIds,
                            callback: function ($$v) {
                              _vm.visibleAdAccountIds = $$v
                            },
                            expression: "visibleAdAccountIds",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Visible Datasources")) + " "),
                    ]),
                    _c("multi-select", {
                      attrs: {
                        options: _vm.sourceProfiles,
                        "append-to-body": false,
                        "value-key": "id",
                        "label-key": "name",
                      },
                      model: {
                        value: _vm.visibleSourceProfileIds,
                        callback: function ($$v) {
                          _vm.visibleSourceProfileIds = $$v
                        },
                        expression: "visibleSourceProfileIds",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "modal-action-group" }, [
        _c(
          "button",
          { staticClass: "bordered", on: { click: () => _vm.close() } },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.submitForm() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : [_vm._v(" " + _vm._s(_vm.tr("Invite")) + " ")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }