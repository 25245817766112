<template>
  <div class="protected-accounts-view list-view">
    <sorted-table
      v-if="!isListEmpty"
      :data="sourceProfileList"
    >
      <template #header>
        <table-head col="status" class="status" width="42px" />
        <table-head col="name" minWidth="150px"> {{ tr('Account') }} </table-head>
        <table-head col="actions" width="94px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="sourceProfile in sort" :key="sourceProfile.id">
          <table-data col="status" class="status">
            <switches
              v-model="sourceProfile.comment_moderation"
              :disabled="$root.loggedInUser.isRestrictedViewer"
              @click.native="() => onChangeModerationStatus(sourceProfile)"
            />
          </table-data>
          <table-data col="name" class="name">
            <router-link to="/moderation/post">
              <div class="name-with-icon" @click="() => setSourceProfileFilter(sourceProfile)">
                <i :class="'icon light-black ' + sourceProfile.platform" />
                <div class="name">{{ sourceProfile.name }}</div>
              </div>
            </router-link>
          </table-data>
          <table-data col="actions">
            <div class="action-group">
              <refresh-button
                v-if="!$root.loggedInUser.isRestrictedViewer"
                class="icon-wrapper"
                :label="tr('Update posts')"
                :success-message="tr('Posts of :name updated successfully.', { ':name': sourceProfile.name })"
                :promise="() => refreshSourceProfile(sourceProfile.id)"
                color-class="light-black"
                label-as-tooltip
              />

              <div
                v-if="!$root.loggedInUser.isRestrictedViewer"
                v-tooltip="{ content: tr('Settings'), classes: [ '' ] }"
                class="icon-wrapper"
                @click="() => openSourceProfileSettings(sourceProfile)"
              >
                <i class="icon gear light-black" />
              </div>
              <navigation-arrow :link="'/moderation/post'" @click="() => setSourceProfileFilter(sourceProfile)" />
            </div>
          </table-data>
        </table-row>
      </template>
    </sorted-table>

    <notification-card v-if="isListEmpty" empty-screen show-image-wrapper>
      {{ tr('No data sources are connected yet.') }}

      <router-link v-if="!$root.loggedInUser.isRestricted" class="button primary" to="/integrations">
        {{ tr('Connect Datasource') }}
      </router-link>
    </notification-card>
  </div>
</template>

<script>
import Switches from 'vue-switches';

import NavigationArrow from '@/components/NavigationArrow.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import RefreshButton from '@/components/RefreshButton.vue';
import SourceProfileModerationSettings from '@/components/SourceProfileModerationSettings.vue';
import SourceProfileService from '@/services/SourceProfile.js';

export default {
  name: 'ProtectedAccounts',
  components: {
    RefreshButton,
    NavigationArrow,
    NotificationCard,
    Switches,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    isListEmpty() {
      return this.sourceProfileList.length === 0;
    },
    sourceProfileList() {
      return this.$root.currentCompany.source_profiles;
    },
  },
  methods: {
    platformLabel(platform) {
      switch (platform) {
        case 'facebook':
          return this.tr('Facebook Page');

        case 'instagram':
          return this.tr('Instagram Account');
      }

      return '';
    },
    setSourceProfileFilter(sourceProfile) {
      this.$store.commit('protectedPosts/setSourceProfileId', sourceProfile.id);
    },
    onChangeModerationStatus(sourceProfile) {
      const { id } = sourceProfile;
      const newStatus = !sourceProfile.comment_moderation;

      const platformType = this.platformLabel(sourceProfile.platform);

      let title = '';
      let content = '';

      if (newStatus) {
        title = this.tr('Enable account-level moderation');
        content = this.tr(
          'With account-level moderation enabled, all comments posted on any content published in :name :platform, both currently available and future, will be automatically reviewed and moderated as needed.',
          { ':name': sourceProfile.name, ':platform': platformType },
        )
          + '<br/><br/>'
          + this.tr('If you choose this option, you can disable moderation at the post level for those posts where you don\'t want to apply automatic moderation.')
          + '<br/><br/>';
      }
      else {
        title = this.tr('Disabling account-level moderation');
        content = this.tr(
          'With account-level moderation disabled, comments posted on any content published in :name :platform, whether current or future, will not undergo automatic review.',
          { ':name': sourceProfile.name, ':platform': platformType },
        )
          + '<br/><br/>'
          + this.tr('If you choose this option, you can enable moderation at the post level for those posts where you want to apply automatic moderation.')
          + '<br/><br/>';
      }

      this.$confirmation.open({
        title,
        content,
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        cancel: () => sourceProfile.comment_moderation = !sourceProfile.comment_moderation,
        confirm: () => SourceProfileService.changeStatus(id, newStatus)
          .catch(err => {
            this.error(err);
            sourceProfile.comment_moderation = !newStatus;
          }),
      });
    },
    openSourceProfileSettings(sourceProfile) {
      let editing = null;
      if ('external_id' in sourceProfile) {
        editing = sourceProfile;
      }
      else {
        editing = this.getSourceProfile(sourceProfile.id);
      }

      const modalMaxHeight = this.$app.isBuzz ? 640 : 540;
      this.$modal.show(
        SourceProfileModerationSettings,
        { entity: editing },
        {  class: 'modal-without-padding modal-without-scroll modal-height--' + modalMaxHeight },
      );
    },
    refreshSourceProfile(id) {
      this.$toast.info(this.tr('We will start updating the posts. Be patient, this may take up to a minute.'));

      return SourceProfileService.refresh(id);
    },
  },
};
</script>
