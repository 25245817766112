<template>
  <div>
    <modal :name="name" @closed="onClosed">
      <div class="top">
        <i v-if="icon" :class="'icon ' + icon"></i>
        <div v-if="image">
          <img :class="'image ' + imageClass" :src="image"  />
        </div>

        <div v-if="title" class="title" v-html="title" />
        <div v-if="content" class="content" v-html="content" />
      </div>

      <div class="buttons">
        <button :class="'cancel-button ' + cancelClass" @click="actionCancel">
          {{ labelCancel }}
        </button>

        <button :class="'confirm-button ' + confirmClass" @click="actionConfirm">
          <i v-if="progress" class="icon loader white" />
          <template v-else>
            {{ labelConfirm }}
          </template>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    icon: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    image: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    imageClass: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    title: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    content: {
      type: String,
      required: true,
    },
    labelCancel: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    cancelClass: {
      type: String,
      required: false,
      default: 'clean',
    },
    labelConfirm: {
      type: String,
      required: false,
      default: 'OK',
    },
    confirmClass: {
      type: String,
      required: false,
      default: 'primary',
    },
    confirmOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirm: {
      type: [ Function, null ],
      required: false,
      default: null,
    },
    cancel: {
      type: [ Function, null ],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default() {
        return (Math.random() + 1).toString(36).substring(7);
      },
    },
    className: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      parentTop: null,
      progress: false,
    };
  },
  beforeMount() {
    this.setupContainer();
  },
  mounted() {
    this.parentTop.insertAdjacentElement('afterbegin', this.$el);
    this.$modal.show(this.name);
  },
  methods: {
    setupContainer() {
      this.parentTop = document.querySelector('.confirmation-modal');

      const isNewWrapper = !this.parentTop;
      if (isNewWrapper) {
        this.parentTop = document.createElement('div');
      }

      this.parentTop.className = 'confirmation-modal';
      if (this.className) {
        this.parentTop.classList.add(this.className);
      }

      if (isNewWrapper) {
        const container = document.body;
        container.appendChild(this.parentTop);
      }
    },
    close() {
      this.$modal.hide(this.name);
    },
    onClosed() {
      if (typeof this.cancel === 'function') {
        this.cancel();
      }

      this.$destroy();
      this.parentTop.innerHTML = '';
    },
    actionCancel() {
      if (this.progress) {
        return;
      }

      this.close();
    },
    actionConfirm() {
      if (this.progress) {
        return;
      }

      if (typeof this.confirm !== 'function') {
        this.close();
        return;
      }

      const resp = this.confirm();
      if (resp instanceof Promise) {
        this.progress = true;
        resp
          .then(() => this.close())
          .catch(() => {
            // eslint-disable-next-line no-console
            console.error('ConfirmationModal: Promise rejected');
          })
          .finally(() => this.progress = false);
      }
      else {
        this.close();
      }
    },
  },
};
</script>
