var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integrations-meta-view" },
    [
      _c(
        "sticky-header",
        {
          attrs: { title: _vm.tr("Meta Business") },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _vm.connectButtonVisible
                    ? _c(
                        "button",
                        {
                          class: _vm.connectProfileButtonClasses,
                          attrs: { disabled: _vm.isConnecting },
                          on: { click: () => _vm.connect(true) },
                        },
                        [
                          _vm.isConnecting
                            ? _c("i", { staticClass: "icon loader white" })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.tr("Connect Profile")) + " "),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("form-step-tab", { attrs: { items: _vm.tabs } })],
        1
      ),
      _c("router-view", {
        on: {
          connect: () => _vm.connect(true),
          reconnect: (profile) => _vm.reconnect(profile),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }