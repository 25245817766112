const translations = {
  'hu': require('@/translations/hu.json'),
  'en': require('@/translations/en.json'),
};

function replaceParameters(str, params, language) {
  if (params) {
    Object.keys(params).forEach(param => {
      str = str.replaceAll(param, params[param]);
    });

    if (language === 'hu') {
      if (str.indexOf('(z) ') !== -1) {
        str = str.replace(/\b([Aa])\((z)\) (\w)/g, (match, a, z, nextChar) => {
          const vowels = 'aeiouáéíóöőúüűAEIOUÁÉÍÓÖŐÚÜŰ';
          return vowels.includes(nextChar) ? `${a}z ${nextChar}` : `${a} ${nextChar}`;
        });
      }
    }
  }

  return str;
}

export const tr = (str, params, language) => {
  if (translations[language] && translations[language][str]) {
    return replaceParameters(translations[language][str], params, language);
  }

  if (language !== 'en' && process.env.NODE_ENV !== 'production') {
    // console.warn('Missing "' + str + '" ' + language + ' translation.');
  }

  if (translations['en'] && translations['en'][str]) {
    return replaceParameters(translations['en'][str], params, language);
  }

  return replaceParameters(str, params, language);
};

export default {
  methods: {
    tr(str, params) {
      return tr(str, params, this.$root.language);
    },
    translateOptionList(list, labelKey) {
      if (!labelKey) {
        labelKey = 'label';
      }

      const copy = JSON.parse(JSON.stringify(list));
      return copy.map(item => {
        item[labelKey] = this.tr(item[labelKey]);
        return item;
      });
    },
  },
};
