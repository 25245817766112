<template>
  <div class="google-2fa-form-component">
    <form-block
      :title="title"
      :description="tr('Please, enter you 6-digit authentication code from the Google Authenticator app.')"
    >
      <auth-code-input
        ref="authCodeInput"
        v-model="code"
        :disabled="isLoading"
        @enter="() => submit()"
      />

      <label v-if="trustOption" class="trust-checkbox">
        <input
          v-model="trust"
          type="checkbox"
          value="1"
        />
        {{ tr('Trust this device for 30 days') }}
      </label>
    </form-block>

    <div class="bottom-button">
      <button class="bordered" @click="() => cancel()">
        {{ tr(cancelButtonLabel) }}
      </button>

      <button
        :class="confirmButtonClass"
        :disabled="isLoading"
        @click="() => submit()"
      >
        <i v-if="isLoading" class="icon loader white" />
        {{ tr('Confirm') }}
      </button>
    </div>
  </div>
</template>

<script>
import AuthCodeInput from '@/components/AuthCodeInput.vue';
import FormBlock from '@/components/FormBlock';

export default {
  name: 'Google2faCodeForm',
  components: {
    AuthCodeInput,
    FormBlock,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    confirmButtonClass: {
      type: String,
      required: false,
      default: () => 'primary',
    },
    cancelButtonLabel: {
      type: String,
      required: false,
      default: () => 'Cancel',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    trustOption: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    code: '',
    trust: false,
  }),
  watch: {
    code() {
      this.$emit('input', this.code);
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs['authCodeInput'].focus());
  },
  methods: {
    submit() {
      if (this.value.length !== 6) {
        this.error('You must enter the 6-digit code from the Google Authenticator app.');
        return;
      }
      if (this.isLoading) {
        return;
      }

      this.$emit('submit', { trust: this.trust });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
