var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-2",
    },
    [
      _c("campaign-name-block", {
        attrs: { entity: _vm.entity, disabled: !_vm.entity.isNew },
      }),
      _c("div", { staticClass: "divider" }),
      _c("campaign-budget-block", {
        attrs: {
          title: _vm.tr("Budget"),
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          switchable: "",
        },
      }),
      _c("div", { staticClass: "divider" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }