<template>
  <div class="table-cell" :style="columnStyle">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TableData',
  inject: [ 'sortedTable' ],
  props: {
    col: { type: String, required: true },
  },
  data: () => ({
    isMounted: false,
  }),
  computed: {
    columnExists() {
      return this.col in this.sortedTable.columns;
    },
    columnStyle() {
      if (!this.isMounted || !this.columnExists) {
        return null;
      }

      return this.sortedTable.columns[this.col].columnStyle;
    },
  },
  mounted() {
    this.$nextTick(() => this.isMounted = true);
  },
};
</script>
