import SubscriptionPackage from '@/entities/SubscriptionPackage.js';
import api from '@/services/Api';

export default {
  getPackages() {
    return api().get('/subscription/packages')
      .then(resp => resp.map(i => new SubscriptionPackage(i)));
  },

  calculateCost({ packageId, extraAssets, featureManagedAccount }) {
    const data = { package_id: packageId };
    if (typeof extraAssets !== 'undefined') {
      data.extra_assets = parseInt(extraAssets);
      if (!(data.extra_assets >= 0)) {
        delete data.extra_assets;
      }
    }
    if (typeof featureManagedAccount !== 'undefined' && featureManagedAccount !== null) {
      data.feature_managed_account = featureManagedAccount ? 1 : 0;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/subscription/cost?' + query);
  },

  change({ packageId, extraAssets, featureManagedAccount }) {
    const data = { package_id: packageId };
    if (typeof extraAssets !== 'undefined') {
      data.extra_assets = parseInt(extraAssets);
      if (!(data.extra_assets >= 0)) {
        delete data.extra_assets;
      }
    }
    if (typeof featureManagedAccount !== 'undefined' && featureManagedAccount !== null) {
      data.feature_managed_account = featureManagedAccount ? 1 : 0;
    }

    return api().put('/subscription', data);
  },

  cancel() {
    return api().delete('/subscription');
  },

  currentUsage() {
    return api().get('/subscription/current-usage')
      .then(resp => {
        resp.billing_period_begin = new Date(resp.billing_period_begin);
        resp.billing_period_end = new Date(resp.billing_period_end);

        return resp;
      });
  },
};
