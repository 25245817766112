import BaseEntity from '@/entities/BaseEntity';

export default class AdvertisableApplication extends BaseEntity {
  id = null;
  name = '';
  google_play_url = null;
  app_store_url = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get hasGooglePlayApp() {
    return !!this.google_play_url;
  }

  get getAppStoreApp() {
    return !!this.app_store_url;
  }
}
