<template>
  <div ref="table" :class="wrapperClasses">
    <div class="table-header">
      <slot name="header" />
    </div>
    <div class="table-body">
      <slot :sort="sortedData" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortedTable',
  provide() {
    return {
      sortedTable: this,
    };
  },
  props: {
    initialSort: {
      type: Object,
      default: () => ({ key: '', direction: 'asc' }),
    },
    data: {
      type: Array,
      required: true,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resizeObserver: null,
      currentWidth: null,
      currentSort: this.initialSort,
      columns: {},
    };
  },
  computed: {
    wrapperClasses() {
      return {
        'sorted-table': true,
        'disable-scroll': this.disableScroll,
      };
    },
    sortedData() {
      if (!this.currentSort.key) {
        return this.data;
      }
      return [ ...this.data ].sort((a, b) => {
        const aVal = a[this.currentSort.key];
        const bVal = b[this.currentSort.key];
        if (aVal < bVal) {
          return this.currentSort.direction === 'asc' ? -1 : 1;
        }
        if (aVal > bVal) {
          return this.currentSort.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
  },
  mounted() {
    this.updateColumnWidth();
    this.currentWidth = this.$refs.table.offsetWidth;

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect && entry.contentRect.width !== this.currentWidth) {
          this.currentWidth = entry.contentRect.width;
          this.updateColumnWidth();
        }
      }
    });

    this.resizeObserver.observe(this.$refs.table);
  },
  beforeDestroy() {
    if (this.resizeObserver !== null) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  },
  methods: {
    handleSort(column) {
      if (this.currentSort.key === column) {
        this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
      }
      else {
        this.currentSort = { key: column, direction: 'asc' };
      }
      this.$emit('sort', this.currentSort);
    },
    registerColumn(columnHeader) {
      this.columns[columnHeader.col] = columnHeader;
    },
    unregisterColumn(columnHeader) {
      delete this.columns[columnHeader.col];
    },
    updateColumnWidth() {
      Object.values(this.columns).forEach(column => {
        if (column.width && column.width !== 'fit-content') {
          return;
        }

        column.setWidth('auto');
      });

      this.$nextTick(() => {
        Object.values(this.columns).forEach(column => {
          if (column.width && column.width !== 'fit-content') {
            return;
          }

          const width = column.$el.offsetWidth;

          column.setWidth(`${width}px`);
        });
      });
    },
  },
};
</script>
