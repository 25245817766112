<template>
  <div
    :class="wrapperClasses"
    @mouseenter="() => onHover()"
    @mouseleave="() => debouncedOnLeave()"
  >
    <div class="icon-wrapper">
      <i class="icon info primary" />
      <i class="icon info-filled primary" />
    </div>

    <div v-if="popupInDom" :class="popupClasses">
      <slot />
    </div>
  </div>
</template>

<script>
import debounce from '@/utils/debounce.js';

export default {
  name: 'InfoPopup',
  data() {
    return {
      active: false,
      popupInDom: false,
      hiddenPopup: false,
      debouncedOnLeave: debounce(() => this.onLeave(), 100),
      popupHideTimer: null,
    };
  },
  computed: {
    wrapperClasses() {
      return {
        'info-popup-component': true,
        'active': this.active,
      };
    },
    popupClasses() {
      return {
        'popup': true,
        'hidden': this.hiddenPopup,
      };
    },
  },
  methods: {
    onHover() {
      this.debouncedOnLeave.cancel();

      if (!this.active) {
        this.active = true;
        this.showPopup();
      }
    },
    onLeave() {
      if (!this.active) {
        return;
      }

      this.active = false;
      this.hidePopup();
    },
    showPopup() {
      if (this.popupHideTimer !== null) {
        clearTimeout(this.popupHideTimer);
        this.popupHideTimer = null;
      }

      this.hiddenPopup = true;
      this.popupInDom = true;

      setTimeout(() => this.hiddenPopup = false, 1);
    },
    hidePopup() {
      this.hiddenPopup = true;
      this.popupHideTimer = setTimeout(() => {
        this.popupInDom = false;
        this.popupHideTimer = null;
      }, 300);
    },
  },
};
</script>
