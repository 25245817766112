import NonCriticalError from '@/errors/NonCriticalError.js';

class PaymentRequiredError extends NonCriticalError {
  constructor(netPrice, grossPrice) {
    super('Payment required');
    this.netPrice = netPrice;
    this.grossPrice = grossPrice;
  }

  getNetPrice() {
    return this.netPrice;
  }

  getGrossPrice() {
    return this.grossPrice;
  }
}

export default PaymentRequiredError;
