<template>
  <div class="auth-page auth-view">
    <div class="content-wrapper">
      <div class="input-form-container">
        <h1>{{ tr('User identification') }}</h1>

        <div class="auth-form-view login-form-view">
          <form class="form" @submit="$event => onFormSubmit($event)">
            <div class="input-label">
              <span>{{ tr('User ID') }}</span>
            </div>
            <input
              v-model="userId"
              type="text"
              min="36"
              max="36"
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              autofocus
              :disabled="isLoading"
            />

            <button type="submit" class="primary">
              <i
                v-if="isLoading"
                class="icon loader white"
                :loading="isLoading"
                color="#ffffff"
                size="6px"
              />
              <template v-else> {{ tr('Login') }} </template>
            </button>
          </form>

          <div :title="tr('Log out')" class="logout-link" @click="() => logout()">
            <i class="i icon logout" />
            <span>{{ tr('Log out') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'SupportMasquerade',
  data() {
    return {
      isLoading: false,
      userId: '',
    };
  },
  created() {
    if (this.$root.isLoggedIn) {
      this.$store.dispatch('auth/logout');
    }

    const userId = this.$store.getters['support/autoLoginUserId'];
    if (userId) {
      this.userId = userId;
      this.onFormSubmit();
      this.$store.commit('support/setAutoLoginUserId', null);
    }
  },
  methods: {
    resetForm() {
      this.userId = '';
    },
    onFormSubmit($event) {
      if ($event) {
        $event.preventDefault();
      }

      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store.dispatch('support/masquerade', this.userId)
        .then(resp => {
          this.$store.commit('auth/setToken', resp.access_token);

          this.$root.fetchUserData();
          this.$router.replace({ path: '/dashboard' });

          this.$nextTick(() => this.resetForm());

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    validate() {
      this.$toast.clear();

      if (this.userId.length !== 36) {
        this.$toast.error(this.tr('Invalid User ID.'));
        return false;
      }

      return true;
    },
    logout() {
      this.$store.dispatch('support/logout');
    },
  },
};
</script>
