<template>
  <div class="report-ad-details-component">
    <div class="details">
      <div v-if="ad.post" class="post">
        <span class="label">{{ tr('Post source') }}:</span>
        {{ ucfirst(ad.post.platform) }}<br />

        <span class="label">{{ tr('Post ID') }}:</span>
        {{ ad.post.external_id }}

        <a
          v-if="ad.post.url"
          :href="ad.post.url"
          class="post-link"
          target="_blank"
        >
          {{ tr('Go to Post') }}
        </a>

        <router-link
          v-if="$root.isLoggedIn"
          :to="'/posts/' + ad.post.id"
        >
          {{ tr('Go to Post Analysis') }}
        </router-link>
      </div>

      <div v-if="ad.campaign">
        <span class="label">{{ tr('Campaign') }}:</span>
        {{ ad.campaign.name }}
      </div>

      <div v-if="ad.adset" class="placements">
        <div v-for="platform in ad.adset.targeting_publisher_platforms" :key="ad.external_id + '--placement--' + platform">
          <span class="label">{{ tr(':platform placements', { ':platform': ucfirst(platform) }) }}:</span>
          {{ getPlacements(ad, platform).join(', ') }}
        </div>
      </div>
    </div>

    <div class="preview">
      <v-select
        v-model="previewAdFormat"
        :options="adFormats"
        :reduce="(item) => item.machine_name"
        :clearable="false"
        :disabled="previewIsLoading"
      />

      <i v-if="previewIsLoading" class="icon loader black" />
      <div v-else-if="!previewIsLoaded" class="error">
        {{ tr('An error occurred while loading the preview.') }}
      </div>

      <div
        ref="iframeWrapper"
        class="preview-iframe-wrapper"
        :style="previewWrapperStyle"
        v-html="previewIframe"
      />
    </div>
  </div>
</template>

<script>
import AdEntity from '@/entities/Ad';
import ReportService from '@/services/Report';
import { ucfirst } from '@/utils/string';

export default {
  name: 'ReportAdDetails',
  props: {
    tokenPart1: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    tokenPart2: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ad: {
      type: AdEntity,
      required: true,
    },
    adAccountId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    previewAdFormat: 'DESKTOP_FEED_STANDARD',
    previewIsLoading: false,
    previewIsLoaded: false,
    previewIframe: null,
    previewWidth: null,
    previewHeight: null,
  }),
  computed: {
    adFormats() {
      return this.$store.getters['app/facebookAdFormats'];
    },
    previewWrapperStyle() {
      let width;
      let height;
      if (this.previewIsLoaded) {
        if (this.previewHeight) {
          width = (this.previewWidth * 0.65) + 'px';
          height = (this.previewHeight * 0.65) + 'px';
        }
        else {
          width = 'auto';
          height = 'auto';
        }
      }
      else {
        width = '0px';
        height = '0px';
      }

      return { width, height };
    },
  },
  watch: {
    previewAdFormat() {
      this.previewIsLoaded = false;
      this.previewWidth = null;
      this.previewHeight = null;
      this.generatePreview();
    },
  },
  created() {
    this.generatePreview();
  },
  methods: {
    ucfirst,
    generatePreview() {
      if (this.previewIsLoading) {
        return;
      }
      this.previewIsLoading = true;

      ReportService.getAdPreview({
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.adAccountId,
        adId: this.ad.id,
        adFormat: this.previewAdFormat,
      })
        .then(resp => {
          this.previewIframe = resp.iframe;
          this.previewWidth = resp.width;
          this.previewHeight = resp.height;

          this.$nextTick(() => this.initIframeOnLoadListener());

          return resp;
        })
        .catch(err => {
          this.previewIsLoading = false;
          this.error(err);
        });
    },
    initIframeOnLoadListener() {
      this.$refs['iframeWrapper'].querySelector('iframe').addEventListener('load', () => this.onIframeLoaded());
    },
    onIframeLoaded() {
      this.previewIsLoaded = true;
      this.previewIsLoading = false;
    },
    getPlacements(ad, platform) {
      return ad.adset['targeting_' + platform + '_positions'].map(placement => ucfirst(placement.replaceAll('_', ' ')));
    },
  },
};
</script>
