import { getObjectValue } from '@/utils/object.js';

export function conditionMatch(conditions, object) {
  const matchers = {
    inOrEquals(currentValue, expectedValue) {
      if (Array.isArray(expectedValue)) {
        if (
          !expectedValue.includes(currentValue)
          && JSON.stringify(expectedValue) !== JSON.stringify(currentValue)
        ) {
          return false;
        }
      }
      else {
        if (currentValue !== expectedValue) {
          return false;
        }
      }

      return true;
    },

    notInOrEquals(currentValue, expectedValue) {
      return !this.inOrEquals(currentValue, expectedValue);
    },
  };

  for (const key in conditions) {
    const value = getObjectValue(object, key);
    let expectedValue = conditions[key];

    let matcherName = 'inOrEquals';
    if (
      typeof expectedValue === 'object'
      && !Array.isArray(expectedValue)
      && expectedValue !== null
      && Object.keys(expectedValue).length === 1
    ) {
      const conditionKey = Object.keys(expectedValue)[0];
      switch (conditionKey) {
        case 'not':
          matcherName = 'notInOrEquals';
          expectedValue = expectedValue[conditionKey];
          break;
      }
    }

    if (!matchers[matcherName](value, expectedValue)) {
      return false;
    }
  }

  return true;
}
