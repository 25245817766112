import BaseEntity from '@/entities/BaseEntity';

export default class Adset extends BaseEntity {
  platform = '';
  external_id = '';
  name = '';
  budget = null;
  budget_period = null;
  deleted_at = null;
  status = 1;
  targeting_publisher_platforms = null;
  targeting_facebook_positions = null;
  targeting_instagram_positions = null;

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }
}
