<template>
  <div class="has-no-assets-modal-content-component">
    <top-image-content>
      <img src="~@/assets/img/sad.png" />
    </top-image-content>

    <div class="content">
      <div class="title">{{ tr('Ouch...') }}</div>
      <div class="textual">
        {{ textPart1 }}
        {{ textPart2 }}
      </div>

      <button class="primary" @click="() => $modal.hide('has-no-assets-modal')">
        {{ tr('Close') }}
      </button>
    </div>
  </div>
</template>

<script>
import TopImageContent from '@/components/TopImageContent.vue';

export default {
  name: 'HasNoAssetsModalContent',
  components: {
    TopImageContent,
  },
  props: {
    asset: {
      type: String,
      required: true,
    },
  },
  computed: {
    textPart1() {
      switch (this.asset) {
        case 'ad-account':
          return this.tr('The connected Facebook profile does not have an available advertising account.');

        case 'source-profile':
          return this.tr('The connected Facebook profile does not have an available Facebook Page.');
      }

      return '';
    },
    textPart2() {
      switch (process.env.VUE_APP_MODE) {
        case 'ad':
          return this.tr('In the absence of this, unfortunately, you won\'t be able to use the Infinite∞Ad.');

        case 'buzz':
          return this.tr('In the absence of this, unfortunately, you won\'t be able to use the Infinite∞Buzz.');
      }

      return '';
    },
  },
};
</script>
