<template>
  <div class="rule-preview">
    <div
      class="size-keeper"
      :style="'min-height: ' + sizeKeeperHeight + 'px'"
    />

    <div v-if="isLoading" class="loading-state">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
    <notification-card v-else-if="items.length === 0" empty-screen>
      {{ tr('No post corresponds to the current settings, and no ads have been created from them yet.') }}
    </notification-card>
    <div v-else ref="content" class="content">
      <div class="item-count-text">
        {{ itemsCountText }}
      </div>

      <sorted-table :data="items">
        <template #header>
          <table-head col="source" width="150px">{{ tr('Source') }}</table-head>
          <table-head col="post" min-width="300px">{{ tr('Post') }}</table-head>
          <table-head col="post_type" width="140px">{{ tr('Post type') }}</table-head>
          <table-head col="media_type" width="140px">{{ tr('Media type') }}</table-head>
          <table-head col="posted" width="140px">{{ tr('Posted') }}</table-head>
          <table-head col="info_column" width="50px" />
        </template>

        <template #default="{ sort }">
          <table-row v-for="post in sort" :key="post.id">
            <table-data col="source">
              <div class="platform">{{ ucfirst(post.source_profile.platform) }}</div>
              {{ post.source_profile.name }}
            </table-data>
            <table-data col="post">
              <small>
                {{ tr('Post ID') }}:
                <component
                  :is="post.url ? 'a' : 'span'"
                  :href="post.url"
                  :target="post.url ? '_blank' : ''"
                >
                  {{ post.external_id }}
                </component>
              </small><br />
              <div class="post-text">
                {{ post.shortText }}
              </div>
            </table-data>
            <table-data col="post_type">
              {{ post.type }}
            </table-data>
            <table-data col="media_type">
              {{ post.media_type }}
            </table-data>
            <table-data col="posted">
              <span v-tooltip="formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)">
                {{ timeAgo(post.created_at, tr) }}
              </span>
            </table-data>

            <table-data col="info_column">
              <div class="action-group">
                <i
                  v-if="isProhibitedContent(post)"
                  v-tooltip="prohibitedContentTooltip(post)"
                  class="icon alert light-black"
                />
              </div>
            </table-data>
          </table-row>
        </template>
      </sorted-table>
    </div>

    <div v-if="!isLoading && sourceProfileDetails.length !== 0" class="source-profile-informations-wrapper">
      <h3>{{ tr('Datasource informations') }}</h3>
      <div class="source-profile-informations">
        <div
          v-for="(sourceProfile, key) in sourceProfileDetails"
          :key="'source-profile-details--' + key"
          class="source-profile-informations--item"
        >
          <div class="source-profile-title">
            {{ sourceProfile.name }} - {{ ucfirst(sourceProfile.platform) }}

            <refresh-button
              :label="tr('Update posts')"
              :success-message="tr('Posts of :name updated successfully.', { ':name': sourceProfile.name })"
              :promise="() => refreshSourceProfile(sourceProfile.id)"
            />
          </div>

          <template v-if="sourceProfile.fetched_at">
            {{ tr('Data last updated at :time', { ':time': timeAgo(sourceProfile.fetched_at, tr) }) }}
          </template>
          <template v-else>
            {{ tr('The datasource has not been fetched yet.') }}
          </template>
          <br />

          {{ tr('Number of posts examined') }}:
          {{ tr('last :num pcs', { ':num': sourceProfile.post_limit }) }}
          <br />

          {{ tr('Latest known post date') }}:
          <template v-if="sourceProfile.latest_post_date">
            {{ formatToYMD(sourceProfile.latest_post_date) }}
            {{ formatTime(sourceProfile.latest_post_date) }}
          </template>
          <template v-else>
            -
          </template>
          <br />

          {{ tr('Oldest examined post date') }}:
          <template v-if="sourceProfile.oldest_post_date">
            {{ formatToYMD(sourceProfile.oldest_post_date) }}
            {{ formatTime(sourceProfile.oldest_post_date) }}
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationCard from '@/components/NotificationCard.vue';
import RefreshButton from '@/components/RefreshButton.vue';
import SourceProfileService from '@/services/SourceProfile.js';
import ToolService from '@/services/Tool.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';
import { ucfirst } from '@/utils/string';

export default {
  name: 'RulePreview',
  components: {
    NotificationCard,
    RefreshButton,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      prohibitedContents: [],
      sourceProfileDetails: [],
      debouncedGeneratePreview: debounce(() => this.generateNewPreview(), 1000),
      sizeKeeperHeight: 0,
    };
  },
  computed: {
    itemsCountText() {
      if (this.items.length < 2) {
        return this.tr(':num item', { ':num': this.items.length });
      }

      return this.tr(':num items', { ':num': this.items.length });
    },
  },
  watch: {
    'entity.source_profiles': {
      deep: true,
      handler() {
        this.debouncedGeneratePreview();
      },
    },
    'entity.facebook_post_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.facebook_post_media_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.instagram_post_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.instagram_post_media_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.output_campaign.objective'() {
      this.debouncedGeneratePreview();
    },
    'entity.conditions': {
      deep: true,
      handler() {
        this.debouncedGeneratePreview();
      },
    },
    'entity.settings.dont_create_expired_ads'() {
      this.debouncedGeneratePreview();
    },
  },
  mounted() {
    this.generateNewPreview();
  },
  methods:{
    formatToYMD,
    formatTime,
    timeAgo,
    ucfirst,
    generateNewPreview() {
      if (this.isLoading) {
        return;
      }

      const sourceProfileIds = this.entity.source_profiles.map(item => item.id).filter(item => !!item);

      if (
        sourceProfileIds.length === 0
        || (this.entity.hasSelectedFacebookPlatform && (this.entity.facebook_post_types.length === 0 || this.entity.facebook_post_media_types === 0))
        || (this.entity.hasSelectedInstagramPlatform && (this.entity.instagram_post_types.length === 0 || this.entity.instagram_post_media_types === 0))
        || !this.entity.isValidConditions
      ) {
        return;
      }

      if (this.$refs.content) {
        this.sizeKeeperHeight = this.$refs.content.offsetHeight;
      }

      this.isLoading = true;

      ToolService.generatePostBoostingRuleNextRunPreview({
        ruleId: this.entity.id,
        type: 'post_boosting',
        sourceProfileIds,
        facebookPostTypes: this.entity.facebook_post_types,
        facebookPostMediaTypes: this.entity.facebook_post_media_types,
        instagramPostTypes: this.entity.instagram_post_types,
        instagramPostMediaTypes: this.entity.instagram_post_media_types,
        action: this.entity.action,
        conditions: this.entity.conditions,
        dontCreateExpiredAds: this.entity.settings.dont_create_expired_ads,
        objective: this.entity.output_campaign.objective,
        ctaButton: this.entity.settings.cta_button,
        ctaUrl: this.entity.settings.cta_url,
        smartCtaUrl: this.entity.settings.smart_cta_url,
      })
        .then(resp => {
          this.items = resp.posts;
          this.prohibitedContents = resp.prohibited_contents;
          this.sourceProfileDetails = resp.source_profiles;

          return resp;
        })
        .catch(err => {
          this.error(err);
          this.items = [];
          this.prohibitedContents = [];
          this.sourceProfileDetails = [];
        })
        .finally(() => {
          this.isLoading = false;

          this.$nextTick(() => {
            this.sizeKeeperHeight = this.$refs.content ? this.$refs.content.offsetHeight : 0;
          });
        });
    },
    refreshSourceProfile(id) {
      this.$toast.info(this.tr('We will start updating the posts. Be patient, this may take up to a minute.'));

      return SourceProfileService.refresh(id)
        .then(resp => {
          this.generateNewPreview();
          return resp;
        });
    },
    isProhibitedContent(post) {
      const prohibitedPostIds = this.prohibitedContents.map(i => i.post_id);
      return prohibitedPostIds.includes(post.id);
    },
    prohibitedContentReason(post) {
      const item = this.prohibitedContents.find(i => i.post_id === post.id);
      return item.reason;
    },
    prohibitedContentTooltip(post) {
      const reason = this.prohibitedContentReason(post);

      return '<div class="tooltip-head-with-icon">'
        + '<i class="icon robot white"></i>'
        + '<span>' + this.tr('The Ai prevented the creation of the advertisement with the following reason') + ':</span>'
        + '</div>'
        + reason;
    },
  },
};
</script>
