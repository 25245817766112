<template>
  <div class="post-list-view list-view">
    <h3>{{ tr('Analyzed copies') }}</h3>
    <div class="description">{{ tr('Here you can see a list of your published contents and their performance. To set the specific data you want to see about your contents, press the \'Edit view\' button and select them from the list.') }}</div>
    <div v-if="!isListEmpty || !isEmptyFilters || isLoading" class="actions">
      <v-select
        v-model="sourceProfileId"
        :options="sourceProfileList"
        :reduce="(option) => option.id"
        :placeholder="tr('Datasource')"
        label="name"
        class="source-select"
      >
        <template #option="option">
          <span>
            <i v-if="option.platform" :class="'icon ' + option.platform" />
            {{ option.name }}
          </span>
        </template>
        <template #selected-option="option">
          <span>
            <i v-if="option.platform" :class="'icon ' + option.platform" />
            {{ option.name }}
          </span>
        </template>
      </v-select>
      <input
        v-model="searchTerm"
        type="text"
        class="text"
        :placeholder="tr('Search') + '...'"
      />
    </div>

    <template v-if="!isLoading && isListEmpty">
      <notification-card
        v-if="!isEmptyFilters"
        :image="require('@/assets/img/sad_new.png')"
        class="no-result"
        empty-screen
      >
        {{ tr('There are no results matching your search criteria.') }}
      </notification-card>
      <notification-card
        v-else
        :image="require('@/assets/img/new-rule-card/ai.png')"
        empty-screen
      >
        {{ tr('Analyze your Facbook and Instagram posts with AI.') }}

        <access-handled-router-link class="button primary" to="/integrations/meta/facebook-pages">
          + {{ tr('Connect platform') }}
        </access-handled-router-link>
      </notification-card>
    </template>

    <sorted-table
      :data="list"
      :initial-sort="{ key: orderBy, direction: order }"
      :style="{ display: !isLoading && isListEmpty ? 'none' : 'block' }"
      class="row-hover-shadow"
      @sort="sortTable"
    >
      <template #header>
        <table-head col="source" width="200px">{{ tr('Source') }}</table-head>
        <table-head
          v-for="(field, key) in visibleFields"
          :key="'field-th-' + key"
          :col="field"
          :sortable="availableFields.find(i => i.machine_name === field).sortable"
          :minWidth="field === 'text' ? '400px' : null"
          :width="field === 'text' ? null : 'fit-content'"
        >
          {{ tr(availableFields.find(i => i.machine_name === field).label) }}
        </table-head>
        <table-head col="actions" width="32px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.id">
          <table-data col="source" class="source">
            <div class="platform">{{ row.source_profile.platform }}</div>
            {{ row.source_profile.name }}
          </table-data>
          <table-data
            v-for="(field, key) in visibleFields"
            :key="'field-td-' + key + '-' + row.id"
            :col="field"
            :class="field"
          >
            <template v-if="field === 'text'">
              <router-link :to="'/posts/' + row.id">
                <small>
                  {{ tr('Post ID') }}: {{ row.external_id }}
                </small><br />
                <div class="post-text">
                  {{ row.shortText }}
                </div>
              </router-link>
            </template>

            <template v-else-if="field === 'ai_value'">
              <template v-if="row.ai_value">
                {{ row.ai_value }} / 10
              </template>
              <template v-else-if="row.ai_meta_in_progress">
                <i class="icon loader" />
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template v-else-if="field === 'created_at'">
              <span v-tooltip="formatToYMD(row.created_at) + ', ' + formatTime(row.created_at)">
                {{ timeAgo(row.created_at, tr) }}
              </span>
            </template>

            <template v-else-if="field === 'ai_content_actuality'">
              <skeleton v-if="row.contentActualityCheckInProgress" />
              <template v-else-if="row.ai_content_actuality">
                <span>{{ formatToYMD(row.ai_content_actuality) }}</span>
                <br>
                <span>{{ formatTime(row.ai_content_actuality) }}</span>
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template v-else>
              {{ formatNumber(row.metric[field.replace('metric.', '')], field === 'ctr' ? '%' : '') }}
            </template>
          </table-data>
          <table-data col="actions">
            <div class="action-group">
              <navigation-arrow :link="'/posts/' + row.id" />
              <button
                v-if="$root.supportHasL2Access"
                class="bordered small"
                @click="() => openCommunicatorDebug(row)"
              >
                {{ tr('Debug') }}
              </button>
            </div>
          </table-data>
        </table-row>

        <template v-if="isLoading || hasMore || true">
          <table-row
            v-for="i in (isListEmpty ? [ 1, 2, 3 ] : [ 1, 2 ])"
            :key="'posts-loader-skeleton--' + i"
            ref="loadMore"
          >
            <table-data col="source" class="source">
              <skeleton width="45px" height="10px" />
              <skeleton width="100px" />
            </table-data>
            <table-data
              v-for="(field, key) in visibleFields"
              :key="'field-td-' + key + '-loadmore-' + i"
              :col="field"
              :class="field"
            >
              <template v-if="field === 'text'">
                <skeleton max-width="120px" height="10px" />
                <skeleton max-width="400px" />
                <skeleton width="80%" />
              </template>

              <skeleton v-else-if="field === 'ai_value'" width="40px" />
              <skeleton v-else-if="field === 'created_at'" width="80px" />
              <skeleton v-else-if="field === 'ai_content_actuality'" width="80px" />
              <skeleton v-else width="40px" />
            </table-data>
            <table-data col="actions">
              <div class="action-group">
                <skeleton
                  width="80px"
                  height="26px"
                  radius="10px"
                />
              </div>
            </table-data>
          </table-row>
        </template>
      </template>
    </sorted-table>

    <modal name="visible-field-editor" class="visible-field-editor-modal">
      <div class="modal-title">
        {{ tr('Visible fields') }}
      </div>
      <div class="modal-content">
        <div class="checkbox-list vertical">
          <label v-for="(item, key) in availableFields" :key="'field-' + key">
            <input
              type="checkbox"
              name="visible-fields"
              :value="item.machine_name"
              :checked="tmpVisibleFields.includes(item.machine_name)"
              :disabled="key === 0"
              @click="() => toggleVisibleField(item.machine_name)"
            />
            {{ tr(item.label) }}
          </label>
        </div>
      </div>
      <div class="modal-action-group">
        <button class="primary" @click="() => applyChangedFields()">
          {{ tr('Apply') }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import CommunicatorDebug from '@/components/CommunicatorDebug.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import Skeleton from '@/components/Skeleton.vue';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';
import { formatNumber } from '@/utils/number.js';

export default {
  name: 'PostListView',
  components: {
    NavigationArrow,
    NotificationCard,
    Skeleton,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.name && !from.name.startsWith('Posts')) {
        vm.$store.dispatch('posts/resetView', true);
      }
    });
  },
  data() {
    return {
      debouncedLoadData: debounce(() => this.loadData(), 500),
      tmpVisibleFields: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['posts/isLoading'];
    },
    hasMore() {
      return this.$store.getters['posts/hasMore'];
    },
    sourceProfileId: {
      get() {
        return this.$store.getters['posts/sourceProfileId'];
      },
      set(value) {
        this.$store.commit('posts/setSourceProfileId', value);
      },
    },
    searchTerm: {
      get() {
        return this.$store.getters['posts/searchTerm'];
      },
      set(value) {
        this.$store.commit('posts/setSearchTerm', value);
      },
    },
    orderBy: {
      get() {
        return this.$store.getters['posts/orderBy'];
      },
      set(value) {
        this.$store.commit('posts/setOrderBy', value);
      },
    },
    order: {
      get() {
        return this.$store.getters['posts/order'];
      },
      set(value) {
        this.$store.commit('posts/setOrder', value);
      },
    },
    visibleFields() {
      return this.$store.getters['posts/visibleFields'];
    },
    list() {
      return this.$store.getters['posts/list'];
    },
    isListEmpty() {
      return this.list.length == 0;
    },
    sourceProfileList() {
      return this.$root.currentCompany.source_profiles;
    },
    isEmptyFilters() {
      return this.searchTerm.trim().length === 0 && !this.sourceProfileId;
    },
    availableFields() {
      let postMetricFields = this.$store.getters['app/postMetrics'];
      postMetricFields = JSON.parse(JSON.stringify(postMetricFields));
      postMetricFields.forEach(i => i.machine_name = 'metric.' + i.machine_name);

      const result = [
        { machine_name: 'text', label: 'Content' },
        { machine_name: 'ai_value', label: 'Rating', sortable: true },
        { machine_name: 'created_at', label: 'Posted', sortable: true },
        { machine_name: 'ai_content_actuality', label: 'Expiration date', sortable: true },
      ];

      return result.concat(postMetricFields);
    },
  },
  watch: {
    searchTerm(val) {
      if (!val || val.trim().length === 0) {
        this.loadData();
      }
      else {
        this.debouncedLoadData();
      }
    },
    sourceProfileId() {
      this.loadData();
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.loadData();
    }
    else {
      this.$nextTick(() => this.initInfiniteScroll());
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    formatNumber,
    timeAgo,
    loadData() {
      this.$store.dispatch('posts/resetView', true);
      this.loadMoreData();
    },
    loadMoreData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.$store.dispatch('posts/loadMoreData')
        .catch(err => this.error(err))
        .finally(() => {
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadMoreData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0].$el);
      }
    },
    sortTable({ key, direction }) {
      this.orderBy = key;
      this.order = direction;
      this.loadData();
    },
    openFieldsModal() {
      this.tmpVisibleFields = [ ...this.visibleFields ];
      this.$modal.show('visible-field-editor');
    },
    applyChangedFields() {
      this.tmpVisibleFields = this.tmpVisibleFields.sort((a, b) => {
        const machineNames = this.availableFields.map(i => i.machine_name);
        return machineNames.indexOf(a) - machineNames.indexOf(b);
      });

      this.$store.commit('posts/setVisibleFields', this.tmpVisibleFields);
      this.$modal.hide('visible-field-editor');
    },
    toggleVisibleField(field) {
      if (this.tmpVisibleFields.indexOf(field) === -1) {
        this.tmpVisibleFields.push(field);
      }
      else {
        this.tmpVisibleFields = this.tmpVisibleFields.filter(i => i !== field);
      }
    },
    linkOrSpan(field) {
      return this.availableFields.find(i => i.machine_name === field).sortable ? 'sort-link' : 'span';
    },
    isConnected(adAccount) {
      return this.selected.includes(adAccount.id);
    },
    openCommunicatorDebug(post) {
      this.$modal.show(
        CommunicatorDebug,
        { entity: post },
        { class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960' },
      );
    },
  },
};
</script>
