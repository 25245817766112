import ScalingUpRuleSettings from '@/descriptors/RuleSettings/ScalingUpRuleSettings.js';
import StartRuleSettings from '@/descriptors/RuleSettings/StartRuleSettings.js';
import StopRuleSettings from '@/descriptors/RuleSettings/StopRuleSettings.js';
import AdAccount from '@/entities/AdAccount.js';
import BaseEntity from '@/entities/BaseEntity.js';
import Campaign from '@/entities/Campaign.js';
import SourceProfile from '@/entities/SourceProfile.js';
import User from '@/entities/User.js';
import { appInstance } from '@/main.js';

export default class Rule extends BaseEntity {
  id = null;
  group_id = null;
  type = 'post_boosting';
  action = 'start';
  name = '';
  active = false;
  conditions = {};
  connected_rules = [];

  notifiable_users = [];
  activity_management = false;
  activity_management_auto_handling = null;
  activity_management_auto_handling_after = null;
  pending_items_count_in_activity_management = null;

  ad_account = new AdAccount();
  has_output_campaign = false;
  output_campaign = null;
  input_campaigns = [];
  campaign_status = null;
  conversion_event = null;
  source_profiles = [];

  facebook_post_types = [];
  facebook_post_media_types = [];
  instagram_post_types = [];
  instagram_post_media_types = [];
  settings = {};

  dsa_payer = '';
  dsa_beneficiary = '';

  active_ads = null;
  triggered = 0;
  last_activity = null;
  last_run = null;
  next_run = null;
  mins_between_runs = 15;
  date_from = null;
  date_to = null;
  daily_schedule = null;
  created_at = new Date();
  updated_at = null;
  deleted_at = null;

  _entityFields = {
    ad_account: AdAccount,
    output_campaign: Campaign,
  };

  _entityArrayFields = {
    connected_rules: Rule,
    notifiable_users: User,
    source_profiles: SourceProfile,
    input_campaigns: Campaign,
  };

  _input_platforms = [];

  constructor(rawData) {
    super();

    if (rawData) {
      switch (rawData.action) {
        case 'start':
          this.type = 'post_boosting';
          this.output_campaign = new Campaign();
          this.settings = new StartRuleSettings();
          break;

        case 'scaling_up':
          this.type = 'simple';
          this.output_campaign = new Campaign();
          this.settings = new ScalingUpRuleSettings();
          break;

        case 'stop':
          this.type = 'simple';
          this.settings = new StopRuleSettings();
          break;
      }
      if (rawData?.deleted_at) {
        rawData.name = `[Deleted] ${rawData.name}`;
      }
    }

    this.updateWith(rawData);
  }

  updateWith(newValues) {
    if (newValues.action === 'smart_stop') {
      newValues.action = 'stop';
    }

    if (!super.updateWith(newValues)) {
      return;
    }

    for (const [ key, value ] of Object.entries(newValues)) {
      if (!(key in this)) {
        continue;
      }

      if (key === 'settings' && Object.keys(this.settings).length !== 0) {
        if (this.type === 'post_boosting') {
          this[key] = new StartRuleSettings(value);
        }
        else if (this.type === 'simple' && this.action === 'scaling_up') {
          this[key] = new ScalingUpRuleSettings(value);
        }
      }
      else if (
        (key === 'last_activity' || key === 'last_run' || key === 'next_run' || key === 'date_from' || key === 'date_to')
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }

  toObject() {
    const data = super.toObject();

    for (let key in data) {
      let value = data[key];

      if (key === 'ad_account') {
        delete data[key];
        key = 'ad_account_id';
        if (value) {
          value = value.id;
        }
      }
      /* else if (key === 'output_campaign') {
        delete data[key];
        key = 'output_campaign_id';
        if (value) {
          value = value.id;
        }
      } */
      else if (key === 'input_campaigns') {
        delete data[key];
        key = 'input_campaign_ids';
        if (value && Array.isArray(value) && value.length !== 0) {
          value = value.map(item => item.id).filter(item => !!item);
        }
      }
      else if (key === 'notifiable_users') {
        delete data[key];
        key = 'notifiable_user_ids';
        if (value && Array.isArray(value) && value.length !== 0) {
          value = value.map(item => item.id).filter(item => !!item);
        }
      }
      else if (key === 'source_profiles') {
        delete data[key];
        key = 'source_profile_ids';
        if (value && Array.isArray(value) && value.length !== 0) {
          value = value.map(item => item.id).filter(item => !!item);
        }
      }

      data[key] = value;
    }

    if ('pending_items_count_in_activity_management' in data) {
      delete data.pending_items_count_in_activity_management;
    }

    return data;
  }

  hasSelectedPlatform(platform) {
    for (let i = 0; i < this.source_profiles.length; i++) {
      if (platform == this.source_profiles[i].platform) {
        return true;
      }
    }
    return false;
  }

  resetInputPlatforms() {
    this._input_platforms = [];
  }

  hasSelectedInputPlatform(platform) {
    return this._input_platforms.includes(platform);
  }

  addInputPlatform(platform) {
    if (!this.hasSelectedInputPlatform(platform)) {
      this._input_platforms.push(platform);
    }
  }

  removeInputPlatform(platform) {
    if (this.hasSelectedInputPlatform(platform)) {
      this._input_platforms = this._input_platforms.filter(item => item !== platform);
    }
  }

  get firstInputCampaign() {
    return Array.isArray(this.input_campaigns) && this.input_campaigns.length !== 0 ? this.input_campaigns[0] : null;
  }

  get hasSelectedFacebookPlatform() {
    return this.hasSelectedPlatform('facebook') || this.hasSelectedInputPlatform('facebook');
  }

  get hasSelectedInstagramPlatform() {
    return this.hasSelectedPlatform('instagram') || this.hasSelectedInputPlatform('instagram');
  }

  get hasOutputCampaign() {
    return this.action === 'start' || this.action === 'scaling_up';
  }

  get isPostBoosting() {
    return this.type === 'post_boosting';
  }

  get isScaling() {
    return this.type === 'simple' && this.action === 'scaling_up';
  }

  get isValidConditions() {
    return this.isValidConditionGroup(this.conditions);
  }

  get isEmptyConditions() {
    if (Object.keys(this.conditions).length === 0) {
      return true;
    }

    if (this.conditions.items.length === 0) {
      return true;
    }
    if (this.conditions.items.length === 1) {
      return !this.conditions.items[0].field;
    }

    return false;
  }

  // For simplicity, we check groups and items separately
  isValidConditionGroup(group) {
    // If we don't get an object, it's immediately bad.
    if (typeof group !== 'object') {
      return false;
    }

    // If the group doesn't have a logic value, or it's not AND/OR, it's also bad.
    if (!group.logic || (group.logic !== 'AND' && group.logic !== 'OR')) {
      return false;
    }

    // If there are no items, it's also bad.
    if (
      !group.items ||
      !Array.isArray(group.items) ||
      group.items.length === 0
    ) {
      return false;
    }

    // Checking items.
    for (const item of group.items) {
      // If the item within the group is actually a group...
      if ('logic' in item && 'items' in item) {
        if (!this.isValidConditionGroup(item)) {
          return false;
        }
      }
      else {
        if (!this.isValidConditionItem(item)) {
          return false;
        }
      }
    }

    return true;
  }

  isValidConditionItem(item) {
    // If we don't get an object, it's immediately bad.
    if (typeof item !== 'object') {
      return false;
    }

    // If field, comparison, or value are missing, it's bad.
    // There's a bit of magic in the last condition to accept 0 as a valid value.
    if (
      !item.field ||
      !item.comparison ||
      !('value' in item && item.value.toString().length !== 0)
    ) {
      return false;
    }

    return true;
  }

  get campaignName() {
    if (!this.name.trim()) {
      return '-';
    }

    let name = '';
    if (this.settings && 'campaign_name_prefix' in this.settings) {
      name = this.settings.campaign_name_prefix + ' ';
    }
    name += this.name;
    name = name.trim();

    return name;
  }

  get nextRunDate() {
    if (!this.active) {
      return null;
    }

    if (this.last_run !== null) {
      while (this.next_run < new Date()) {
        this.next_run = new Date(new Date(this.last_run).getTime() + this.mins_between_runs * 60000);
      }
    }

    return this.next_run;
  }

  get nextRunInSeconds() {
    const { nextRunDate } = this;
    if (nextRunDate === null) {
      return null;
    }

    const diffTime = (new Date(nextRunDate).getTime() - new Date().getTime()) / 1000;
    return diffTime < 0 ? 0 : diffTime;
  }

  get hasSelectedAdAccount() {
    return this.ad_account && this.ad_account.id;
  }

  get selectedAdAccount() {
    if (!this.hasSelectedAdAccount) {
      return null;
    }

    const company = appInstance.currentCompany;
    const adAccount = company.ad_accounts.find(i => i.id === this.ad_account.id);

    return adAccount;
  }

  get pixelList() {
    if (!this.selectedAdAccount) {
      return [];
    }

    return this.selectedAdAccount.pixels;
  }

  get pixelListForSelect() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return this.pixelList
      .map(item => ({
        status: item.last_fired_time > yesterday,
        label: item.name + ' (' + item.id + ')',
        value: item.id,
      }))
      .sort((a, b) => {
        // First, sort based on the 'status' property
        if (a.status === b.status) {
          // If both are either true or false, sort by 'label' in alphabetical order
          return a.label.localeCompare(b.label);
        }

        // Place 'status: true' items before 'status: false' items
        return a.status ? -1 : 1;
      });
  }

  get appList() {
    if (!this.selectedAdAccount) {
      return [];
    }

    return this.selectedAdAccount.advertisable_applications
      .filter(i => i.hasGooglePlayApp || i.getAppStoreApp)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get selectedApp() {
    if (!this.settings.adset_conversion_app_id) {
      return null;
    }

    return this.appList.find(i => i.id === this.settings.adset_conversion_app_id);
  }

  get disconnectedAdAccount() {
    return this.id && !this.selectedAdAccount;
  }

  get adAccountCurrency() {
    if (!this.selectedAdAccount) {
      return '';
    }

    return appInstance.$store.getters['app/currencies'].find(i => i.code === this.selectedAdAccount.currency);
  }

  get adAccountMinDailyBugdet() {
    if (!this.selectedAdAccount) {
      return 0;
    }

    return this.selectedAdAccount.min_daily_budget / this.adAccountCurrency.offset;
  }

  get isCostPerResultGoalStrategy() {
    if (!this.output_campaign) {
      return false;
    }

    return this.output_campaign.bid_strategy === 'COST_CAP'
      || this.settings.adset_bid_strategy === 'COST_CAP';
  }

  get isBidCapStrategy() {
    if (!this.output_campaign) {
      return false;
    }

    return this.output_campaign.bid_strategy === 'LOWEST_COST_WITH_BID_CAP'
      || this.settings.adset_bid_strategy === 'LOWEST_COST_WITH_BID_CAP'
      || this.settings.adset_optimization_goal === 'IMPRESSIONS';
  }

  get isRoasGoalCampaignStrategy() {
    if (!this.output_campaign) {
      return false;
    }

    return this.output_campaign.bid_strategy === 'LOWEST_COST_WITH_MIN_ROAS';
  }

  get isRoasGoalAdsetOptimization() {
    return this.settings.adset_optimization_goal === 'VALUE';
  }

  get isRoasGoalAvailable() {
    if (!this.output_campaign) {
      return false;
    }

    return this.isRoasGoalCampaignStrategy
      || (!this.output_campaign.budget && this.isRoasGoalAdsetOptimization);
  }

  get bidAmountIsRequired() {
    return this.isCostPerResultGoalStrategy || this.isBidCapStrategy;
  }

  get bidAmountIsAvailable() {
    if (!this.output_campaign) {
      return false;
    }

    return (!this.output_campaign.budget || this.bidAmountIsRequired) && !this.isRoasGoalAvailable;
  }

  get isSalesCampaign() {
    if (!this.output_campaign) {
      return false;
    }

    return this.output_campaign.objective === 'OUTCOME_SALES';
  }

  get isAppPromo() {
    if (!this.output_campaign) {
      return false;
    }

    return this.output_campaign.objective === 'OUTCOME_APP_PROMOTION';
  }

  get selectedSourcePlatforms() {
    const result = [];

    if (this.hasSelectedFacebookPlatform) {
      result.push('facebook');
    }
    if (this.hasSelectedInstagramPlatform) {
      result.push('instagram');
    }

    return result;
  }

  get disablePostMetrics() {
    for (const sourceProfile of this.source_profiles) {
      if (sourceProfile.unavailable_insights) {
        return true;
      }
    }

    return false;
  }
}
