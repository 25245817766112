var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-profile-moderation-settings-component" },
    [
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Moderation settings of :name :platformType", {
              ":name": _vm.entity.name,
              ":platformType": _vm.platformType,
            }),
            description: _vm.tr(
              "The comments that reach the set risk rating after the AI evaluation are automatically subject to moderation."
            ),
          },
        },
        [
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Sensitivity"))),
            ]),
            _c("div", { staticClass: "sensitivity-select" }, [
              _c(
                "div",
                {
                  staticClass: "sensitivity-value",
                  style: _vm.sensitivityValueStyle,
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.model.comment_moderation_sensitivity) + " "
                  ),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.comment_moderation_sensitivity,
                    expression: "model.comment_moderation_sensitivity",
                  },
                ],
                attrs: {
                  type: "range",
                  min: "1",
                  max: "5",
                  step: "1",
                  disabled: !_vm.canModifySenstivity,
                },
                domProps: { value: _vm.model.comment_moderation_sensitivity },
                on: {
                  __r: function ($event) {
                    return _vm.$set(
                      _vm.model,
                      "comment_moderation_sensitivity",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          !_vm.canModifySenstivity
            ? _c("info-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "To set the sensitivity of moderation, switch to a subscription plan that includes this feature."
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Sensitivity levels"))),
            ]),
            _c("div", { staticClass: "levels" }, [
              _c(
                "div",
                {
                  class:
                    "level " +
                    (_vm.model.comment_moderation_sensitivity == 1
                      ? "active"
                      : ""),
                },
                [
                  _c("div", { staticClass: "emoji" }, [_vm._v(" 😠 ")]),
                  _c("div", { staticClass: "number" }, [_vm._v(" 1) ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("Low risk")) +
                      ": " +
                      _vm._s(
                        _vm.tr(
                          "Expresses dissatisfaction in a polite manner, showing some resentment in opinion."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "level " +
                    (_vm.model.comment_moderation_sensitivity == 2
                      ? "active"
                      : ""),
                },
                [
                  _c("div", { staticClass: "emoji" }, [_vm._v(" 😤 ")]),
                  _c("div", { staticClass: "number" }, [_vm._v(" 2) ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("Medium risk")) +
                      ": " +
                      _vm._s(
                        _vm.tr(
                          "Expresses dissatisfaction in a slightly impolite manner, showing some resentment in opinion."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "level " +
                    (_vm.model.comment_moderation_sensitivity == 3
                      ? "active"
                      : ""),
                },
                [
                  _c("div", { staticClass: "emoji" }, [_vm._v(" 😡 ")]),
                  _c("div", { staticClass: "number" }, [_vm._v(" 3) ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("High risk")) +
                      ": " +
                      _vm._s(
                        _vm.tr(
                          "Accuses of lying or uses improper or vulgar language, indicating some use of offensive language."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "level " +
                    (_vm.model.comment_moderation_sensitivity == 4
                      ? "active"
                      : ""),
                },
                [
                  _c("div", { staticClass: "emoji" }, [_vm._v(" 🤬 ")]),
                  _c("div", { staticClass: "number" }, [_vm._v(" 4) ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("Very high risk")) +
                      ": " +
                      _vm._s(
                        _vm.tr(
                          "Accuses of lying or uses improper or vulgar language, indicating a significant use of offensive language."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "level " +
                    (_vm.model.comment_moderation_sensitivity == 5
                      ? "active"
                      : ""),
                },
                [
                  _c("div", { staticClass: "emoji" }, [_vm._v(" ⚠️ ")]),
                  _c("div", { staticClass: "number" }, [_vm._v(" 5) ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("Dangerous")) +
                      ": " +
                      _vm._s(
                        _vm.tr(
                          "Contains words indicating abuse, or uses vulgar language, involves online fraud, or false information. It can be highly offensive, especially related to race or sexual orientation."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "modal-action-group" }, [
        _c(
          "button",
          {
            staticClass: "bordered",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.$emit("close") },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.save() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.tr("Save")) + " "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }