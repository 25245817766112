var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-profile-settings-component" },
    [
      _c("form-block", { attrs: { title: _vm.title } }, [
        _c("div", [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Website"))),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.website,
                expression: "model.website",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.model.website },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "website", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Target audience"),
            description: _vm.tr(
              "If you cannot define an option exactly, leave it unchanged."
            ),
          },
        },
        [
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Gender"))),
              ]),
              _c("gender-selector", {
                model: {
                  value: _vm.model.targeting.genders,
                  callback: function ($$v) {
                    _vm.$set(_vm.model.targeting, "genders", $$v)
                  },
                  expression: "model.targeting.genders",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Age"))),
            ]),
            _c(
              "div",
              { staticClass: "age-block" },
              [
                _c("age-selector", {
                  model: {
                    value: _vm.model.targeting.age_min,
                    callback: function ($$v) {
                      _vm.$set(_vm.model.targeting, "age_min", $$v)
                    },
                    expression: "model.targeting.age_min",
                  },
                }),
                _vm._v(" - "),
                _c("age-selector", {
                  model: {
                    value: _vm.model.targeting.age_max,
                    callback: function ($$v) {
                      _vm.$set(_vm.model.targeting, "age_max", $$v)
                    },
                    expression: "model.targeting.age_max",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Geo locations"))),
              ]),
              _c("geolocation-target-selector", {
                ref: "geolocationTargetSelector",
                model: {
                  value: _vm.model.targeting,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "targeting", $$v)
                  },
                  expression: "model.targeting",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Language"))),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.languages,
                  reduce: (obj) => obj.id,
                  placeholder: _vm.tr("Choose at least one language..."),
                  label: "name",
                  multiple: "",
                },
                model: {
                  value: _vm.model.targeting.locales,
                  callback: function ($$v) {
                    _vm.$set(_vm.model.targeting, "locales", $$v)
                  },
                  expression: "model.targeting.locales",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "modal-action-group" }, [
        _c(
          "button",
          {
            staticClass: "bordered",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.$emit("close") },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.save() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.tr("Save")) + " "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }