<template>
  <div class="money-input-component" :symbol-length="symbol.length">
    <number-input
      v-model="insideValue"
      :disabled="disabled"
      :min="min"
      :step="step"
    />
    <div class="prefix">{{ symbol }}</div>
  </div>
</template>

<script>
import NumberInput from '@/components/NumberInput.vue';

export default {
  name: 'MoneyInput',
  components: {
    NumberInput,
  },
  props: {
    value: {
      type: [ Number, String, null ],
      required: false,
      default: () => null,
    },
    currency: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    insideValue: '',
  }),
  computed: {
    currencyDetails() {
      return this.$store.getters['app/currencies'].find(i => i.code === this.currency);
    },
    symbol() {
      return this.currencyDetails ? this.currencyDetails.symbol : '';
    },
    step() {
      return this.currencyDetails ? (1 / this.currencyDetails.offset) : 1;
    },
  },
  watch: {
    insideValue() {
      this.$emit('input', this.insideValue);
    },
    value: {
      immediate: true,
      handler() {
        this.insideValue = this.value;
      },
    },
  },
};
</script>
