<template>
  <div class="blog-box">
    <div class="title">{{ tr('Latest post from Infinite∞Blog') }}</div>

    <div v-if="isLoading" class="loading">
      <i class="icon loader primary" />
    </div>
    <div v-else-if="posts.length === 0" class="empty-text">
      {{ tr('There are no items available.') }}
    </div>

    <div v-for="post in posts" :key="post.id" class="post">
      <a :href="post.link" target="_blank" class="post-title">{{ post.title.rendered }} →</a>
      <div class="post-date">{{ formatToStringYMD(new Date(post.date_gmt), tr) }}</div>
    </div>

    <a href="https://8ad.io/blog/" target="_blank" class="button primary">
      {{ tr('Read more from Infinite∞Blog') }}
    </a>
  </div>
</template>

<script>
import BlogService from '@/services/Blog.js';
import { formatToStringYMD } from '@/utils/date';

export default {
  name: 'BlogBox',
  data: () => ({
    isLoading: true,
    posts: [],
  }),
  created() {
    BlogService.getLatests()
      .then(resp => this.posts = resp.data)
      .catch(() => this.posts = [])
      .finally(() => this.isLoading = false);
  },
  methods: {
    formatToStringYMD,
  },
};
</script>
