<template>
  <form-block
    class="rule-form-block adset-name-block-component"
    :title="tr('Ad set name')"
    :description="tr('This will be the name of the currently created adset. If you are not sure what should be the name of your adset, you can also add different macros that will automatically be filled according to your current ad series settings.')"
  >
    <div>
      <label class="input-label">{{ tr('Name prefix') }}</label>
      <input v-model="entity.settings.name_prefix" type="text" placeholder="[Infinite.ad]" />
    </div>

    <div>
      <label class="input-label">{{ tr('Ad set name') }}</label>
      <templateable-input
        v-model="entity.settings.adset_name"
        :templateParts="adsetNameMacros"
        :placeholder="tr('Set the ad set name')"
        :disabled="disabled"
      />
    </div>

    <div class="preview">
      <span>
        <label>{{ tr('Preview') }}:</label> {{ preview }}
      </span>
      <i v-if="previewIsLoading" class="icon loader" />
    </div>
  </form-block>
</template>

<script>
import TemplateableInput from '@/components/TemplateableInput.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import ToolService from '@/services/Tool.js';
import debounce from '@/utils/debounce.js';

export default {
  name: 'AdsetNameBlock',
  components: {
    TemplateableInput,
  },
  mixins: [ RuleFormBlockMixin ],
  data() {
    return {
      preview: '-',
      previewIsLoading: false,
      previewGenerator: debounce(() => this.generatePreview(), 500),
    };
  },
  computed: {
    adsetNameMacros() {
      const result = [];
      let previousGroup = '';
      for (const item of this.$store.getters['app/adsetNameMacros']) {
        if (item.group !== previousGroup) {
          result.push({ title: this.tr(item.group) });
          previousGroup = item.group;
        }
        result.push({ label: this.tr(item.label), value: item.machine_name });
      }
      return result;
    },
  },
  watch: {
    'entity.settings.name_prefix'() {
      this.previewGenerator();
    },
    'entity.settings.adset_name'() {
      this.previewGenerator();
    },
  },
  created() {
    this.generatePreview();
  },
  methods: {
    validate() {
      if (!this.entity.settings.adset_name.trim()) {
        this.error(this.tr('The {field} field cannot be empty!', { '{field}': this.tr('Ad set') }));
        return false;
      }

      return true;
    },
    generatePreview() {
      if (this.previewIsLoading) {
        return;
      }

      this.previewIsLoading = true;

      ToolService.getExampleAdsetName({
        prefix: this.entity.settings.name_prefix,
        template: this.entity.settings.adset_name,
        sourceProfileId: Array.isArray(this.entity.source_profiles) && this.entity.source_profiles.length !== 0 ? this.entity.source_profiles[0].id : null,
        adAccountId: this.entity.ad_account.id,
        campaign: this.entity.output_campaign.toObject(),
      })
        .then(resp => {
          if (resp) {
            this.preview = resp;
          }
          else {
            this.preview = '-';
          }

          return resp;
        })
        .catch(() => this.preview = '-')
        .finally(() => this.previewIsLoading = false);
    },
  },
};
</script>
