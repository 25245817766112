import Ad from '@/entities/Ad';
import BaseEntity from '@/entities/BaseEntity';
import Post from '@/entities/Post';
import Rule from '@/entities/Rule';

export default class RuleActivityLog extends BaseEntity {
  id = null;
  rule = null;
  action = '';
  ad = null;
  post = null;
  created_ad = null;
  text = '';
  created_at = new Date();

  _entityFields = {
    rule: Rule,
    ad: Ad,
    post: Post,
    created_ad: Ad,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
