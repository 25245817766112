<template>
  <div class="active-boosts-card-component">
    <template v-if="loading">
      <div>
        <skeleton class="title" width="100px" height="15px" />
        <skeleton class="time" width="80px" height="12px" />
      </div>
      <div>
        <skeleton class="number" width="60px" height="45px" />
        <skeleton class="number-text" width="100px" height="12px" />
      </div>
      <skeleton width="130px" height="15px" />
    </template>

    <template v-else>
      <div class="last-activity">
        <div class="title">
          {{ loading || lastActivity ? tr('Last activity') : tr('No activity') }}
          <i :class="'icon ' + icon" />
        </div>

        <div v-if="lastActivity" class="time">
          {{ timeAgo(lastActivity, tr) }}
        </div>
      </div>

      <div class="counter">
        <div class="number">{{ number }}</div>
        {{ numberText }}
      </div>

      <router-link :to="link">{{ linkText }} →</router-link>
    </template>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue';
import { timeAgo } from '@/utils/date';

export default {
  name: 'ActiveBoostsCard',
  components: {
    Skeleton,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    numberText: {
      type: String,
      required: true,
    },
    lastActivity: {
      type: [ Date, null ],
      required: false,
      default: () => null,
    },
    link: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    timeAgo,
  },
};
</script>
