<template>
  <div ref="wrapper" class="onboarding-component onboarding--ad" :style="wrapperStyle">
    <div class="content">
      <i
        class="icon close"
        :style="{'background-color': iconBackgroundColor}"
        @click="close()"
      />
      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 0" class="step step--0 step--welcome">
          <top-image-content>
            <img src="~@/assets/img/onboarding/step-welcome.png" />
          </top-image-content>

          <div
            class="title large"
            v-html="tr('Welcome to the <span class=\'primary\'>Infinite∞Ad</span> automatic ad management system!')"
          />

          <div class="textual">
            <p>{{ tr('We are glad that you are with us in the world of endless ads.') }} {{ tr('In the following, we will help you use the system in three simple steps.') }} {{ tr('Don\'t worry, we will guide you step by step so that you can set up your first automations as easily as possible.') }}</p>
          </div>

          <div class="bottom-button">
            <button class="primary" @click="() => next()">{{ tr('Let start it!') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 1 && !skipFacebookProfile" class="step step--1 step--profile">
          <top-image-content>
            <img src="~@/assets/img/onboarding/step-profile.png" />
          </top-image-content>

          <div class="title">{{ tr('Connect Facebook Profile') }}</div>

          <div class="textual">
            <p>
              {{ tr('Okay, first, let\'s connect your Facebook profile. This is how we will be able to access your assets.') }}
              {{ tr('Don\'t worry, data protection is also of utmost importance to us here.') }}
            </p>

            <p>
              {{ tr('Click the \"Connect Profile\" button below and follow the instructions.') }}
            </p>
            <p>
              {{ tr('With this step, we are already on our way to more effective advertising!') }}
            </p>
          </div>

          <div class="bottom-button">
            <button
              :class="connectProfileButtonClasses"
              :disabled="isConnecting"
              @click="() => connect()"
            >
              <i v-if="isConnecting" class="icon loader white" />
              {{ tr('Connect Profile') }}
            </button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 2" class="step step--2 step--ad-account">
          <div class="textual">
            <p v-html="tr('Great, now connect at least one Facebook ad account to manage your ads with <b class=\'primary\'>Infinite∞Ad</b>.')"></p>
            <p>{{ tr('Use the checkboxes to select the advertising accounts you want to manage, then go to the last step by pressing the "Next" button below.') }}</p>
          </div>

          <div class="scroll-wrapper">
            <integrations-meta-ad-accounts-view
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>

          <div class="bottom-button space-between">
            <button class="bordered" :disabled="disabledButtons" @click="() => back()">{{ tr('Back') }}</button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Next') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 3" class="step step--3 step--source-profile">
          <div class="title small">{{ tr('Congratulations, you are very close to creating effective ads!') }}</div>

          <div class="textual">
            <p v-html="tr('In the next step, use the checkboxes to connect the <b>Facebook Page</b> data sources from which you want to use the posts as raw material for ads.')" />
          </div>

          <div class="scroll-wrapper">
            <integrations-meta-facebook-items-view
              platform-from-prop="facebook"
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>

          <div class="bottom-button space-between">
            <button class="bordered" :disabled="disabledButtons" @click="() => back()">{{ tr('Back') }}</button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Next') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 4" class="step step--4 step--source-profile">
          <div class="title small">{{ tr('Congratulations, you are very close to creating effective ads!') }}</div>

          <div class="textual">
            <p v-html="tr('In the next step, use the checkboxes to connect the <b>Instagram Account</b> data sources from which you want to use the posts as raw material for ads.')" />
          </div>

          <div class="scroll-wrapper">
            <integrations-meta-facebook-items-view
              platform-from-prop="instagram"
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>

          <div class="bottom-button space-between">
            <button class="bordered" :disabled="disabledButtons" @click="() => back()">{{ tr('Back') }}</button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Finish') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 5" class="step step--5 step--done">
          <top-image-content>
            <img src="~@/assets/img/onboarding/step-done.png" />
          </top-image-content>

          <div class="title">{{ tr('Fantastic!') }}</div>
          <div class="textual">
            <p v-html="tr('From now on, with the help of <span class=\'primary\'>Infinite∞Ad</span>, you can easily automate your ads and achieve the best results.')"></p>
            <p>{{ tr('Remember, if you get stuck at any time, we are available to help you.') }}</p>
            <p>{{ tr('Now start your new advertising experience!') }}</p>
          </div>

          <div class="bottom-button">
            <router-link
              to="/automation/new/ai-mode"
              class="button primary"
              @click.native="() => $emit('close')"
            >
              {{ tr('Create my first Automation') }}
            </router-link>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-show="step >= 1 && step <= 4" class="dots">
          <div :class="{ 'dot': true, 'active': step >= 1 }" />
          <div :class="{ 'dot': true, 'active': step >= 2 }" />
          <div :class="{ 'dot': true, 'active': step >= 3 }" />
          <div :class="{ 'dot': true, 'active': step >= 4 }" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import HasNoAssetsModalContent from '@/components/HasNoAssetsModalContent.vue';
import TopImageContent from '@/components/TopImageContent.vue';
import ConnectFacebookProfileMixin from '@/mixins/ConnectFacebookProfile';
import IntegrationsMetaAdAccountsView from '@/views/IntegrationsMetaAdAccountsView.vue';
import IntegrationsMetaFacebookItemsView from '@/views/IntegrationsMetaFacebookItemsView.vue';

export default {
  name: 'OnboardingAd',
  components: {
    IntegrationsMetaAdAccountsView,
    IntegrationsMetaFacebookItemsView,
    TopImageContent,
  },
  mixins: [ ConnectFacebookProfileMixin ],
  props: {
    skipFacebookProfile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 0,
    forceHeight: null,
    disableHeightTransition: false,
    disabledButtons: false,
  }),
  computed: {
    steps() {
      return [
        this.tr('Connect Facebook Profile'),
        this.tr('Connect Ad account'),
        this.tr('Connect Datasource'),
      ];
    },
    wrapperStyle() {
      const list = {
        'min-height': (this.forceHeight ? this.forceHeight : 0) + 'px',
      };

      if (this.disableHeightTransition) {
        list['transition'] = 'none';
      }

      return list;
    },
    iconBackgroundColor() {
      return (this.step <= 1 || this.step === this.steps.length + 2) ? '#fff' : '#000';
    },
  },
  mounted() {
    this.updateMinHeight();
  },
  methods: {
    onFacebookProfileConnected() {
      if (this.$root.loggedInUser.connected_profiles[0].details.ad_accounts.length === 0) {
        this.$emit('close');

        this.$modal.show(
          HasNoAssetsModalContent,
          { asset: 'ad-account' },
          {
            name: 'has-no-assets-modal',
            class: 'has-no-assets-modal',
          },
        );
        return;
      }
      if (
        this.$root.loggedInUser.connected_profiles[0].details.pages.length === 0
        && this.$root.loggedInUser.connected_profiles[0].details.instagram_accounts.length === 0
      ) {
        this.$emit('close');

        this.$modal.show(
          HasNoAssetsModalContent,
          { asset: 'source-profile' },
          {
            name: 'has-no-assets-modal',
            class: 'has-no-assets-modal',
          },
        );
        return;
      }

      this.next();
    },
    updateMinHeight() {
      this.forceHeight = this.$refs['wrapper'].offsetHeight;
    },
    beforeChangeStep() {
      this.disableHeightTransition = true;
      this.updateMinHeight();
      setTimeout(() => {
        this.disableHeightTransition = false;
        this.$nextTick(() => this.forceHeight = null);
      }, 400);
    },
    next() {
      this.$toast.clear();

      if (this.skipFacebookProfile && this.step === 0) {
        this.step = 2;
        this.beforeChangeStep();
        return;
      }

      if (this.step === 2) {
        if (this.$root.currentCompany.ad_accounts.length === 0) {
          this.$toast.error(this.tr('At least one advertising account connection is required.'));
          return;
        }
      }
      if (this.step === 4) {
        if (this.$root.currentCompany.source_profiles.length === 0) {
          this.$toast.error(this.tr('At least one platform (Facebook Page or Instagram account) connection is required.'));
          return;
        }
      }

      this.beforeChangeStep();
      this.$nextTick(() => ++this.step);
    },
    back() {
      this.beforeChangeStep();
      if (this.skipFacebookProfile && this.step === 2) {
        this.step = 0;
        return;
      }
      this.$nextTick(() => --this.step);
    },
    close() {
      if (this.step === this.steps.length + 2) {
        this.$emit('close');
        return;
      }

      if (this.disabledButtons || this.isConnecting) {
        this.$toast.clear();
        this.$toast.info(this.tr('Please wait until the process is completed.'));
        return;
      }

      this.$confirmation.open({
        className: 'onboarding-close-confirm',
        imageClass: 'onboarding-close-image',
        title: this.tr('Are you sure you want to close the setup wizard?'),
        content: this.tr('You can reopen the installation wizard anytime from the Dashboard menu if you need assistance with setting up the system.'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Close Wizard'),
        confirm: () => this.$emit('close'),
      });
    },
    disableNavigationButtons(value) {
      this.disabledButtons = value;
    },
  },
};
</script>
