import BaseEntity from '@/entities/BaseEntity';

export default class FacebookTargetingGeolocationCity extends BaseEntity {
  key = null;
  radius = 20;
  meta = {};

  constructor(rawData) {
    super();

    if ('type' in rawData) {
      delete rawData.type;
    }

    this.updateWith(rawData);
  }

  get type() {
    return 'city';
  }

  get radiusMin() {
    return 17;
  }

  get radiusMax() {
    return 80;
  }
}
