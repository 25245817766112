<template>
  <adset-budget-block
    ref="mainBlock"
    :entity="entity"
    :disabled="disabled"
  >
    <label class="input-label">
      {{ tr('Optimization goal') }}
    </label>
    <div class="description">{{ tr('It refers to the specific objective or outcome that you aim to achieve through your advertising campaign.') }}</div>
    <v-select
      v-model="entity.settings.adset_optimization_goal"
      :options="facebookAdsetOptimizationGoals"
      :reduce="(obj) => obj.machine_name"
      :searchable="false"
      :clearable="false"
      :placeholder="tr('Set an optimization goal')"
      :disabled="!entity.isNew || disabled"
      label="label"
    />
    <span>{{ tr('You will not be able to change this setting of this automation after the campaign has started.') }}</span>

    <!--
    <label class="input-label">
      {{ tr('Billing event') }}
    </label>
    <v-select
      v-model="entity.settings.adset_billing_event"
      label="label"
      :options="facebookAdsetBillingEvents"
      :reduce="(obj) => obj.machine_name"
      disabled
    />
    <span>{{ tr('You will not be able to change this setting of this automation after the campaign has started.') }}</span>
    -->

    <div v-if="!campaignHasBidStrategy">
      <label class="input-label">{{ tr('Bid strategy') }}</label>
      <v-select
        v-model="entity.settings.adset_bid_strategy"
        :options="facebookAdsetBidStrategies"
        :reduce="(obj)=> obj.machine_name"
        :searchable="false"
        :clearable="false"
        :disabled="disabled"
      />
    </div>

    <div v-if="entity.bidAmountIsAvailable">
      <label class="input-label">
        {{ bidAmountLabel }}
        <template v-if="!entity.bidAmountIsRequired">
          ({{ tr('optional') }})
        </template>
      </label>
      <div v-if="!entity.isBidCapStrategy" class="description">{{ tr('A type of advertising metric that is used to measure the effectiveness and efficiency of an advertising campaign. It represents the average cost that you pay for a specific result, such as a click, an impression, a lead, or a sale.') }}</div>
      <money-input
        v-model="entity.settings.adset_bid_amount"
        :currency="entity.adAccountCurrency.code"
        :disabled="disabled"
      />
      <info-text v-if="!entity.bidAmountIsRequired">
        {{ tr('Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.') }}
      </info-text>
    </div>
  </adset-budget-block>
</template>

<script>
import AdsetBudgetBlock from '@/components/RuleForm/Blocks/AdsetBudgetBlock.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'AdsetBudgetNonOdaxBlock',
  components: {
    AdsetBudgetBlock,
  },
  mixins: [ RuleFormBlockMixin ],
  computed: {
    facebookAdsetOptimizationGoals() {
      const items = this.$store.getters['app/facebookAdsetOptimizationGoals'];
      return this.$parent.filterSelectValuesByConditions(items);
    },
    facebookAdsetBidStrategies() {
      return this.$store.getters['app/facebookAdsetBidStrategies'];
    },
    facebookAdsetBillingEvents() {
      return this.$store.getters['app/facebookAdsetBillingEvents'];
    },
    campaignHasBidStrategy() {
      return this.entity.output_campaign.bid_strategy !== null;
    },
    bidAmountLabel() {
      return this.entity.isBidCapStrategy ? this.tr('Bid cap') : this.tr('Cost per result goal');
    },
  },
  created() {
    if (!this.entity.settings.adset_billing_event) {
      this.entity.settings.adset_billing_event = this.facebookAdsetBillingEvents[0].machine_name;
    }
  },
  methods: {
    validate() {
      return this.$refs.mainBlock.validate();
    },
  },
};
</script>
