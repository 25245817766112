<template>
  <form-block
    class="rule-form-block ad-placement-block-component"
    :title="tr('Placement')"
  >
    <div
      v-if="!entity.output_campaign.isObjectiveEngagement"
      class="checkbox-list vertical placement-selector"
    >
      <label>
        <input
          v-model="isAutoPlacement"
          type="radio"
          name="placement"
          :value="true"
          :disabled="entity.isAppPromo || disabled"
        />
        {{ tr('Automatic') }}
      </label>
      <span>{{ tr('Maximize your budget and help show your ads to more people. Facebook\'s delivery system will allocate your ad set\'s budget across multiple placements based on where they\'re likely to perform best.') }}</span>

      <label>
        <input
          v-model="isAutoPlacement"
          type="radio"
          name="placement"
          :value="false"
          :disabled="disabled"
        />
        {{ tr('Manual') }}
      </label>
      <span>{{ tr('Manually choose the places to show your ad. The more placements you select, the more opportunities you\'ll have to reach your target audience and achieve your business goals.') }}</span>
    </div>
    <div v-else>
      <info-text type="info">
        {{ tr('Placements are automatically selected based on the platform of the chosen data source') }}
      </info-text>
    </div>

    <div v-if="!isAutoPlacement" class="manual-placement-selectors">
      <div>
        <label class="input-label">
          {{ tr('Devices') }}
        </label>
        <v-select
          v-model="adsetTargetingDevices"
          :options="devices"
          :searchable="false"
          :clearable="false"
          :disabled="entity.isAppPromo || disabled"
        />
      </div>

      <div v-if="hasManualMobileTargeting">
        <label class="input-label">
          {{ tr('Specific mobile devices') }}
        </label>
        <v-select
          v-model="adsetTargetingUserOs"
          :options="mobileDevices"
          :searchable="false"
          :clearable="false"
          :disabled="entity.isAppPromo || disabled"
        />
      </div>
    </div>

    <div v-if="!isAutoPlacement">
      <label class="input-label">
        {{ tr('Platforms') }}
      </label>
      <placement-selector
        ref="placementSelector"
        :entity="entity"
        :disabled="disabled"
        :disabled-facebook="facebookPlacementsAreDisabled"
      />
    </div>
  </form-block>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import PlacementSelector from '@/components/RuleForm/PlacementSelector.vue';
import { PLATFORMS } from '@/consts';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'AdPlacementBlock',
  components: {
    PlacementSelector,
    InfoText,
  },
  mixins: [ RuleFormBlockMixin ],
  data: () => ({
    isAutoPlacement: false,
    adsetTargetingDevices: null,
    adsetTargetingUserOs: null,
  }),
  computed: {
    devices() {
      return this.$store.getters['app/devices'];
    },
    adPlacements() {
      return this.$store.getters['app/facebookAdPlacements'];
    },
    platforms() {
      return PLATFORMS;
    },
    mobileDevices() {
      return this.$store.getters['app/mobileDevices'];
    },
    hasManualMobileTargeting() {
      return this.entity.settings.adset_targeting_devices && this.entity.settings.adset_targeting_devices.includes('mobile');
    },
    facebookPlacementsAreDisabled() {
      return this.entity.settings.adset_conversion_location === 'instagram_profile';
    },
  },
  watch: {
    isAutoPlacement(val, oldVal) {
      if (val == oldVal) {
        return;
      }

      if (val) {
        this.entity.settings.adset_targeting_positions = {};

        this.platforms.forEach(platform => {
          this.entity.settings.adset_targeting_positions[platform.machineName] = [];
        });

        this.adsetTargetingDevices = [];
        this.adsetTargetingUserOs = [];
      }
      else {
        this.platforms.forEach(platform => {
          this.entity.settings.adset_targeting_positions[platform.machineName] = this.adPlacements[platform.machineName].map(i => i.machine_name);
        });

        this.adsetTargetingDevices = this.devices[0];
        this.adsetTargetingUserOs = this.mobileDevices[0];
      }
    },
    adsetTargetingDevices(val) {
      this.entity.settings.adset_targeting_devices = val ? val.machine_name : null;
    },
    adsetTargetingUserOs(val) {
      this.entity.settings.adset_targeting_user_os = val ? val.machine_name : null;
    },
    'entity.settings.adset_conversion_app_store_url'(val) {
      if (val) {
        if (this.selectedApp.google_play_url === val) {
          this.adsetTargetingUserOs = this.mobileDevices.find(i => JSON.stringify(i.machine_name) === JSON.stringify([ 'Android' ]));
        }
        else if (this.selectedApp.app_store_url === val) {
          this.adsetTargetingUserOs = this.mobileDevices.find(i => JSON.stringify(i.machine_name) === JSON.stringify([ 'iOS' ]));
        }
      }
    },
  },
  created() {
    if (this.entity.settings.adset_targeting_devices) {
      this.adsetTargetingDevices = this.devices.find(i => JSON.stringify(i.machine_name) === JSON.stringify(this.entity.settings.adset_targeting_devices));
    }
    if (this.entity.settings.adset_targeting_user_os) {
      this.adsetTargetingUserOs = this.mobileDevices.find(i => JSON.stringify(i.machine_name) === JSON.stringify(this.entity.settings.adset_targeting_user_os));
    }

    if (this.entity.isAppPromo) {
      this.isAutoPlacement = false;
      this.adsetTargetingDevices = this.devices.find(i => JSON.stringify(i.machine_name) === JSON.stringify([ 'mobile' ]));
    }
    else if (
      (!this.adsetTargetingDevices || this.adsetTargetingDevices.length === 0)
      && (!this.adsetTargetingUserOs || this.adsetTargetingUserOs.length === 0)
      && Object.values(this.entity.settings.adset_targeting_positions).filter(i => i.length !== 0).length === 0
    ) {
      this.isAutoPlacement = true;
    }
  },
  methods: {
    validate() {
      // TODO:.
      /* if (!this.isAutoPlacement) {
        if (
          this.entity.hasSelectedFacebookPlatform
          && this.$refs.placementSelector.checkboxStatus.facebook.length === 0
        ) {
          this.error(this.tr('At least one Facebook platform must be selected!'));
          return false;
        }

        if (
          this.entity.hasSelectedInstagramPlatform
          && this.$refs.placementSelector.checkboxStatus.instagram.length === 0
        ) {
          this.error(this.tr('At least one Instagram platform must be selected!'));
          return false;
        }
      } */

      return true;
    },
  },
};
</script>
