<template>
  <div class="posts-parent">
    <sticky-header
      :title="title"
      :info="info"
      bordered
    >
      <template v-if="viewIsPostsList" #buttons>
        <button class="primary" @click="() => $refs.child.openFieldsModal()">
          {{ tr('Edit view') }}
        </button>
      </template>

      <template v-else-if="viewIsPosts" #buttons>
        <router-link
          v-slot="{ href, navigate }"
          to="/posts"
          class="button bordered"
          :custom="true"
        >
          <a :href="href" @click="($ev) => $refs.child.onClickToBack($ev, navigate)">
            {{ tr('Back') }}
          </a>
        </router-link>
      </template>
    </sticky-header>

    <div class="transition-position-fix">
      <transition name="fade--fast">
        <router-view ref="child" />
      </transition>
    </div>
  </div>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue';

export default {
  name: 'PostListView',
  components: {
    StickyHeader,
  },
  computed: {
    viewIsPostsList() {
      return this.$route.name === 'PostListView';
    },
    viewIsPosts() {
      return this.$route.name === 'PostView';
    },
    viewIsAdCopyAnalysisMagicTool() {
      return this.$route.name === 'AdCopyMagicToolView';
    },
    title() {
      return this.tr('Posts');
    },
    info() {
      return this.tr('The <b class="primary">Infinite∞Ai</b> evaluates your posts based on 5 criteria and provides suggestions for the text to make them as sought after as possible. 🧠🤖');
    },
    tabs() {
      const tabs = [
        {
          label: this.tr('Published Content'),
          link: '/posts',
        },
      ];

      if (this.$root.isInOriginalCompany) {
        tabs.push({
          label: this.tr('Magic Tool'),
          link: '/magic-tool',
        });
      }

      return tabs;
    },
  },
};
</script>
