<template>
  <form-block
    class="rule-form-block dsa-block-component"
    :title="tr('Beneficiary and payer')"
  >
    <info-text>
      {{ tr('Starting July 10, 2023, you\'ll need to indicate the beneficiary and payer for any new or edited ad set with an audience in the European Union.') }}
    </info-text>

    <div>
      <label class="input-label">
        {{ tr('Beneficiary') }}
      </label>

      <div class="autosuggest-wrapper">
        <vue-autosuggest
          v-model="entity.dsa_beneficiary"
          :input-props="{ 'disabled': disabled }"
          :suggestions="[{ data: beneficiarySuggestions }]"
          @input="updateBeneficiarySuggestions"
          @selected="onSelectSuggestedBeneficiary"
        >
          <template slot-scope="{ suggestion }">{{ suggestion.item }}</template>
        </vue-autosuggest>

        <i v-if="beneficiarySuggestionsIsLoading" class="icon loader primary autosuggest-loader" />
      </div>
    </div>

    <label>
      <input
        v-model="differentPayer"
        type="checkbox"
        :disabled="disabled"
      />
      {{ tr('A different person or organization is paying') }}
    </label>

    <transition-expand>
      <div v-if="differentPayer">
        <label class="input-label">
          {{ tr('Payer') }}
        </label>

        <div class="autosuggest-wrapper">
          <vue-autosuggest
            v-model="entity.dsa_payer"
            :input-props="{ 'disabled': disabled }"
            :suggestions="[{ data: payerSuggestions }]"
            @input="updatePayerSuggestions"
            @selected="onSelectSuggestedPayer"
          >
            <template slot-scope="{ suggestion }">{{ suggestion.item }}</template>
          </vue-autosuggest>

          <i v-if="payerSuggestionsIsLoading" class="icon loader primary autosuggest-loader" />
        </div>
      </div>
    </transition-expand>
  </form-block>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';

import InfoText from '@/components/InfoText.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import ToolService from '@/services/Tool.js';
import debounce from '@/utils/debounce.js';

export default {
  name: 'DsaBlock',
  components: {
    InfoText,
    TransitionExpand,
    VueAutosuggest,
  },
  mixins: [ RuleFormBlockMixin ],
  data() {
    return {
      differentPayer: this.entity ? (this.entity.dsa_payer && this.entity.dsa_payer != this.entity.dsa_beneficiary) : null,
      beneficiarySuggestions: [],
      payerSuggestions: [],
      beneficiarySuggestionsIsLoading: false,
      payerSuggestionsIsLoading: false,
      updateBeneficiarySuggestions: debounce(newValue => {
        this.beneficiarySuggestionsIsLoading = true;

        ToolService.autosuggestBeneficiaryOrPayer(newValue)
          .then(resp => this.beneficiarySuggestions = resp)
          .catch(() => this.beneficiarySuggestions = [])
          .finally(() => this.beneficiarySuggestionsIsLoading = false);
      }),
      updatePayerSuggestions: debounce(newValue => {
        this.payerSuggestionsIsLoading = true;

        ToolService.autosuggestBeneficiaryOrPayer(newValue)
          .then(resp => this.payerSuggestions = resp)
          .catch(() => this.payerSuggestions = [])
          .finally(() => this.payerSuggestionsIsLoading = false);
      }),
    };
  },
  watch: {
    differentPayer(val) {
      if (!val) {
        this.entity.dsa_payer = this.entity.dsa_beneficiary;
      }
      else {
        this.entity.dsa_payer = '';
      }
    },
    'entity.dsa_beneficiary': {
      immediate: true,
      handler() {
        if (!this.differentPayer) {
          this.entity.dsa_payer = this.entity.dsa_beneficiary;
        }
      },
    },
  },
  methods: {
    onSelectSuggestedBeneficiary(item) {
      if (item) {
        this.entity.dsa_beneficiary = item.item;
      }
    },
    onSelectSuggestedPayer(item) {
      if (item) {
        this.entity.dsa_payer = item.item;
      }
    },
    validate() {
      if (this.entity.dsa_beneficiary === null || this.entity.dsa_beneficiary.trim().length === 0) {
        this.error(this.tr(':field is required!', { ':field': this.tr('Beneficiary') }));
        return false;
      }
      if (this.entity.dsa_payer === null || this.entity.dsa_payer.trim().length === 0) {
        this.error(this.tr(':field is required!', { ':field': this.tr('Payer') }));
        return false;
      }

      return true;
    },
  },
};
</script>
