<template>
  <div class="rule-change-log-fields-component">
    <div class="field-row header">
      <div class="field" />
      <div class="value value--original">
        {{ tr('Original value') }}
      </div>
      <div class="value value--new">
        {{ tr('New value') }}
      </div>
    </div>
    <div
      v-for="item in entity.changes"
      :key="'field-item--' + item.id + '-' + item.field"
      class="field-row"
    >
      <div class="field">{{ tr(item.field) }}</div>

      <div class="value value--original">
        <div v-if="item.field === 'field.tasks.conditions' || item.field === 'field.conditions'">
          <condition-group
            v-model="item.original"
            :action="entity.rule.action"
            :ad-account="adAccount"
            :disabled="true"
          />
        </div>
        <template v-else-if="Array.isArray(item.original) && item.original.length">
          <div
            v-for="(row, rowKey) in getDiffedArray(item.original, item.new)"
            :key="'field-item--' + item.id + '-' + item.field + '--original--' + rowKey"
          >
            <span :class="'value-item ' + (row.highlightable ? 'value-item--changed' : '')">
              {{ formatValue(row.value) }}
            </span>
          </div>
        </template>
        <span v-else class="value-item value-item--changed" v-html="formatValue(item.original)" />
      </div>

      <div class="value value--new">
        <condition-group
          v-if="item.field === 'field.tasks.conditions' || item.field === 'field.conditions'"
          v-model="item.new"
          :action="entity.rule.action"
          :ad-account="adAccount"
          :disabled="true"
        />
        <template v-else-if="Array.isArray(item.new) && item.new.length">
          <div
            v-for="(row, rowKey) in getDiffedArray(item.new, item.original)"
            :key="'field-item--' + item.id + '-' + item.field + '--new--' + rowKey"
          >
            <span :class="'value-item ' + (row.highlightable ? 'value-item--changed' : '')">
              {{ formatValue(row.value) }}
            </span>
          </div>
        </template>
        <span v-else class="value-item value-item--changed" v-html="formatValue(item.new)" />
      </div>
    </div>
  </div>
</template>

<script>
import ConditionGroup from '@/components/RuleForm/Condition/ConditionGroup.vue';
import RuleChangeLogEntity from '@/entities/RuleChangeLog.js';

export default {
  name: 'RuleChangeLogFields',
  components: {
    ConditionGroup,
  },
  props: {
    entity: {
      type: RuleChangeLogEntity,
      required: true,
    },
  },
  computed: {
    adAccount() {
      if (!this.entity.rule.ad_account.id) {
        return null;
      }

      const company = this.$root.currentCompany;
      const adAccount = company.ad_accounts.find(i => i.id === this.entity.rule.ad_account.id);

      return adAccount;
    },
  },
  methods: {
    getDiffedArray(array1, array2) {
      return array1.map(item => ({ value: item, highlightable: !array2.includes(item) }));
    },
    formatValue(value) {
      if (value === null) {
        value = '';
      }
      else if (Array.isArray(value)) {
        if (value.length === 0) {
          value = '';
        }
        else if (value.length === 1) {
          value = value[0];
        }
      }
      else if (typeof value === 'string' && value.length !== 0) {
        value = this.tr(value);
      }
      else {
        value = value.toString();
      }

      switch (value) {
        case 'true':
          value = '✓ ' + this.tr('yes');
          break;

        case 'false':
          value = '✗ ' + this.tr('no');
          break;

        case '':
          value = '- ' + this.tr('empty value') + ' -';
          break;

        default:
          value = this.tr(value);
      }

      return value;
    },
  },
};
</script>
