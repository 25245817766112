import FormBlock from '@/components/FormBlock.vue';
import Rule from '@/entities/Rule.js';
import AdAccountService from '@/services/AdAccount.js';

export default {
  components: {
    FormBlock,
  },
  props: {
    entity: {
      type: Rule,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    validate() {
      return true;
    },
    filterSelectValuesByConditions(items) {
      return items.filter(item => {
        if (!('allowed' in item)) {
          return true;
        }

        for (const conditionGroup of item.allowed) {
          let groupResult = true;

          for (const field in conditionGroup) {
            const allowedValues = conditionGroup[field];

            let currentValue = this.entity;
            const fieldParts = field.split('.');
            for (const fieldPart of fieldParts) {
              currentValue = currentValue[fieldPart];
            }

            if (!allowedValues.includes(currentValue)) {
              groupResult = false;
              break;
            }
          }

          if (groupResult) {
            return true;
          }
        }

        return false;
      });
    },
    refreshSelectedAdAccount() {
      if (!this.entity.selectedAdAccount) {
        this.error(this.tr('Ad account is not selected, or the selected ad account has been disconnected.'));
      }

      return AdAccountService.refresh(this.entity.selectedAdAccount.id)
        .then(resp => {
          this.$root.currentCompany.updateAdAccount(resp);
          return resp;
        });
    },
  },
};
