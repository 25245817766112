let activeRequests = 0;
let unloadHandler = null;

export const setupNavigationBlocker = (instance, message) => {
  instance.interceptors.request.use(
    config => {
      if ([ 'post', 'put', 'delete' ].includes(config.method?.toLowerCase())) {
        if (activeRequests === 0) {
          unloadHandler = event => {
            event.preventDefault();
            event.returnValue = message;
            return message;
          };
          window.addEventListener('beforeunload', unloadHandler);
        }
        activeRequests++;
      }
      return config;
    },
    async error => {
      throw error;
    },
  );

  instance.interceptors.response.use(
    response => {
      cleanupRequest();
      return response;
    },
    error => {
      cleanupRequest();
      return Promise.reject(error);
    },
  );

  const cleanupRequest = () => {
    if (activeRequests > 0) {
      activeRequests--;

      if (activeRequests === 0 && unloadHandler) {
        window.removeEventListener('beforeunload', unloadHandler);
        unloadHandler = null;
      }
    }
  };
};
