<template>
  <div class="auth-page auth-view">
    <div class="content-wrapper">
      <div class="input-form-container">
        <h1>{{ tr('Support Login') }}</h1>

        <div class="auth-form-view login-form-view">
          <form class="form" @submit="$event => onFormSubmit($event)">
            <div class="input-label">
              <span>{{ tr('E-mail') }}</span>
            </div>
            <input
              v-model="email"
              type="email"
              :placeholder="tr('you@gmail.com')"
              :disabled="isLoading"
            />
            <div class="input-label">
              <span>{{ tr('Password') }}</span>
            </div>
            <input
              v-model="password"
              type="password"
              :placeholder="tr('Enter at least 8 characters...')"
              :disabled="isLoading"
            />

            <div class="input-label">
              <span>{{ tr('Two-factor authentication code') }}</span>
            </div>
            <auth-code-input
              ref="authCodeInput"
              v-model="code"
              :disabled="isLoading"
              @enter="() => onFormSubmit()"
            />

            <button type="submit" class="primary">
              <i
                v-if="isLoading"
                class="icon loader white"
                :loading="isLoading"
                color="#ffffff"
                size="6px"
              />
              <template v-else> {{ tr('Login') }} </template>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="placeholder" />
  </div>
</template>

<script>
import AuthCodeInput from '@/components/AuthCodeInput.vue';
import { UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'SupportLoginView',
  components: {
    AuthCodeInput,
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      code: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
      this.password = '';
      this.code = '';
    },
    onFormSubmit($event) {
      if ($event) {
        $event.preventDefault();
      }

      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store
        .dispatch('support/login', {
          email: this.email,
          password: this.password,
          code: this.code,
        })
        .then(resp => {
          if (window.PasswordCredential) {
            const passwordCredential = new window.PasswordCredential({ id: this.email, password: this.password });
            navigator.credentials.store(passwordCredential);
          }

          this.$router.replace({ path: '/support/masquerade' });
          this.$nextTick(() => this.resetForm());

          return resp;
        })
        .catch(err => {
          if (err instanceof UnauthorizedError) {
            this.$toast.error(this.tr('Invalid login information.'));
          }
          else {
            this.error(err);
          }
        })
        .finally(() => this.isLoading = false);
    },
    validate() {
      this.$toast.clear();

      if (!this.email || !isValidEmail(this.email)) {
        this.$toast.error(this.tr('Invalid email address.'));
        return false;
      }
      else if (!this.password) {
        this.$toast.error(this.tr('Password is required.'));
        return false;
      }
      else if (this.code.length !== 6) {
        this.$toast.error(this.tr('Two-factor authentication code is required.'));
        return false;
      }

      return true;
    },
  },
};
</script>
