var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "posts-parent" },
    [
      _c("sticky-header", {
        attrs: { title: _vm.title, info: _vm.info, bordered: "" },
        scopedSlots: _vm._u(
          [
            _vm.viewIsPostsList
              ? {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "primary",
                          on: {
                            click: () => _vm.$refs.child.openFieldsModal(),
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.tr("Edit view")) + " ")]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : _vm.viewIsPosts
              ? {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("router-link", {
                        staticClass: "button bordered",
                        attrs: { to: "/posts", custom: true },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ href, navigate }) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: href },
                                    on: {
                                      click: ($ev) =>
                                        _vm.$refs.child.onClickToBack(
                                          $ev,
                                          navigate
                                        ),
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.tr("Back")) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        { staticClass: "transition-position-fix" },
        [
          _c(
            "transition",
            { attrs: { name: "fade--fast" } },
            [_c("router-view", { ref: "child" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }