import RuleSwitchOffModalContent from '@/components/RuleSwitchOffModalContent.vue';
import RuleWithInactiveCampaignSwitchOnModalContent from '@/components/RuleWithInactiveCampaignSwitchOnModalContent.vue';
import RuleService from '@/services/Rule.js';

export default {
  methods: {
    onChangeRuleStatus(rule) {
      const { id } = rule;
      const newStatus = rule.active;

      if (rule.hasOutputCampaign) {
        if (!newStatus && rule.campaign_status === 'active') {
          rule.active = !newStatus;

          this.$modal.show(
            RuleSwitchOffModalContent,
            { rule },
            { name: 'rule-switch-off-modal' },
          );
          return;
        }
        if (newStatus && rule.campaign_status === 'inactive') {
          rule.active = !newStatus;

          this.$modal.show(
            RuleWithInactiveCampaignSwitchOnModalContent,
            { rule },
            { name: 'rule-with-inactive-campaign-switch-on-modal' },
          );
        }
      }

      RuleService.changeStatus(id, newStatus)
        .catch(err => {
          this.error(err);
          rule.active = !newStatus;
        });
    },
  },
};
