var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-preview" },
    [
      _c("div", {
        staticClass: "size-keeper",
        style: "min-height: " + _vm.sizeKeeperHeight + "px",
      }),
      _vm.isLoading
        ? _c("div", { staticClass: "loading-state" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm.items.length === 0
        ? _c("notification-card", { attrs: { "empty-screen": "" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr(
                    "No post corresponds to the current settings, and no ads have been created from them yet."
                  )
                ) +
                " "
            ),
          ])
        : _c(
            "div",
            { ref: "content", staticClass: "content" },
            [
              _c("div", { staticClass: "item-count-text" }, [
                _vm._v(" " + _vm._s(_vm.itemsCountText) + " "),
              ]),
              _c("sorted-table", {
                attrs: { data: _vm.items },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "table-head",
                          { attrs: { col: "source", width: "150px" } },
                          [_vm._v(_vm._s(_vm.tr("Source")))]
                        ),
                        _c(
                          "table-head",
                          { attrs: { col: "post", "min-width": "300px" } },
                          [_vm._v(_vm._s(_vm.tr("Post")))]
                        ),
                        _c(
                          "table-head",
                          { attrs: { col: "post_type", width: "140px" } },
                          [_vm._v(_vm._s(_vm.tr("Post type")))]
                        ),
                        _c(
                          "table-head",
                          { attrs: { col: "media_type", width: "140px" } },
                          [_vm._v(_vm._s(_vm.tr("Media type")))]
                        ),
                        _c(
                          "table-head",
                          { attrs: { col: "posted", width: "140px" } },
                          [_vm._v(_vm._s(_vm.tr("Posted")))]
                        ),
                        _c("table-head", {
                          attrs: { col: "info_column", width: "50px" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ sort }) {
                      return _vm._l(sort, function (post) {
                        return _c(
                          "table-row",
                          { key: post.id },
                          [
                            _c("table-data", { attrs: { col: "source" } }, [
                              _c("div", { staticClass: "platform" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.ucfirst(post.source_profile.platform)
                                  )
                                ),
                              ]),
                              _vm._v(
                                " " + _vm._s(post.source_profile.name) + " "
                              ),
                            ]),
                            _c("table-data", { attrs: { col: "post" } }, [
                              _c(
                                "small",
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.tr("Post ID")) + ": "
                                  ),
                                  _c(
                                    post.url ? "a" : "span",
                                    {
                                      tag: "component",
                                      attrs: {
                                        href: post.url,
                                        target: post.url ? "_blank" : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(post.external_id) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("br"),
                              _c("div", { staticClass: "post-text" }, [
                                _vm._v(" " + _vm._s(post.shortText) + " "),
                              ]),
                            ]),
                            _c("table-data", { attrs: { col: "post_type" } }, [
                              _vm._v(" " + _vm._s(post.type) + " "),
                            ]),
                            _c("table-data", { attrs: { col: "media_type" } }, [
                              _vm._v(" " + _vm._s(post.media_type) + " "),
                            ]),
                            _c("table-data", { attrs: { col: "posted" } }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.formatToYMD(post.created_at) +
                                        ", " +
                                        _vm.formatTime(post.created_at),
                                      expression:
                                        "formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.timeAgo(post.created_at, _vm.tr)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "table-data",
                              { attrs: { col: "info_column" } },
                              [
                                _c("div", { staticClass: "action-group" }, [
                                  _vm.isProhibitedContent(post)
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              _vm.prohibitedContentTooltip(
                                                post
                                              ),
                                            expression:
                                              "prohibitedContentTooltip(post)",
                                          },
                                        ],
                                        staticClass: "icon alert light-black",
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
      !_vm.isLoading && _vm.sourceProfileDetails.length !== 0
        ? _c("div", { staticClass: "source-profile-informations-wrapper" }, [
            _c("h3", [_vm._v(_vm._s(_vm.tr("Datasource informations")))]),
            _c(
              "div",
              { staticClass: "source-profile-informations" },
              _vm._l(_vm.sourceProfileDetails, function (sourceProfile, key) {
                return _c(
                  "div",
                  {
                    key: "source-profile-details--" + key,
                    staticClass: "source-profile-informations--item",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "source-profile-title" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(sourceProfile.name) +
                            " - " +
                            _vm._s(_vm.ucfirst(sourceProfile.platform)) +
                            " "
                        ),
                        _c("refresh-button", {
                          attrs: {
                            label: _vm.tr("Update posts"),
                            "success-message": _vm.tr(
                              "Posts of :name updated successfully.",
                              { ":name": sourceProfile.name }
                            ),
                            promise: () =>
                              _vm.refreshSourceProfile(sourceProfile.id),
                          },
                        }),
                      ],
                      1
                    ),
                    sourceProfile.fetched_at
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr("Data last updated at :time", {
                                  ":time": _vm.timeAgo(
                                    sourceProfile.fetched_at,
                                    _vm.tr
                                  ),
                                })
                              ) +
                              " "
                          ),
                        ]
                      : [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "The datasource has not been fetched yet."
                                )
                              ) +
                              " "
                          ),
                        ],
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.tr("Number of posts examined")) +
                        ": " +
                        _vm._s(
                          _vm.tr("last :num pcs", {
                            ":num": sourceProfile.post_limit,
                          })
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.tr("Latest known post date")) + ": "
                    ),
                    sourceProfile.latest_post_date
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatToYMD(sourceProfile.latest_post_date)
                              ) +
                              " " +
                              _vm._s(
                                _vm.formatTime(sourceProfile.latest_post_date)
                              ) +
                              " "
                          ),
                        ]
                      : [_vm._v(" - ")],
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.tr("Oldest examined post date")) + ": "
                    ),
                    sourceProfile.oldest_post_date
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatToYMD(sourceProfile.oldest_post_date)
                              ) +
                              " " +
                              _vm._s(
                                _vm.formatTime(sourceProfile.oldest_post_date)
                              ) +
                              " "
                          ),
                        ]
                      : [_vm._v(" - ")],
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }