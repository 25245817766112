import FacebookTargetingGeolocations from '@/descriptors/FacebookTargetingGeolocations';
import BaseEntity from '@/entities/BaseEntity';

export default class FacebookTargeting extends BaseEntity {
  genders = 'all';
  age_min = 18;
  age_max = 65;
  geo_locations = new FacebookTargetingGeolocations();
  excluded_geo_locations = new FacebookTargetingGeolocations();
  locales = [];

  _entityFields = {
    geo_locations: FacebookTargetingGeolocations,
    excluded_geo_locations: FacebookTargetingGeolocations,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get hasGeoOverlaps() {
    return this.geo_locations.hasOverlaps || this.excluded_geo_locations.hasOverlaps;
  }

  get hasInvalidGeoExclude() {
    for (const item of this.excluded_geo_locations.all) {
      if (!this.geo_locations.parentSelected(item)) {
        return true;
      }
    }

    return false;
  }
}
