<template>
  <div class="top-image-content-component">
    <i class="icon infinite white background" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TopImageContent',
};
</script>
