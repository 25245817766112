<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--start--step-4">
    <ad-creation-method-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <cta-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <tracking-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <url-params-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>

<script>
import AdCreationMethodBlock from '@/components/RuleForm/Blocks/AdCreationMethodBlock.vue';
import CtaBlock from '@/components/RuleForm/Blocks/CtaBlock.vue';
import TrackingBlock from '@/components/RuleForm/Blocks/TrackingBlock.vue';
import UrlParamsBlock from '@/components/RuleForm/Blocks/UrlParamsBlock.vue';
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

export default {
  name: 'StartRuleFormStep4',
  components: {
    AdCreationMethodBlock,
    CtaBlock,
    UrlParamsBlock,
    TrackingBlock,
  },
  mixins: [ RuleFormStepMixin ],
};
</script>
