import Vue from 'vue';

import SortedTable from './SortedTable.vue';
import TableData from './TableData.vue';
import TableHead from './TableHead.vue';
import TableRow from './TableRow.vue';

Vue.component('SortedTable', SortedTable);
Vue.component('TableHead', TableHead);
Vue.component('TableRow', TableRow);
Vue.component('TableData', TableData);
