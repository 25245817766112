<template>
  <form-block
    class="magic-tool-form-component"
  >
    <h3>{{ tr('Analyze your ad copy') }}</h3>
    <div class="description">{{ tr('Insert your ad text and wait for the system to evaluate it. The system evaluates your ad based on several aspects (creativity, attention-grabbing, etc.), and gives a short assessment for each aspect. In addition, you can also create a new version of your ad text generated by the system.') }}</div>
    <v-select
      v-model="goal"
      :options="goalOptionsList"
      :reduce="(obj) => obj.machine_name"
      :placeholder="tr('Select a goal for your ad (optional)')"
      :clearable="true"
      :searchable="false"
    />

    <input
      v-model="title"
      type="text"
      :placeholder="tr('Paste your ad title here (optional)')"
    >

    <input
      v-model="description"
      type="text"
      :placeholder="tr('Paste your ad description here (optional)')"
    >

    <textarea v-model="text" :placeholder="tr('Paste your ad copy here')" />

    <image-input
      v-model="image"
      :label="tr('Upload your creative')"
    />

    <button
      class="primary"
      :disabled="isLoading"
      @click="() => submit()"
    >
      <i v-if="isLoading" class="icon loader white" />
      <template v-else>
        {{ tr('Get analysis') }}
      </template>
    </button>
  </form-block>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import ImageInput from '@/components/ImageInput.vue';
import MagicToolService from '@/services/MagicTool.js';

export default {
  name: 'MagicToolForm',
  components: {
    FormBlock,
    ImageInput,
  },
  data: () => ({
    isLoading: false,
    goal: null,
    title: '',
    description: '',
    text: '',
    image: null,
  }),
  computed: {
    goalOptionsList() {
      return [
        {
          label: 'You have a webshop, or you sell products or services online',
          machine_name: 'conversion',
        },
        {
          label: 'You have a website and you want to drive visitors there',
          machine_name: 'traffic',
        },
        {
          label: 'You have an application and you want it to achieve more results',
          machine_name: 'app_promotion',
        },
        {
          label: 'You would like your Facebook or Instagram posts to reach more people and receive more reactions',
          machine_name: 'engagement',
        },
      ];
    },
  },
  watch: {
    image: {
      handler(val) {
        if (val) {
          this.$emit('image-uploaded', val);
        }
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      if (this.isLoading) {
        return;
      }

      this.$toast.clear();

      if (this.text.trim().length === 0) {
        this.$toast.error(this.tr('Please paste your ad copy'));
        return;
      }

      this.isLoading = true;

      MagicToolService.analyze(this.text, this.goal, this.title, this.description, this.image)
        .then(resp => {
          this.text = '';
          this.goal = '';
          this.title = '';
          this.description = '';
          this.image = null;

          this.$toast.success(this.tr('We are already working on it! The evaluation will be completed within 1-2 minutes.'));

          this.$emit('entity-created', resp);

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
