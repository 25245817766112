/* eslint-disable filenames/match-exported */
/* eslint-disable filenames/match-regex */

export default class ChartCircle {
  r = 3.75;
  strokeWidth = 2;
  stroke = 'black';
  fill = 'white';

  #svg = null;
  #element = null;

  constructor(chart) {
    this.#svg = chart.svg;
  }

  get element() {
    return this.#element;
  }

  draw() {
    this.#element = this.#svg.append('circle')
      .attr('fill', this.fill)
      .attr('stroke', this.stroke)
      .attr('stroke-width', this.strokeWidth)
      .attr('r', this.r);

    return this.#element;
  }
}
