if (process.env.VUE_APP_MODE === 'ad' && window.location.hostname === 'app.8ad.io') {
  window.location.href = window.location.href.replace('://app.8ad.io', '://app.infinite.ad');
}

import('./assets/scss/styles.scss');

/* IMPORT SCRIPTS */
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import VCalendar from 'v-calendar';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import vSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import App from '@/App.vue';
import AccessHandledRouterLink from '@/components/AccessHandledRouterLink';
import { NonCriticalError, ValidationError } from '@/errors';
import ConditionalFilteredList from '@/mixins/ConditionalFilteredList';
import ErrorNotifier from '@/mixins/ErrorNotifier';
import Translator from '@/mixins/Translator';
import ConfirmationModal from '@/plugins/confirmation';
import { router } from '@/router';
import { store } from '@/store';
import selectPositionCalculator from '@/utils/selectPositionCalculator.js';

vSelect.props.calculatePosition.default = selectPositionCalculator;
vSelect.props.dropdownShouldOpen.default = function({ noDrop, open, mutableLoading }) {
  const isOpen = noDrop ? false : open && !mutableLoading;
  if (!isOpen && this.$refs.search) {
    this.$nextTick(() => this.$refs.search.blur());
  }

  return isOpen;
};
vSelect.props.appendToBody.default = true;
vSelect.props.transition.default = 'dropdown-slide';
vSelect.props.getOptionLabel.default = function(option) {
  if (
    typeof option === 'object'
    && option !== null
    && !Array.isArray(option)
  ) {
    const label = this.label || 'label';
    if (label in option) {
      return this.tr(option[label]);
    }
  }

  return option;
};
Vue.component('v-select', vSelect);

require('@/components/SortedTable/Register.js');

Vue.component('vue-toast', VueToast);
Vue.component('AccessHandledRouterLink', AccessHandledRouterLink);

Vue.use(ConfirmationModal);
Vue.use(VCalendar);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(VueToast, {
  position: 'bottom-left',
  duration: 10000,
  dismissible: true,
});

Vue.mixin(Translator);
Vue.mixin(ConditionalFilteredList);
Vue.mixin(ErrorNotifier);

Vue.config.productionTip = false;

Vue.prototype.$store = store;
Vue.prototype.$app = {
  mode: process.env.VUE_APP_MODE,
  isAd: process.env.VUE_APP_MODE === 'ad',
  isBuzz: process.env.VUE_APP_MODE === 'buzz',
};

export const appInstance = new Vue({
  router,
  store,
  data: () => ({
    screenWidth: window.innerWidth,
  }),
  computed: {
    language() {
      return store.getters['app/language'];
    },
    isUserFetching() {
      return this.$store.getters['auth/isFetching'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
    loggedInUser() {
      return store.getters['auth/user'];
    },
    currentCompany() {
      return store.getters['auth/company'];
    },
    isInOriginalCompany() {
      return this.loggedInUser.company.id === this.currentCompany.id;
    },
    isSupportMode() {
      return process.env.VUE_APP_SUPPORT_BUILD == 1;
    },
    loggedInSupportUser() {
      return store.getters['support/user'];
    },
    supportHasL2Access() {
      const supportLevel = this.loggedInSupportUser ? this.loggedInSupportUser.level : null;
      return this.isSupportMode && supportLevel >= 2;
    },
    supportHasL3Access() {
      const supportLevel = this.loggedInSupportUser ? this.loggedInSupportUser.level : null;
      return this.isSupportMode && supportLevel >= 3;
    },
  },
  created() {
    this.updateScreenWidth();
    window.addEventListener('resize', () => this.screenWidth = window.innerWidth);

    if (this.isSupportMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('user_id');

      if (userId) {
        this.$store.commit('support/setAutoLoginUserId', userId);

        if (this.isLoggedIn) {
          this.$store.dispatch('auth/logout');
        }
      }
    }
  },
  mounted() {
    this.updateScreenWidth();
    setTimeout(() => this.updateScreenWidth(), 250);

    this.initInputAutoTrimmer();
  },
  methods: {
    initInputAutoTrimmer() {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.addedNodes.length === 0) {
            return;
          }

          mutation.addedNodes.forEach(node => {
            if (!(node instanceof Element)) {
              return;
            }

            if (node instanceof HTMLInputElement) {
              node.removeEventListener('blur', this.inputAutoTrim);
              node.addEventListener('blur', this.inputAutoTrim);
            }
            else {
              const inputs = node.querySelectorAll('input');
              inputs.forEach(input => {
                input.removeEventListener('blur', this.inputAutoTrim);
                input.addEventListener('blur', this.inputAutoTrim);
              });
            }
          });
        });
      });

      observer.observe(document.body, { subtree: true, childList: true });
    },
    inputAutoTrim(e) {
      const ariaControlsValue = e.target.getAttribute('aria-controls');
      if (typeof ariaControlsValue === 'string' && ariaControlsValue.indexOf('autosuggest') !== -1) {
        return;
      }

      this.$nextTick(() => {
        e.target.value = e.target.value.trim();
        e.target.dispatchEvent(new Event('input', { bubbles: true }));
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    fetchUserData() {
      return this.$store.dispatch('auth/fetch');
    },
    fetchSubscriptionData() {
      return this.$store.dispatch('auth/fetchSubscription');
    },
    fetchSupportUserData() {
      this.$store.commit('support/isFetching', true);

      return this.$store.dispatch('support/fetch')
        .finally(async () => {
          await this.$nextTick();
          this.$store.commit('support/isFetching', false);
        });
    },
  },
  render: h => h(App),
}).$mount('#app');

ValidationError.appInstance = appInstance;

if (process.env.NODE_ENV !== 'production') {
  window.AppInstance = appInstance;
}

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'beta') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      /Redirected when going from "(.*?)" to "(.*?)" via a navigation guard/,
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        error instanceof NonCriticalError
        || (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK)
      ) {
        return null;
      }

      return event;
    },
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/app\.infinite\.ad/,
      /^https:\/\/beta\.app\.8ad\.io/,
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
