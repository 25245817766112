<template>
  <div class="auth-page">
    <div class="content-wrapper">
      <div class="input-form-container">
        <enable-google-2fa
          v-if="created"
          :description="tr('The use of two-factor authentication is mandatory in the organization.') + '<br/><br/>'"
          @success="() => onSuccess()"
          @cancel="() => logout()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EnableGoogle2fa from '@/components/EnableGoogle2fa';

export default {
  name: 'AccountView',
  components: {
    EnableGoogle2fa,
  },
  data:() => ({
    isLoading: false,
    isSaving: false,
    created: false,
  }),
  created() {
    if (this.$root.loggedInUser && this.$root.loggedInUser.google_2fa_enabled) {
      this.$router.replace('/');
      return;
    }

    this.created = true;
  },
  methods: {
    onSuccess() {
      this.$router.replace('/');
      window.location.reload();
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
