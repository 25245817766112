<template>
  <modal
    name="connect-unsettled-ad-account"
    class="connect-unsettled-ad-account-modal"
    overlayTransition="fade-modal-overlay"
    @closed="() => $emit('closed')"
  >
    <i
      class="icon close"
      @click="() => close()"
    />

    <form-block
      v-if="adAccount !== null"
      :title="tr('Unsettled Ad Account')"
      :description="modalDescription"
    >
      <div class="description">
        {{ tr('You can view and settle your debt at Meta via the following link:') }}<br />
        <a :href="metaLink" target="_blank">{{ metaLink }}</a>
      </div>

      <div class="buttons">
        <button :disabled="isLoading" @click="() => close()">
          {{ tr('Cancel') }}
        </button>
        <button class="primary" :disabled="isLoading" @click="() => tryAgain()">
          <i v-if="isLoading" class="icon loader white" /> {{ tr('Paid, let\'s try again') }}
        </button>
      </div>
    </form-block>
  </modal>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import { ApiError } from '@/errors';
import ConnectFacebookProfileMixin from '@/mixins/ConnectFacebookProfile';

export default {
  name: 'ConnectUnsettledAdAccountModal',
  components: {
    FormBlock,
  },
  mixins: [ ConnectFacebookProfileMixin ],
  props: {
    adAccount: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    modalDescription() {
      return this.tr('The advertisement account you are trying to connect (:adAccountName) shows a payment debt at Meta (Facebook). Until the debt is settled, the advertisement account cannot be connected to Infinite∞Ad.', {
        ':adAccountName': this.adAccount.name,
      });
    },
    metaLink() {
      return 'https://business.facebook.com/billing_hub/payment_activity?asset_id=' + this.adAccount.id.replace('act_', '');
    },
  },
  methods: {
    open() {
      this.$modal.show('connect-unsettled-ad-account');
    },
    close() {
      this.$modal.hide('connect-unsettled-ad-account');
    },
    tryAgain() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.connect(null, null, true)
        .then(resp => {
          let updatedAdAccount = null;

          const connectedProfiles = this.$root.loggedInUser.connected_profiles.filter(profile => profile.platform === 'facebook');
          for (const profile of connectedProfiles) {
            for (const adAcc of profile.details.ad_accounts) {
              if (adAcc.id === this.adAccount.id) {
                updatedAdAccount = adAcc;
                break;
              }
            }

            if (updatedAdAccount !== null) {
              break;
            }
          }

          if (updatedAdAccount === null) {
            this.close();
            throw new ApiError(this.tr('After updating, the ad account cannot be found.'));
          }

          if (updatedAdAccount.account_status !== 1) {
            throw new ApiError(this.tr('The advertisement account still shows a payment debt. Please try again.'));
          }

          this.$emit('settled', updatedAdAccount);
          this.close();

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
