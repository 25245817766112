var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        { class: _vm.classList },
        [
          _vm.showTokenExpiredError
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "error",
                    link: _vm.isAdmin ? "/integrations/meta/profiles" : "",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          'The access token associated with one of your organization\'s connected :platform profiles named ":name" has expired.',
                          {
                            ":name": _vm.connectedProfileWithExpiredToken.name,
                            ":platform":
                              _vm.connectedProfileWithExpiredToken.platform,
                          }
                        )
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isAd
                          ? _vm.tr(
                              "Consequently, until the profile is reconnected, the automations using the assets of this profile will not work."
                            )
                          : ""
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isBuzz
                          ? _vm.tr(
                              "Consequently, until the profile is reconnected, the comment moderations using the assets of this profile will not work."
                            )
                          : ""
                      ) +
                      " "
                  ),
                ]
              )
            : _vm.showTokenWillBeExpiredWarning
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "error",
                    link: _vm.isAdmin ? "/integrations/meta/profiles" : "",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          'The access token associated with one of your organization\'s connected :platform profiles named ":name" will be expired :expireInterval.',
                          {
                            ":name": _vm.connectedProfileWithExpiredToken.name,
                            ":platform":
                              _vm.connectedProfileWithExpiredToken.platform,
                            ":expireInterval":
                              _vm.connectedProfileTokenExpireInterval,
                          }
                        )
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isAd
                          ? _vm.tr(
                              "Consequently, the profile needs to be reconnected, otherwise automations using the profile's assets will not work."
                            )
                          : ""
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isBuzz
                          ? _vm.tr(
                              "Consequently, the profile needs to be reconnected, otherwise comment moderations using the profile's assets will not work."
                            )
                          : ""
                      ) +
                      " "
                  ),
                ]
              )
            : _vm.showTokenHasMissingScopeWarning
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "error",
                    link: _vm.isAdmin ? "/integrations/meta/profiles" : "",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          'The access token associated with one of your organization\'s connected :platform profiles named ":name" does not have a required permission. Reconnecting the profile is required to resolve the issue.',
                          {
                            ":name": _vm.connectedProfileWithMissingScope.name,
                            ":platform":
                              _vm.connectedProfileWithMissingScope.platform,
                          }
                        )
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.hasNoBillingAddress
            ? _c(
                "info-text",
                {
                  attrs: {
                    link: "/settings/billing-and-payment",
                    icon: "cash",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "Don't forget to set your billing information on the Settings / Billing page."
                        )
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.showLastPaymentIsIncomplete
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "warning",
                    icon: "cash",
                    link: "/settings/payment-history",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "You have a pending payment obligation. Check it on the Settings / Payment History page."
                        )
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.showAssetNumberError &&
          !_vm.showPaymentArrearsError &&
          !_vm.hasNoActiveSubscription
            ? _c("info-text", { attrs: { type: "error", large: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "Attention! Your company is entitled to use a maximum of :availableNum devices, but :connectedNum devices are connected.",
                        {
                          ":availableNum": _vm.$root.currentCompany.max_assets,
                          ":connectedNum":
                            _vm.$root.currentCompany.usedAssetsCount,
                        }
                      )
                    ) +
                    " " +
                    _vm._s(
                      _vm.$app.isAd
                        ? _vm.tr(
                            "Until you fix the problem, your automations won't run."
                          )
                        : ""
                    ) +
                    " " +
                    _vm._s(
                      _vm.$app.isAd
                        ? _vm.tr(
                            "A solution may be to switch to a larger subscription package, purchase extra asset option, or disconnect one or more assets."
                          )
                        : ""
                    ) +
                    " " +
                    _vm._s(
                      _vm.$app.isBuzz
                        ? _vm.tr(
                            "Until you fix the problem, automatic comment moderation will not run."
                          )
                        : ""
                    ) +
                    " " +
                    _vm._s(
                      _vm.$app.isBuzz
                        ? _vm.tr(
                            "A solution may be to switch to a larger subscription package, or disconnect one or more assets."
                          )
                        : ""
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.showPaymentArrearsError
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "error",
                    link: _vm.isAdmin ? "/settings/payment-history" : "",
                    large: "",
                  },
                },
                [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "Your company's payment balance shows arrears!"
                          )
                        ) +
                        " " +
                        _vm._s(
                          _vm.$app.isAd
                            ? _vm.tr(
                                "Because of this, your automations are currently not running."
                              )
                            : ""
                        ) +
                        " " +
                        _vm._s(
                          _vm.$app.isBuzz
                            ? _vm.tr(
                                "Because of this, your automatic comment moderation is not running."
                              )
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                  _vm.isAdmin
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "Complete the payment obligation on the Settings / Payment history page.",
                                {
                                  ":date":
                                    _vm.formatToStringYMD(
                                      _vm.company.grace_period_end_date
                                    ) +
                                    " " +
                                    _vm.formatTime(
                                      _vm.company.grace_period_end_date
                                    ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ]
                    : [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "Ask the administrator to fulfill your company's payment obligations.",
                                {
                                  ":date":
                                    _vm.formatToStringYMD(
                                      _vm.company.grace_period_end_date
                                    ) +
                                    " " +
                                    _vm.formatTime(
                                      _vm.company.grace_period_end_date
                                    ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ],
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$app.isAd
                          ? _vm.tr(
                              "After the account is settled, your automations will run again."
                            )
                          : ""
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isBuzz
                          ? _vm.tr(
                              "After the account is settled, comment moderation will run again."
                            )
                          : ""
                      ) +
                      " "
                  ),
                ],
                2
              )
            : _vm.showPaymentArrearsWarning
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "warning",
                    link: _vm.isAdmin ? "/settings/payment-history" : "",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "Attention! We were unable to renew your subscription on the last deadline."
                        )
                      ) +
                      " "
                  ),
                  _vm.isAdmin
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "Complete the payment obligation by :date on the Settings / Payment history page.",
                                {
                                  ":date":
                                    _vm.formatToStringYMD(
                                      _vm.company.grace_period_end_date
                                    ) +
                                    " " +
                                    _vm.formatTime(
                                      _vm.company.grace_period_end_date
                                    ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ]
                    : [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "Ask the administrator to fulfill your company's payment obligations by :date.",
                                {
                                  ":date":
                                    _vm.formatToStringYMD(
                                      _vm.company.grace_period_end_date
                                    ) +
                                    " " +
                                    _vm.formatTime(
                                      _vm.company.grace_period_end_date
                                    ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ],
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$app.isAd
                          ? _vm.tr(
                              "If this is not done, the automations will be suspended."
                            )
                          : ""
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isBuzz
                          ? _vm.tr(
                              "If this is not done, the commend moderation will be suspended."
                            )
                          : ""
                      ) +
                      " "
                  ),
                ],
                2
              )
            : _vm.hasNoActiveSubscription
            ? _c(
                "info-text",
                {
                  attrs: {
                    type: "warning",
                    link: "/settings/billing-and-payment",
                    large: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$app.isAd
                          ? _vm.tr(
                              "You currently do not have an active subscription! To use Infinite∞Ad, select and subscribe to the package that suits you best."
                            )
                          : ""
                      ) +
                      " " +
                      _vm._s(
                        _vm.$app.isBuzz
                          ? _vm.tr(
                              "You currently do not have an active subscription! To use Infinite∞Buzz, select and subscribe to the package that suits you best."
                            )
                          : ""
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.showAdAccountStatusNotActive
            ? _c("info-text", { attrs: { type: "error", large: "" } }, [
                _vm._v(" " + _vm._s(_vm.adAccountStatusMessage) + " "),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }