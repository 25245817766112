import Vue from 'vue';
import VueRouter from 'vue-router';

import { store } from '@/store';
import AccountView from '@/views/AccountView.vue';
import AuthView from '@/views/AuthView.vue';
import DashboardView from '@/views/DashboardView.vue';
import DevView from '@/views/DevView.vue';
import Enable2faView from '@/views/Enable2faView.vue';
import ErrorLogsView from '@/views/ErrorLogsView.vue';
import ForgottenPasswordView from '@/views/ForgottenPasswordView.vue';
import IntegrationsMetaAdAccountsView from '@/views/IntegrationsMetaAdAccountsView.vue';
import IntegrationsMetaFacebookItemsView from '@/views/IntegrationsMetaFacebookItemsView.vue';
import IntegrationsMetaProfilesView from '@/views/IntegrationsMetaProfilesView.vue';
import IntegrationsMetaView from '@/views/IntegrationsMetaView.vue';
import IntegrationsView from '@/views/IntegrationsView.vue';
import LoginView from '@/views/LoginView.vue';
import MagicToolView from '@/views/MagicToolView.vue';
import ModerationView from '@/views/ModerationView.vue';
import PasswordResetView from '@/views/PasswordResetView.vue';
import PostListView from '@/views/PostListView.vue';
import PostView from '@/views/PostView.vue';
import PostsParentView from '@/views/PostsParentView.vue';
import ProtectedAccountsView from '@/views/ProtectedAccountsView.vue';
import ProtectedPostView from '@/views/ProtectedPostView.vue';
import ProtectedPostsView from '@/views/ProtectedPostsView.vue';
import ReportView from '@/views/ReportView.vue';
import RuleAiRiskReviewView from '@/views/RuleAiRiskReviewView.vue';
import RuleEditHistoryView from '@/views/RuleEditHistoryView.vue';
import RuleErrorLogView from '@/views/RuleErrorLogView.vue';
import RuleFormView from '@/views/RuleForm/RuleFormView.vue';
import RuleListView from '@/views/RuleListView.vue';
import RuleOverviewView from '@/views/RuleOverviewView.vue';
import RulePendingAdsView from '@/views/RulePendingAdsView.vue';
import RuleReportView from '@/views/RuleReportView.vue';
import RuleView from '@/views/RuleView.vue';
import SettingsBillingAndPaymentView from '@/views/SettingsBillingAndPaymentView.vue';
import SettingsOrganizationView from '@/views/SettingsOrganizationView.vue';
import SettingsPaymentHistoryView from '@/views/SettingsPaymentHistoryView.vue';
import SettingsTeamView from '@/views/SettingsTeamView.vue';
import SettingsView from '@/views/SettingsView.vue';
import SignupView from '@/views/SignupView.vue';
import SupportLoginView from '@/views/SupportViews/SupportLoginView.vue';
import SupportMasqueradeView from '@/views/SupportViews/SupportMasqueradeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
      },
      {
        path: 'signup/:token?',
        name: 'Signup',
        component: SignupView,
      },
      {
        path: 'forgotten-password',
        name: 'ForgottenPassword',
        component: ForgottenPasswordView,
      },
      {
        path: 'reset-password/:token',
        name: 'PasswordReset',
        component: PasswordResetView,
      },
    ],
  },
  {
    path: '/enable-2fa',
    component: Enable2faView,
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/automation',
    name: 'Rules',
    component: RuleListView,
  },
  {
    path: '/automation/new/:action',
    component: RuleFormView,
  },
  {
    path: '/automation/:id/edit',
    alias: '/automation/:id/duplicate',
    name: 'RuleForm',
    component: RuleFormView,
  },
  {
    path: '/automation/:id',
    name: 'Rule',
    component: RuleView,
    children: [
      {
        path: '/',
        name: 'Rule',
        redirect: '/automation/:id/overview',
      },
      {
        path: 'overview',
        name: 'RuleOverview',
        component: RuleOverviewView,
      },
      {
        path: 'pending-ads',
        name: 'RulePendingAdsView',
        component: RulePendingAdsView,
      },
      {
        path: 'ai-risk-review',
        name: 'RuleAiRiskReviewView',
        component: RuleAiRiskReviewView,
      },
      {
        path: 'edit-history',
        name: 'RuleEditHistoryView',
        component: RuleEditHistoryView,
      },

      {
        path: 'report',
        name: 'RuleReport',
        component: RuleReportView,
      },
      {
        path: 'error-logs',
        name: 'RuleErrorLog',
        component: RuleErrorLogView,
        meta: {
          accessHandler() {
            return router.app.$root.isSupportMode;
          },
        },
      },
    ],
  },
  {
    path: '/posts',
    redirect: '/',
    component: PostsParentView,
    children: [
      {
        path: '/',
        name: 'PostListView',
        component: PostListView,
      },
      {
        path: '/posts/:id',
        name: 'PostView',
        component: PostView,
      },
    ],
  },
  {
    path: '/magic-tool',
    name: 'MagicToolView',
    component: MagicToolView,
  },
  {
    path: '/moderation',
    redirect: '/moderation/account',
    component: ModerationView,
    children: [
      {
        path: 'account',
        name: 'ProtectedAccountsView',
        component: ProtectedAccountsView,
      },
      {
        path: 'post',
        name: 'ProtectedPostsView',
        component: ProtectedPostsView,
      },
      {
        path: 'post/:id',
        name: 'ProtectedPostView',
        component: ProtectedPostView,
      },
    ],
  },
  {
    path: '/report',
    name: 'Report',
    component: ReportView,
  },
  {
    path: '/report/:tokenPart1/:tokenPart2',
    name: 'SharedReport',
    component: ReportView,
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: IntegrationsView,
    meta: {
      accessHandler() {
        const user = store.getters['auth/user'];
        return user && [ 'admin', 'normal' ].includes(user.level);
      },
    },
  },
  {
    path: '/integrations/meta',
    redirect() {
      return Vue.prototype.$app.isAd ? '/integrations/meta/ad-accounts' : '/integrations/meta/facebook-pages';
    },
    meta: {
      accessHandler() {
        const user = store.getters['auth/user'];
        return user && [ 'admin', 'normal' ].includes(user.level);
      },
    },
    component: IntegrationsMetaView,
    children: [
      {
        path: 'ad-accounts',
        name: 'IntegrationsMetaAdAccounts',
        component: IntegrationsMetaAdAccountsView,
      },
      {
        path: 'profiles',
        name: 'IntegrationsMetaProfiles',
        component: IntegrationsMetaProfilesView,
      },
      {
        path: ':itemType',
        name: 'IntegrationsMetaFacebookItemsView',
        component: IntegrationsMetaFacebookItemsView,
      },
    ],
  },
  {
    path: '/error-logs',
    name: 'ErrorLogs',
    component: ErrorLogsView,
    meta: {
      accessHandler() {
        return router.app.$root.isSupportMode;
      },
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    meta: {
      accessHandler() {
        const user = store.getters['auth/user'];
        const currentCompany = store.getters['auth/company'];

        return user
          && currentCompany
          && user.company.id === currentCompany.id;
      },
    },
  },
  {
    path: '/settings',
    redirect: '/settings/billing-and-payment',
    component: SettingsView,
    meta: {
      accessHandler() {
        const user = store.getters['auth/user'];
        const currentCompany = store.getters['auth/company'];

        return user
          && currentCompany
          && user.company.id === currentCompany.id
          && [ 'admin' ].includes(user.level);
      },
    },
    children: [
      {
        path: 'billing-and-payment',
        name: 'SettingsBillingAndPayment',
        component: SettingsBillingAndPaymentView,
      },
      {
        path: 'payment-history',
        name: 'SettingsPaymentHistory',
        component: SettingsPaymentHistoryView,
      },
      {
        path: 'team',
        name: 'SettingsTeam',
        component: SettingsTeamView,
      },
      {
        path: 'organization',
        name: 'SettingsOrganization',
        component: SettingsOrganizationView,
      },
    ],
  },
  {
    path: '/dev',
    name: 'Dev',
    component: DevView,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '*',
    redirect: '/dashboard',
  },
];

if (process.env.VUE_APP_SUPPORT_BUILD == 1) {
  routes.push({
    path: '/support/login',
    name: 'SupportLogin',
    component: SupportLoginView,
  });
  routes.push({
    path: '/support/masquerade',
    name: 'SupportMasquerade',
    component: SupportMasqueradeView,
  });
}

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const onlyPublicRouteNames = [
    'Login',
    'Signup',
    'ForgottenPassword',
    'PasswordReset',
    'SharedReport',
    'SupportLogin',
  ];
  const publicRouteNames = [
    'SharedReport',
    'SupportLogin',
  ];

  const hasAuthToken = store.getters['auth/hasToken'];
  const hasSupportToken = store.getters['support/hasToken'];
  const toPublicView = onlyPublicRouteNames.includes(to.name);

  if (process.env.VUE_APP_SUPPORT_BUILD == 1) {
    if (to.name === 'SupportMasquerade') {
      return hasSupportToken ? next() : next('/');
    }

    if (to.name === 'Login') {
      if (hasSupportToken) {
        return next('/support/masquerade' + window.location.search);
      }

      return next('/support/login' + window.location.search);
    }
  }

  if (!hasAuthToken && !toPublicView) {
    return next('/auth/login');
  }
  if (hasAuthToken && toPublicView && !publicRouteNames.includes(to.name)) {
    return next('/');
  }

  if (
    to &&
    to.meta &&
    to.meta.accessHandler &&
    !to.meta.accessHandler()
  ) {
    return next(from.path);
  }

  return next();
});
