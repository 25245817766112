<template>
  <div class="enable-google-2fa-component">
    <div v-if="step === 1">
      <form-block
        class="step step--1"
        :title="tr('Set up Google Authenticator')"
        :description="description + tr('Set up your two factor authentication by scanning the barcode below with the Google Authenticator app. Alternatively, you can use the code :code', { ':code': '<strong>' + secret + '</strong>' })"
      >
        <div v-if="qrCodeUrl" class="qr-code" v-html="qrCodeUrl" />
        <div v-else class="qr-code-loading-placeholder">
          <i class="icon loader primary" />
        </div>
      </form-block>

      <div class="bottom-button">
        <button class="bordered" @click="() => prev()">
          {{ tr('Back') }}
        </button>

        <button class="primary" @click="() => next()">
          {{ tr('Next') }}
        </button>
      </div>
    </div>

    <google-2fa-code-form
      v-else-if="step === 2"
      v-model="code"
      :title="tr('Set up Google Authenticator')"
      :cancel-button-label="tr('Previous')"
      :is-loading="isSaving"
      @submit="() => confirm()"
      @cancel="() => prev()"
    />
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock';
import Google2faCodeForm from '@/components/Google2faCodeForm';
import AuthService from '@/services/Auth';

export default {
  name: 'EnableGoogle2fa',
  components: {
    FormBlock,
    Google2faCodeForm,
  },
  props: {
    description: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data: () => ({
    isLoading: false,
    isSaving: false,
    step: 1,

    secret: null,
    qrCodeUrl: null,
    code: '',
  }),
  created() {
    this.loadFristStep();
  },
  methods: {
    loadFristStep() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      AuthService.enableGoogle2fa()
        .then(resp => {
          this.qrCodeUrl = resp.qr_url;
          this.secret = resp.secret;

          return resp;
        })
        .catch(err => {
          this.error(err);
          this.$emit('error', err);
          this.$emit('error-step-1');
        })
        .finally(() => this.isLoading = false);
    },
    prev() {
      if (this.step === 1) {
        this.$emit('cancel');
      }
      else {
        --this.step;
      }
    },
    next() {
      ++this.step;
    },
    confirm() {
      this.isSaving = true;

      AuthService.confirmEnableGoogle2fa(this.secret, this.code)
        .then(resp => {
          if (this.$root.loggedInUser) {
            this.$root.loggedInUser.google_2fa_enabled = true;
          }
          this.$toast.success(this.tr('Two-factor authentication has been successfully enabled.'));
          this.$emit('success');

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isSaving = false);
    },
  },
};
</script>
