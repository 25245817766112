var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "activity-log-component",
      attrs: { "hidable-classes": ["event"] },
    },
    [
      !_vm.isListEmpty || _vm.hasMore
        ? _c(
            "div",
            { staticClass: "events" },
            [
              _vm._l(_vm.logs, function (event, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "event" },
                  [
                    _c("div", { staticClass: "time" }, [
                      _vm.showDate(index)
                        ? _c("div", { staticClass: "date" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formattedDate(event.created_at)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "vertical-line" }),
                      _c("div", { staticClass: "content-container" }, [
                        _c(
                          "div",
                          { class: _vm.actionIconContainerColor(event) },
                          [_c("i", { class: _vm.actionIconClasses(event) })]
                        ),
                        _vm._v(
                          " " + _vm._s(_vm.formatTime(event.created_at)) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "vertical-line bottom" }),
                    ]),
                    _c("card", {
                      staticClass: "event-card",
                      class: { "with-date": _vm.cardWithDate(index) },
                      attrs: {
                        "header-color": _vm.headerColor(event),
                        "subrow-color": "white",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                event.action === "error"
                                  ? _c("div", { staticClass: "event-title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tr(
                                              "Could not create an ad from this content."
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "action-message" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon-container" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon checkmark blue",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "event-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.loggedActionText(event)
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.getAdFromEvent(event).variation
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "event-variation",
                                              },
                                              [
                                                _vm._v(
                                                  "- v" +
                                                    _vm._s(
                                                      _vm.getAdFromEvent(event)
                                                        .variation
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                              ]
                            },
                            proxy: true,
                          },
                          _vm.getAdFromEvent(event).created_with_ai &&
                          event.action === "start"
                            ? {
                                key: "header-decorator",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "icon magic-stars white",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Created with Ai")) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "event-description" },
                                  [
                                    event.post && event.post.image_url
                                      ? _c("lazy-image", {
                                          staticClass: "event-image",
                                          attrs: { src: event.post.image_url },
                                        })
                                      : _vm._e(),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.getText(event)) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "event-details" }, [
                                  _vm.getAdFromEvent(event)
                                    ? _c("div", { staticClass: "ad-details" }, [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.tr("Ad Platform")) +
                                              ": " +
                                              _vm._s(
                                                _vm.ucfirst(
                                                  _vm.getAdFromEvent(event)
                                                    .platform
                                                )
                                              )
                                          ),
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.tr("Ad ID")) +
                                              ": " +
                                              _vm._s(
                                                _vm.getAdFromEvent(event)
                                                  .external_id
                                              )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  event.post
                                    ? _c(
                                        "div",
                                        { staticClass: "source-details" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "source" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.tr("Source")) +
                                                  ": "
                                              ),
                                              _c("source-profile-flag", {
                                                attrs: {
                                                  name: event.post
                                                    .source_profile.name,
                                                  platform:
                                                    event.post.source_profile
                                                      .platform,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.tr("Post ID")) +
                                                ": " +
                                                _vm._s(event.post.external_id)
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                event.post
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "analysis-link",
                                        attrs: {
                                          to: "/posts/" + event.post.id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.tr(
                                                "Go to Original Post Analysis"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
              _vm.hasMore
                ? _vm._l([1, 2], function (i) {
                    return _c(
                      "div",
                      {
                        key: "activity-log-loader-skeleton--" + i,
                        ref: "loadMore",
                        refInFor: true,
                        staticClass: "event",
                      },
                      [
                        _c("div", { staticClass: "time" }, [
                          i === 1
                            ? _c(
                                "div",
                                { staticClass: "date" },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "100%", height: "20px" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "vertical-line" }),
                          _c(
                            "div",
                            { staticClass: "content-container" },
                            [
                              _c(
                                "div",
                                { staticClass: "icon-container secondary" },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "100%", height: "100%" },
                                  }),
                                ],
                                1
                              ),
                              _c("skeleton", {
                                attrs: { width: "50px", height: "14px" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "vertical-line bottom" }),
                        ]),
                        _c("card", {
                          class: { "event-card": true, "with-date": i === 1 },
                          attrs: {
                            "header-color": "white",
                            "subrow-color": "white",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "60%",
                                        "max-width": "160px",
                                        height: "12px",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "event-description" },
                                      [
                                        _c("skeleton", {
                                          staticClass: "event-image",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "text" },
                                          [
                                            _c("skeleton", {
                                              attrs: {
                                                width: "100%",
                                                height: "16px",
                                              },
                                            }),
                                            _c("skeleton", {
                                              attrs: {
                                                width: "60%",
                                                height: "16px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "event-details" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "ad-details" },
                                          [
                                            _c("skeleton", {
                                              attrs: {
                                                width: "60%",
                                                "max-width": "160px",
                                                height: "14px",
                                              },
                                            }),
                                            _c("skeleton", {
                                              attrs: {
                                                width: "60%",
                                                "max-width": "140px",
                                                height: "14px",
                                              },
                                            }),
                                            _c("skeleton", {
                                              attrs: {
                                                width: "60%",
                                                "max-width": "200px",
                                                height: "14px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "subrow",
                                fn: function () {
                                  return [
                                    _c("skeleton", {
                                      staticClass: "expand-button",
                                      attrs: { width: "120px", height: "12px" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        : _c("notification-card", { attrs: { emptyScreen: "" } }, [
            _vm._v(" " + _vm._s(_vm.tr("No activity logs available")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }