<template>
  <div class="meta-ap-creative-features-modal">
    <meta-ap-creative-features
      v-model="insideValue"
      :disabled="disabled"
    />

    <div class="actions">
      <button class="button primary" @click="$emit('close')">
        {{ tr('Apply and Close') }}
      </button>
    </div>
  </div>
</template>

<script>
import MetaApCreativeFeatures from '@/components/RuleForm/MetaApCreativeFeatures.vue';
import MultiLevelValueModel from '@/mixins/MultiLevelValueModel';

export default {
  name: 'MetaApCreativeFeaturesModal',
  components: {
    MetaApCreativeFeatures,
  },
  mixins: [ MultiLevelValueModel ],
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>
