var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-activity-management-view" },
    [
      _vm.rule && !_vm.isLoading && _vm.isListEmpty
        ? [
            _vm.rule.activity_management
              ? _c("notification-card", { attrs: { "empty-screen": "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "There are no activities in the rule activity management yet."
                        )
                      ) +
                      " "
                  ),
                ])
              : _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/sad_new.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            'This automation does not have the "Activity Management" feature enabled.'
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
          ]
        : _c(
            "div",
            { staticClass: "activities" },
            [
              _vm._l(_vm.items, function (item) {
                return _c("card", { key: item.id, staticClass: "activity" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            _vm.formatToYMD(item.created_at) +
                            ", " +
                            _vm.formatTime(item.created_at),
                          expression:
                            "formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)",
                        },
                      ],
                      staticClass: "datetime",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.timeAgo(item.created_at, _vm.tr)) + " "
                      ),
                    ]
                  ),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.buildAction(item)) },
                  }),
                  _c("div", {
                    staticClass: "post-or-ad",
                    domProps: { innerHTML: _vm._s(_vm.buildPostOrAd(item)) },
                  }),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _vm.actionButtonsVisible(item)
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "primary small",
                                attrs: {
                                  disabled:
                                    _vm.loadingButton !== null ||
                                    _vm.$root.isSupportMode,
                                },
                                on: { click: () => _vm.accept(item) },
                              },
                              [
                                _vm.loadingButton === item.id + "--accept"
                                  ? _c("i", {
                                      staticClass: "icon loader white",
                                    })
                                  : _c("i", { staticClass: "icon like white" }),
                                _vm._v(" " + _vm._s(_vm.tr("Accept")) + " "),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "red small",
                                attrs: {
                                  disabled:
                                    _vm.loadingButton !== null ||
                                    _vm.$root.isSupportMode,
                                },
                                on: { click: () => _vm.reject(item) },
                              },
                              [
                                _vm.loadingButton === item.id + "--reject"
                                  ? _c("i", {
                                      staticClass: "icon loader white",
                                    })
                                  : _c("i", {
                                      staticClass: "icon dislike white",
                                    }),
                                _vm._v(" " + _vm._s(_vm.tr("Reject")) + " "),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.rule.action === "start"
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "button bordered small go-to-post-button",
                              attrs: {
                                href: "/posts/" + item.post.id,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  item.isHandled
                    ? _c("div", { staticClass: "status-wrapper" }, [
                        _c("div", {
                          staticClass: "status",
                          domProps: {
                            innerHTML: _vm._s(_vm.buildItemStatus(item)),
                          },
                        }),
                        item.handled_at
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.formatToYMD(item.handled_at) +
                                      ", " +
                                      _vm.formatTime(item.handled_at),
                                    expression:
                                      "formatToYMD(item.handled_at) + ', ' + formatTime(item.handled_at)",
                                  },
                                ],
                                staticClass: "datetime",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(item.handled_at, _vm.tr)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              }),
              !_vm.rule || _vm.hasMore
                ? _vm._l(_vm.isListEmpty ? [1, 2, 3] : [1, 2], function (i) {
                    return _c(
                      "card",
                      {
                        key: "rules-loader-skeleton--" + i,
                        ref: "loadMore",
                        refInFor: true,
                        staticClass: "activity",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "skeleton-container" },
                          [
                            _c("skeleton", {
                              attrs: { height: "10px", width: "80px" },
                            }),
                            _c("skeleton", {
                              attrs: { width: "60%", height: "13px" },
                            }),
                            _c("skeleton", {
                              attrs: { width: "80%", height: "13px" },
                            }),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c("skeleton", {
                                  attrs: {
                                    width: "100px",
                                    height: "30px",
                                    radius: "5px",
                                  },
                                }),
                                _c("skeleton", {
                                  attrs: {
                                    width: "100px",
                                    height: "30px",
                                    radius: "5px",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }