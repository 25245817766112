<template>
  <div class="templateable-input">
    <input
      ref="input"
      type="text"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    />
    <dropdown-menu v-model="dropdownIsOpen">
      <button class="button primary" :disabled="disabled">+ {{ tr('Macros') }}</button>
      <div slot="dropdown">
        <div
          v-for="item in templateParts"
          :key="item.id"
          @click="appendToInput(item)"
        >
          <span v-if="item.title" class="title">{{ tr(item.title) }}</span>
          <span v-else>{{ tr(item.label) }}</span>
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';
export default {
  name: 'TemplateableInput',
  components: {
    DropdownMenu,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    templateParts: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    dropdownIsOpen: false,
  }),
  methods: {
    appendToInput(item) {
      if (item.value) {
        item.value = item.value.trim();
        this.$refs.input.focus();
        const cursorPosition = this.$refs.input.selectionStart;

        const before = this.value.substring(0, cursorPosition);
        const after = this.value.substring(cursorPosition);

        const leftChar = this.value.charAt(cursorPosition - 1);
        const rightChar = this.value.charAt(cursorPosition);
        if(leftChar!=' ' && leftChar !=''){
          item.value = ' ' + item.value;
        }
        if(rightChar != ' ' && rightChar!=''){
          item.value = item.value + ' ';
        }
        const newPosition = cursorPosition + item.value.length;
        this.$emit('input', before + item.value + after);
        this.$nextTick(() => {
          this.$refs.input.focus();
          this.$refs.input.setSelectionRange(newPosition, newPosition);
        });
      }
    },
  },
};
</script>

