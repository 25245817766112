<template>
  <div class="dashboard-steps">
    <step-card
      v-for="(card, cardIndex) in cards"
      :key="cardIndex"
      :default-text="card.defaultText"
      :done-text="card.doneText"
      :step="tr(`Step ${cardIndex + 1}`)"
      :button-text="card.buttonText"
      :button-route="card.buttonRoute"
      :is-card-disabled="cardIndex !== 0 && !isCardDone(cardIndex - 1)"
      :is-card-done="isCardDone(cardIndex)"
    />
  </div>
</template>

<script>
import StepCard from '@/components/StepCard.vue';

export default {
  name: 'DashboardSteps',
  components: { StepCard },
  props: {
    profileConnected: {
      type: Boolean,
      required: true,
    },
    adAccountConnected: {
      type: Boolean,
      required: true,
    },
    sourceProfileConnected: {
      type: Boolean,
      required: true,
    },
    postBoostingCreated: {
      type: Boolean,
      required: true,
    },
    ruleCreated: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      cardsDone: [],
    };
  },
  computed: {
    cards() {
      return [
        {
          defaultText: this.tr('Connect your Meta Profile') + '<br/>' + this.tr('and start to use <b>Infinite∞Ad</b>'),
          doneText: '🤩 ' + this.tr('Congratulations, your Meta Profile has been connected to <b>Infinite∞Ad</b>'),
          buttonText: this.tr('Connect your Meta Account'),
          buttonRoute: '/integrations/meta/profiles',
        },
        {
          defaultText: this.tr('Connect at least one Facebook Ad account and one Facebook or (and) Instagram account'),
          doneText: '🤜🤛 ' + this.tr('Kudos, you\'ve linked your initial assets successfully to <b>Infinite∞Ad</b>'),
          buttonText: this.tr('Connect Your first assets'),
          buttonRoute: '/integrations/meta/ad-accounts',
        },
        {
          defaultText: this.tr('Start your automatition'),
          doneText: '🎉 ' + this.tr('Congrats! Your Automation has been successfully launched in <b>Infinite∞Ad</b>'),
          buttonText: this.tr('Create your first Automation'),
          buttonRoute: '/automation/new/ai-mode',
        },
      ];
    },
  },
  methods: {
    isCardDone(cardIndex) {
      switch (cardIndex) {
        case 0:
          return this.profileConnected;

        case 1:
          return this.adAccountConnected && this.sourceProfileConnected;

        case 2:
          return this.postBoostingCreated;

        case 3:
          return this.ruleCreated;
      }

      return false;
    },
  },
};
</script>
