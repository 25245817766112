import Post from '@/entities/Post.js';
import Rule from '@/entities/Rule.js';
import SourceProfile from '@/entities/SourceProfile.js';
import api from '@/services/Api';

export default {
  getExampleAdsetName({ prefix, template, adAccountId, sourceProfileId, campaign }) {
    const data = {
      prefix,
      template,
      ad_account_id: adAccountId,
      source_profile_id: sourceProfileId,
      campaign,
    };

    return api().post('/tools/example-adset-name', data)
      .then(resp => resp.example);
  },

  getExampleUrlParams({ template, sourceProfileId, campaign }) {
    const data = {
      template,
      campaign,
    };

    if (sourceProfileId) {
      data['source_profile_id'] = sourceProfileId;
    }

    return api().post('/tools/example-url-params', data)
      .then(resp => resp.example);
  },

  generatePostBoostingRuleNextRunPreview({
    ruleId,
    sourceProfileIds,
    facebookPostTypes,
    facebookPostMediaTypes,
    instagramPostTypes,
    instagramPostMediaTypes,
    conditions,
    dontCreateExpiredAds,
    objective,
    ctaButton,
    ctaUrl,
    smartCtaUrl,
  }) {
    const data = {
      rule_id: ruleId,
      source_profile_ids: sourceProfileIds,
      facebook_post_types: facebookPostTypes,
      facebook_post_media_types: facebookPostMediaTypes,
      instagram_post_types: instagramPostTypes,
      instagram_post_media_types: instagramPostMediaTypes,
      conditions,
      dont_create_expired_ads: dontCreateExpiredAds,
      objective,
      cta_button: ctaButton,
      cta_url: ctaUrl,
      smart_cta_url: smartCtaUrl,
    };

    return api().post('/tools/generate-rule-next-run-preview/post-boosting', data)
      .then(resp => {
        resp.posts = resp.posts.map(item => new Post(item));
        resp.source_profiles = resp.source_profiles.map(item => new SourceProfile(item));

        return resp;
      });
  },

  autosuggestBeneficiaryOrPayer(name) {
    const params = {};
    if (name) {
      params['name'] = name;
    }

    return api().get('/tools/autosuggest/beneficiary-or-payer?' + (new URLSearchParams(params)).toString())
      .then(resp => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  disconnectPrecheck({ adAccountId, sourceProfileId, connectedProfileId }) {
    const params = {};
    if (adAccountId) {
      params['ad_account_id'] = adAccountId;
    }
    if (sourceProfileId) {
      params['source_profile_id'] = sourceProfileId;
    }
    if (connectedProfileId) {
      params['connected_profile_id'] = connectedProfileId;
    }

    return api().get('/tools/disconnect-precheck?' + (new URLSearchParams(params)).toString())
      .then(resp => {
        resp.defunc_rules.map(item => new Rule(item));

        return resp;
      });
  },

  facebookAdGeolocationSearch(q) {
    const params = { q };
    return api().get('/tools/facebook/ad-geolocation/search?' + (new URLSearchParams(params)).toString());
  },

  facebookAdGeolocationMetadata(type, key) {
    return api().get('/tools/facebook/ad-geolocation/' + type + '/' + key);
  },
};
